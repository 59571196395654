import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom, Subject, tap } from 'rxjs';
import { menuAdministracion, menuGCType, menuMAIN } from 'src/app/globalcampo/config/menus-app.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';
import { IProfileService } from 'src/app/globalcampo/services/profile/profile-service.interface';
import { IProfile } from 'src/app/globalcampo/services/profile/profile.interface';
import { PROFILE_TOKEN } from 'src/app/globalcampo/services/profile/profile.token';
import { UsuarioService } from 'src/app/globalcampo/services/usuario.service';

@Component({
  selector: 'gc-menu-administracion',
  templateUrl: './menu-administracion.component.html',
  styleUrls: ['./menu-administracion.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule, FormsModule],
})
export class MenuAdministracionComponent implements OnInit {
  /**
   * Emite un valor booleano que indica si el menú está en estado compacto.
   * @event menuCCACompact
   * @type {boolean}
   */
  @Output() menuCCACompact: EventEmitter<boolean> = new EventEmitter();

  private readonly _unsubs: Subject<unknown> = new Subject();

  /**
   * Representa los nav links del menu
   */
  private readonly _navLinks: IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
   * Representa el link de salida del footer.
   */
  private _footerExitLink!: IMenu;
  public get footerExitLink(): IMenu {
    return this._footerExitLink;
  }

  /**
   * Indica si el menú debe mostrarse compacto.
   */
  private _compactMenu: boolean = false;
  public get compactMenu(): boolean {
    return this._compactMenu;
  }

  /**
   * Indica si la aplicación se está ejecutando en un entorno de escritorio.
   */
  private _isDesktop: boolean = false;
  public get isDesktop(): boolean {
    return this._isDesktop;
  }

  /**
   * Indica si el tamaño de la pantalla actual es MD o superior.
   */
  private _isMdUp: boolean = false;
  public get isMdUp(): boolean {
    return this._isMdUp;
  }

  /**
   * Indica la etiqueta para el botón toggle.
   */
  private _toogleLabel: string = 'LIGHT';
  public get toogleLabel(): string {
    return this._toogleLabel;
  }

  /**
   * Representa el nombre del icono que se utilizará en el componente del menú.
   */
  private _iconName: string = 'sun';
  public get iconName(): string {
    return this._iconName;
  }

  /**
   * El color del icono, por defecto es 'amarillo'.
   */
  private _iconColor: string = 'yellow';

  /**
  * Indica el estado actual del interruptor de modo.
  */
  public modeChanged: boolean = false;

  private readonly footerIcon: string = '--gc-color-footer-icon';
  private readonly footerIconHover: string = '--gc-color-footer-icon-hover';

  /**
   * Representa el texto para seleccionar una instalación en el menú CCA.
   */
  private readonly _labelSelectInstalacion: string = 'CCA.MENU.SEL_INSTALACION';
  /**
   * La etiqueta para la instalación.
   */
  private readonly _instalacionLabel: string = this._labelSelectInstalacion;
  public get instalacionLabel(): string {
    return this._instalacionLabel;
  }
  /**
   * Representa la instalación.
   */
  private _instalacion: string = '';
  public get instalacion(): string | null {
    return this._instalacion;
  }
  /**
  * Indica si se muestra u oculta el botón de cambio de instalación.
  */
  private readonly _showCambioInstalacion: boolean = true;
  public get showCambioInstalacion(): boolean {
    return this._showCambioInstalacion;
  }

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _platform: Platform,
    private readonly _userService: UsuarioService,
    private readonly _appStatusService: AppStatusService,
    private readonly _menuController: MenuController,
    private readonly _navCtrl: NavController,
    private readonly _router: Router,
    @Inject(PROFILE_TOKEN) private readonly _profileService: IProfileService,
  ) { }

  ngOnInit() {
    this.manageLinks();
    this._profileService.getProfile()
      .subscribe((profile) => {
        this.updateModeDataBasedOnProfile(profile);
      });

    this.checkIsDesktop();
    this.checkIsMdUp();
    window.addEventListener('resize', () => {
      this.checkIsMdUp();
    });

    this._appStatusService.instalacionChanged$
      .pipe(
        tap((instalacion) => {
          this._instalacion = instalacion?.nombreInstalacion ?? '';
        }),
      )
      .subscribe();
  }

  /**
   * Recupera un array de elementos de menú de navegación.
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
  public manageNavLinks(): IMenu[] {
    const links: IMenu[] = [];

    const menu: menuGCType = menuAdministracion;

    links.push(menu['usuarios']);
    links.push(menu['explotaciones']);

    return links;
  }

  /**
   * Gestiona los enlaces para el componente de menú.
   */
  public manageLinks() {
    const menu: menuGCType = menuMAIN;
    this._footerExitLink = menu['inicio'];
  }

  /**
   * Actualiza los datos del modo basado en el perfil proporcionado
   * @param profile - El objeto de perfil que contiene la información del modo. Si es nulo, no se realizan cambios
   */
  public updateModeDataBasedOnProfile(profile: IProfile | null) {
    if (profile) {
      const isDarkMode = profile?.mode === 'dark';
      this.modeChanged = isDarkMode;
      this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
      this._iconName = isDarkMode ? 'moon' : 'sun';
      this._iconColor = isDarkMode ? 'blue' : 'yellow';
      this._elementRef.nativeElement.style.setProperty(this.footerIcon, `var(--gc-color-${this._iconColor}-tone)`);
      this._elementRef.nativeElement.style.setProperty(this.footerIconHover, `var(--gc-color-${this._iconColor}-opacity-10)`);
    }
  }

  /**
   * Verifica si el ancho de la plataforma es mayor a 768 píxeles
   */
  public checkIsMdUp() {
    const mdUp: number = 768;
    this._isMdUp = this._platform.width() > mdUp;
  }

  /**
   * Verifica si la plataforma actual es un escritorio
   */
  public checkIsDesktop() {
    this._isDesktop = this._platform.is('desktop');
  }

  /**
   * Navega a la URL especificada.
   * @param menu - El objeto de menú que contiene la URL a la que navegar.
   * @param rootNavigation - Un booleano que indica si se debe realizar una navegación raíz.
   * @returns Una Promesa que se resuelve cuando se completa la navegación.
   */
  async navigationUrl(menu?: IMenu, rootNavigation: boolean = false) {
    const link: string = menu?.link ?? null ?? '';

    this._menuController.close();

    if (rootNavigation) {
      await this._navCtrl.navigateRoot([link]);
    } else {
      await this._router.navigate([link]);
    }
  }

  cambioInstalacion() {
    this._userService.selectInstalacion();
  }

  /**
   * Alterna el botón del menú en el componente de la aplicación.
   */
  public toggleBtnMenuCCA() {
    this._compactMenu = !this._compactMenu;
    this.menuCCACompact.emit(this._compactMenu);
    const menuCCABtnIcon = document.getElementById('gc-footer-btn-icon') as HTMLElement;
    menuCCABtnIcon.style.transform = this._compactMenu ? 'rotate(180deg)' : 'rotate(0deg)';
  }

  /**
   * Cambia el modo del perfil entre 'oscuro' y 'claro'.
   *
   * @param ev - El objeto de evento.
   */
  async modeChange(ev: CustomEvent | MouseEvent | KeyboardEvent) {
    const isClickEvent: boolean = ev.type === 'click';
    const isDarkMode: boolean = isClickEvent ? !this.modeChanged : ev.detail.checked;
    const updatedProfile: Partial<IProfile> = { mode: isDarkMode ? 'dark' : 'light' };

    this._iconColor = isDarkMode ? 'blue' : 'yellow';
    this._elementRef.nativeElement.style.setProperty(this.footerIcon, `var(--gc-color-${this._iconColor}-tone)`);
    this._elementRef.nativeElement.style.setProperty(this.footerIconHover, `var(--gc-color-${this._iconColor}-opacity-10)`);

    this.modeChanged = isDarkMode;
    this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
    this._iconName = isDarkMode ? 'moon' : 'sun';

    await lastValueFrom(this._profileService.patchProfile(updatedProfile, 'modeChanged'));
  }

}
