export const estadoActividades = {
    TODOS: {
      auxEstadoActividad: '',
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.TODOS',
    },
    SIN_ENVIAR: {
      auxEstadoActividad: 1,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.SIN_ENVIAR',
    },
    PENDIENTE_VALIDACION: {
      auxEstadoActividad: 2,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.PENDIENTE_VALIDACION',
    },
    VALIDADA_CON_ERRORES: {
      auxEstadoActividad: 3,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.VALIDADA_CON_ERRORES',
    },
    VALIDADA: {
      auxEstadoActividad: 4,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.VALIDADA',
    },
    ERROR_ENVIO: {
      auxEstadoActividad: 5,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.ERROR_ENVIO',
    },
    PENDIENTE_ELIMINACION: {
      auxEstadoActividad: 6,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO',
    },
    ELIMINADA: {
      auxEstadoActividad: 7,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.ELIMINADA',
    },
    CERRADA: {
      auxEstadoActividad: 99,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.CERRADA',
    },
    ENVIO_NO_PERMITIDO: {
      auxEstadoActividad: -1,
      desEstadoActividad: 'CCA.CUADERNO_DIGITAL.ACT_REALIZADAS.ESTADO_ACTIVIDADES.ENVIO_NO_PERMITIDO',
    },
}
// objeto solamente con los valores
export const estadoActividadesCCA = Object.values(estadoActividades);



export enum desEstadoActividad {
  SIN_ENVIAR = 'SIN_ENVIAR',
  PENDIENTE_VALIDACION = 'PENDIENTE_VALIDACION',
  VALIDADA_CON_ERRORES = 'VALIDADA_CON_ERRORES',
  VALIDADA = 'VALIDADA',
  ERROR_ENVIO = 'ERROR_ENVIO',
  PENDIENTE_ELIMINACION = 'PENDIENTE_ELIMINACION',
  ELIMINADA = 'ELIMINADA',
  CERRADA = 'CERRADA',
  ENVIO_NO_PERMITIDO = 'ENVIO_NO_PERMITIDO',
}
