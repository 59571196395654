<div class="alert-content">
  @if(this.template) {
      <ng-container [ngTemplateOutlet]="template" ></ng-container>
  } @else {
      <ng-container [ngTemplateOutlet]="alertTemplate" ></ng-container>
  }
</div>
<div class="alert-footer">
  @for(button of this.buttons; track button) {
      <ion-button class="btn-m-{{this.tipo}}-{{button.tipo}} footer-btn" (click)="button.fn?.()" (keydown.enter)="button.fn?.()">{{button.text}}</ion-button>
  }
</div>

<ng-template #alertTemplate>
  <gc-svg-wrapper class="content-svg" directory="alert" name="gc-{{this.tipo}}"></gc-svg-wrapper>
  <div class="content-header">
      <span class="header-title">{{this.title}}</span>
      @if (this.desc) {
          <span class="header-desc">{{this.desc}}</span>
      }
  </div>
  @if (this.message) {
      <span class="content-message">{{this.message}}</span>
  }
</ng-template>
