import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { permisosEndPoints } from '../enpoints/permisos.endpoints.v1';
import { EMessageTypes } from '../enums/message-type.enum';
import { IArbolPermiso, IBuscadorExplotacionAdmin, IPermiso, IPermisoDetalle, IPermisoGet, ITipoPermiso, PermisoFilter } from '../interfaces/permiso.interface';
import { AlertService } from './alerts/alert.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class PermisosService implements OnDestroy {
  private readonly permisosSubject = new BehaviorSubject<Record<string, IPermisoDetalle> | null>({});
  permisos$ = this.permisosSubject.asObservable();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    private readonly _alert: AlertService,
    private readonly _translate: TranslateService,
    private readonly _login: LoginService,
  ) {
    this.iniciarSubscripciones();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPermisos(idUsuario: string): Observable<Record<string, IPermisoDetalle>> {
    return this.http.get<Record<string, IPermisoDetalle>>(permisosEndPoints.getPermisos(idUsuario));
  }

  putPermisos(idUsuario: string, permisos: Record<string, IPermisoDetalle>): Observable<Record<string, IPermisoDetalle>> {
    return this.http.put<Record<string, IPermisoDetalle>>(permisosEndPoints.putPermisos(idUsuario), permisos);
  }

  getPermisosDisponibles(): Observable<Record<string, IPermisoDetalle>> {
    return this.http.get<Record<string, IPermisoDetalle>>(permisosEndPoints.getPermisosDisponibles);
  }

  postPermiso(permiso: IPermiso): Observable<IPermiso> {
    return this.http.post<IPermiso>(permisosEndPoints.postPermiso, permiso);
  }

  getGrupoPermisos(): Observable<string[]> {
    return this.http.get<string[]>(permisosEndPoints.getGrupoPermisos);
  }

  getPermisosAgrupacion(agrupacion: string): Observable<IArbolPermiso[]> {
    return this.http.get<IArbolPermiso[]>(permisosEndPoints.getPermisosAgrupacion(agrupacion));
  }

  putAllPermisos(options?: PermisoFilter): Observable<IPermisoGet[]> {
    let body = {};
    if(options){
      body = { ...body, ...options };
    }
    return this.http.put<IPermisoGet[]>(permisosEndPoints.putAllPermisos, body);
  }

  getPermiso(idPermiso: number): Observable<IPermiso> {
    return this.http.get<IPermiso>(permisosEndPoints.getPermiso(idPermiso));
  }

  putPermiso(idPermiso: number, permiso: IPermiso): Observable<IPermiso> {
    return this.http.put<IPermiso>(permisosEndPoints.putPermiso(idPermiso), permiso);
  }

  getTipoPermiso(): Observable<ITipoPermiso[]> {
    return this.http.get<ITipoPermiso[]>(permisosEndPoints.getTipoPermiso);
  }

  postTipoPermiso(tipoPermiso: ITipoPermiso): Observable<ITipoPermiso> {
    return this.http.post<ITipoPermiso>(permisosEndPoints.postTipoPermiso, tipoPermiso);
  }

  putExplotacionesFiltradas(options: {}): Observable<IBuscadorExplotacionAdmin[]> {
    return this.http.put<IBuscadorExplotacionAdmin[]>(permisosEndPoints.putExplotacionesFiltradas, { params: options });
  }

  getPermisosUsuarioExplotacion(idPgaExplotacion: string, idUsuario: string): Observable<Record<string, IPermisoDetalle>> {
    return this.http.get<Record<string, IPermisoDetalle>>(permisosEndPoints.getPermisosUsuarioExplotacion(idPgaExplotacion, idUsuario));
  }

  putPermisoUsuarioExplotacion(idUsuario: string, idPgaExplotacion: string | null, codInstalacion: number | null, permisos: Record<string, IPermisoDetalle>):
    Observable<Record<string, IPermisoDetalle>> {
    const params = new HttpParams().set('idPgaExplotacion', idPgaExplotacion ?? '').set('codInstalacion', codInstalacion ?? '');

    return this.http.put<Record<string, IPermisoDetalle>>(
      permisosEndPoints.putPermisosUsuarioExplotacion(idUsuario),
      permisos,
      { params },
    );
  }

  getPermisosUsuarioExplotacionGenerales(codInstalacion: number, idUsuario: string): Observable<Record<string, IPermisoDetalle>> {
    return this.http.get<Record<string, IPermisoDetalle>>(permisosEndPoints.getPermisosUsuarioExplotacionGenerales(codInstalacion, idUsuario));
  }

  getPermisosExplotacionUsuario(): Observable<Record<string, IPermisoDetalle>> {
    return this.http.get<Record<string, IPermisoDetalle>>(permisosEndPoints.getPermisosExplotacionUsuario());
  }

  cargarPermisos(permisos: Record<string, IPermisoDetalle>): void {
    this.permisosSubject.next(permisos);
  }

  /**
   * Inicia las suscripciones necesarias para gestionar los permisos del usuario.
   * Se suscribe al observable `isLoggedIn$` para verificar el estado de autenticación del usuario.
   * Si el usuario está autenticado, se establecen los permisos del usuario.
   * Si el usuario no está autenticado, se emite `null` en el `permisosSubject`.
   */
  iniciarSubscripciones() {
    this._login.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          if (user) {
            this.setUserPermissions();
          }else{
            this.permisosSubject.next(null);
          }
        },
      });
  }

  /**
   * Verifica si los permisos requeridos están presentes en los permisos actuales.
   *
   * @param permisosRequeridos - Un array de permisos requeridos.
   * @returns `true` si todos los permisos requeridos están presentes, de lo contrario `false`.
   */
  hasPermiss(permisosRequeridos: string[]): boolean {
    const permisos = this.permisosSubject.value;
    if (!permisos) {
      return false;
    }
    return permisosRequeridos.every((permiso) => permisos.hasOwnProperty(permiso));
  }

  /**
   * Establece los permisos del usuario una vez que se haya hecho el setLoggedIn.
   */
  setUserPermissions(): void {
    this.getPermisosDisponibles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (permisos) => {
          this.cargarPermisos(permisos);
        },
      });
  }

  showAlert(permiso: string): void {
    this._alert.openAlert(EMessageTypes.warn, {
      code: 0,
      header: this._translate.instant('SETTINGS.PERMISOS.FUNCION_BLOQUEADA'),
      message: this._translate.instant('SETTINGS.PERMISOS.FUNCION_BLOQUEADA_MSG', {permiso}),
      actionButton: () => this._alert.dismiss(),
      actionButtonLabel: this._translate.instant('GENERAL.ACEPTAR'),
      backdropDismiss: false,
    });
  }

  /**
   * Añade nuevos permisos de explotación eliminando primero los permisos de explotación existentes.
   * @param permisosExplotacion - Un objeto con los nuevos permisos a añadir.
   */
  agregarPermisosExplotacion(permisosExplotacion: Record<string, IPermisoDetalle>): void {
    const permisosActuales = this.permisosSubject.value || {};

    const permisosSinExplotacion = Object.keys(permisosActuales)
      .filter((key) => !this.esPermisoDeExplotacion(key))
      .reduce((acc, key) => {
        acc[key] = permisosActuales[key];
        return acc;
      }, {} as Record<string, IPermisoDetalle>);

    const permisosActualizados = {
      ...permisosSinExplotacion,
      ...permisosExplotacion,
    };

    this.permisosSubject.next(permisosActualizados);
  }

  /**
   * Determina si una clave de permiso pertenece a un permiso de explotación.
   * @param key - La clave del permiso.
   * @returns `true` si es un permiso de explotación, `false` en caso contrario.
   */
  private esPermisoDeExplotacion(key: string): boolean {
    return key.startsWith('cca.');
  }

}
