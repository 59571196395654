import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOGGER_PROVIDER } from './logger/logger-provider.token';
import { ILoggerProvider } from './logger/logger-provider.interface';
import { catchError, from, Observable, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocalFileReaderService {

    constructor(
        private readonly http: HttpClient,
        @Inject(LOGGER_PROVIDER) private readonly _logger: ILoggerProvider,
    ) { }


    /**
     * Realiza la lectura de un fichero partiendo de ruta local
     * @param filePath
     */
    readFile(filePath: string): Observable<unknown> {
        return from(fetch(filePath)).pipe(
            switchMap((response) => response.json()),
            catchError(() => of(null)),
        );
    }
}
