import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject, map } from 'rxjs';
import { ILoadingMessage } from 'src/app/globalcampo/services/loading/interfaces/loading-message.interface';
import { LoadingService } from 'src/app/globalcampo/services/loading/loading.service';
@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  standalone: true,
  imports: [ IonicModule, CommonModule, TranslateModule]
})
export class LoadingModalComponent  implements OnInit {
  
  msgObs: Observable<string|null> = new Subject();

  @Input() cancelSubject: Subject<any> = new Subject();

  constructor(private _loadingService: LoadingService) {}

  async cerrar() {
    this.cancelSubject.next(null);
    this.cancelSubject.complete();
    await this._loadingService.closeLoadingModal();
  }

  ngOnInit() {
    this.msgObs = this._loadingService.LoadingMessage$.pipe(
      map(msg => {
        if((msg as ILoadingMessage)?.message){
          return (msg as ILoadingMessage).message;
        }
        if(typeof msg === 'string'){
          return msg as string;
        }
        return '';
      })
    )
  }


}
