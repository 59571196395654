import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of, Subject, switchMap, take, tap } from 'rxjs';
import { urls } from '../../config/urls';
import { tecnicoRolIndex } from '../../config/user-roles';
import { EMessageTypes } from '../../enums/message-type.enum';
import { IAlertContent } from '../../interfaces/alerts/alert-content.interface';
import { IEstadoExplotacion } from '../../interfaces/explotaciones/estado-explotacion.interface';
import { IExplotacion } from '../../interfaces/explotaciones/explotacion.inteface';
import { IInstalacionAmpliado } from '../../interfaces/instalaciones/instalacion.interface';
import { IDatosToken } from '../../interfaces/security/datos-token.interface';
import { AlertService } from '../alerts/alert.service';
import { AltaExplotacionService } from '../alta-explotacion/alta-explotacion.service';
import { AppStatusService } from '../app-status.service';
import { ExplotacionesService } from '../cuadernos-de-campo/explotaciones.service';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { LoadingService } from '../loading/loading.service';
import { ReaService } from '../rea/rea.service';

@Injectable({
    providedIn: 'root',
})
export class CcaVerificarExplotacionUsuarioGuard {
    private readonly errorConfiguracionTxt = 'ALERT.VERIFICAR_EXPLOTACION_GUARD.ERROR_CONFIGURACION';
    constructor(
        private readonly _rea: ReaService,
        private readonly _globalcampo: GlobalcampoService,
        private readonly _explotaciones: ExplotacionesService,
        private readonly _app: AppStatusService,
        private readonly _alert: AlertService,
        private readonly _loading: LoadingService,
        private readonly _altaExplotacion: AltaExplotacionService,
        private readonly _router: Router,
    ) { }

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {

        return this._loading.loadWithMessage(this._globalcampo.getInstalacionesAmpliado(), 'MODAL.LOADING.ACCEDIENDO_A_CUADERNO', false)
            .pipe(
                take(1),
                switchMap((instalaciones) => {
                    if (instalaciones && instalaciones.length > 0) {
                        // De momento seleccionamos la primera instalación (que será única hasta más adelante)
                        const instalacion = instalaciones[0];
                        if (instalacion.codRol === tecnicoRolIndex) {
                            return of(true);
                        }
                        //obtenemos token cca de la instalacion
                        const obtainTokenCCA = this._explotaciones.postTokenInstalacionExplotacion(instalacion.codInstalacion, '');
                        return obtainTokenCCA.pipe(
                            switchMap((ccaToken) => {
                                //registramos el token cca
                                if (ccaToken?.token?.accessToken) {
                                    return this.handleInstalacionTokenCca(ccaToken, instalacion);
                                } else {
                                    this._app.ccaToken = null;
                                    return of(false);
                                }
                            }),
                            catchError(() => of(false)),
                        );
                    } else {
                        this._alert.errorAlert(
                            this.errorConfiguracionTxt,
                            'ALERT.VERIFICAR_EXPLOTACION_GUARD.SIN_CUADERNO',
                        );
                        return of(false);
                    }
                }),
                catchError(() => of(false)),
            );
    }

    handleInstalacionTokenCca(ccaToken: IDatosToken, instalacion: IInstalacionAmpliado) {
        this._app.ccaToken = ccaToken.token.accessToken;
        return this._rea.comprobarExplotacion()
            .pipe(
                switchMap((explotaciones) => {
                    this._loading.closeLoadingModal();
                    if (explotaciones && explotaciones.length > 0) {
                        const explotacion = explotaciones[0];
                        if (explotacion.documentacionValidaRea) {
                            return this.handleExplotacionValida(instalacion, explotacion);
                        } else {
                            this._alert.errorAlert(
                                'ALERT.VERIFICAR_EXPLOTACION_GUARD.DOCUMENTACION_PENDIENTE_FIRMA',
                                'ALERT.VERIFICAR_EXPLOTACION_GUARD.NECESARIO_FIRMAR_DOCUMENTACION',
                            );
                        }
                        return of(false);
                    } else {
                        this._alert.errorAlert(
                            'ALERT.VERIFICAR_EXPLOTACION_GUARD.SIN_EXPLOTACIONES',
                            'ALERT.VERIFICAR_EXPLOTACION_GUARD.SIN_EXPLOTACIONES_MSG',
                        );
                        return of(false);
                    }
                }),
            );
    }

    /**
     * Maneja la explotación válida
     * @param instalacion
     * @param explotacion
     */
    handleExplotacionValida(instalacion: IInstalacionAmpliado, explotacion: IEstadoExplotacion) {
        if (explotacion.descargaRea) {
            return this.handleExplotacionValidaDescargada(instalacion, explotacion);
        } else {
            return this.handleExplotacionValidaNoDescargada(instalacion, explotacion);
        }
    }

    /**
     * Maneja la explotación válida descargada
     * @param instalacion
     * @param explotacion
     */
    handleExplotacionValidaDescargada(instalacion: IInstalacionAmpliado, explotacion: IEstadoExplotacion) {
        return this._explotaciones.postTokenInstalacionExplotacion(instalacion.codInstalacion, explotacion.codIdentific)
            .pipe(
                switchMap((token) => {
                    if (token?.token?.accessToken) {
                        this._app.ccaToken = token.token.accessToken;
                        return this._explotaciones.getDatosExplotacion(explotacion.codIdentific).pipe(
                            tap((explotacionData) => {
                                this._app.setCurrentInstalacion(instalacion);
                                this._app.setCurrentExplotacion(explotacionData);
                            }),
                            map(() => this._router.parseUrl(urls.cca.dashboard)),
                        );
                    } else {
                        this._alert.errorAlert(
                            this.errorConfiguracionTxt,
                            'ALERT.VERIFICAR_EXPLOTACION_GUARD.ERROR_TOKEN_CCA',
                        );
                        return of(false);
                    }
                }),
            );
    }

    /**
     * Maneja la explotación válida no descargada
     * @param instalacion
     * @param explotacion
     */
    handleExplotacionValidaNoDescargada(instalacion: IInstalacionAmpliado, explotacion: IEstadoExplotacion) {
        const mySub: Subject<boolean> = new Subject<boolean>();
        const alertOptions: IAlertContent = {
            header: 'ALERT.VERIFICAR_EXPLOTACION_GUARD.DESCARGA_EXPLOTACION.HEADER',
            message: 'ALERT.VERIFICAR_EXPLOTACION_GUARD.DESCARGA_EXPLOTACION.MESSAGE',
            actionButtonLabel: 'ALERT.VERIFICAR_EXPLOTACION_GUARD.DESCARGA_EXPLOTACION.ACTION_BTN',
            actionButton: () => {
                const pasos = this._altaExplotacion.theSteps;
                this._app.setCurrentInstalacion(instalacion);
                const partialExplotacion: Partial<IExplotacion> = {
                    codIdentific: explotacion.codIdentific,
                    id: explotacion.idPgaExplotacion,
                };
                this._app.setCurrentExplotacion(partialExplotacion as IExplotacion);
                this._altaExplotacion.nextStep(pasos.DESCARGA_REA);
                mySub.next(true);
                mySub.complete();
            },
            subActionButtonLabel: 'ALERT.VERIFICAR_EXPLOTACION_GUARD.DESCARGA_EXPLOTACION.CANCEL_BTN',
            subActionButton: () => {
                mySub.next(false);
                mySub.complete();
            },
        };
        this._alert.openAlert(EMessageTypes.success, alertOptions);
        return mySub.asObservable().pipe(map((val) => val ? this._router.parseUrl(urls.cca.alta_explotacion) : false));
    }
}
