import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { AzureGlobalcampoB2CConfig } from './azure-ad.config';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export const b2cPolicies = {
  names: {
    //   signUpSignIn: 'B2C_1_InicioSesion',
      signIn: environment.azureB2cPolicies.signIn,
      // editProfile: "b2c_1_edit_profile_v2"
  },
  authorities: {
      signUpSignIn: {
          authority: environment.azureB2cPolicies.signUpSignInAuthority,
      },
      // editProfile: {
      //     authority: "https://your-tenant-name.b2clogin.com/your-tenant-name.onmicrosoft.com/b2c_1_edit_profile_v2"
      // }
  },
  authorityDomain: 'globalcampoapp.b2clogin.com',
};
export const msalConfig: Configuration = {
        auth: {
          // Application (client) ID from the app registration
          clientId: AzureGlobalcampoB2CConfig.AppID,
          // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          knownAuthorities: [b2cPolicies.authorityDomain],
          // This is your redirect URI,
          redirectUri: environment.azureReditectUri,
          postLogoutRedirectUri: `${environment.azureReditectUri}/logout`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          // Set to true for Internet Explorer 11
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            loggerCallback(_logLevel, _message, _containsPii) { },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
      },
};
export const protectedResourcesMap = new Map([
            //["*", ['https://gicoop-application-proxy-dev.gicoop.es/gicoop.all']],

        // dejamos solo las llamadas a la api para evitar que otra peticiones externas, como la del clima, se controlen también por el token de Azure
        // [`${environment.apiURL}api/*`, [`${environment.azureAPI}globalcampo.all`]],
        [`${environment.apiURL}*`, [`${environment.azureAPI}globalcampo.all`]],
        // ['https://api-dev.globalcampo.es/api/cca', [`${environment.azureAPI}globalcampo.all`]],
        // ['/sec', [`${environment.azureAPI}globalcampo.all`]],
        // ['/cue', [`${environment.azureAPI}globalcampo.all`]],
        // ['/instalaciones', [`${environment.azureAPI}globalcampo.all`]],
        // ['https://api-dev.globalcampo.es/api/ctl', [`${environment.azureAPI}globalcampo.all`]],
        // ['/globalcampo', [`${environment.azureAPI}globalcampo.all`]],
        // ['/acttratamfito', [`${environment.azureAPI}globalcampo.all`]],
        // ['/weather', [`${environment.azureAPI}globalcampo.all`]],
        // ['/auxx', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actagraria', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actfertilizacion', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actanalitica', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actcosecha', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actdatoscubierta', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actedificacion', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actpostcosecha', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actcomercializacion', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actpastoreo', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actusosemillatratada', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actplanabonado', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actsiembraplantacion', [`${environment.azureAPI}globalcampo.all`]],
        // ['/actenergia', [`${environment.azureAPI}globalcampo.all`]],
        // ['/logalty', [`${environment.azureAPI}globalcampo.all`]],
        // ['/feedback', [`${environment.azureAPI}globalcampo.all`]],
        // ['https://api-dev.globalcampo.es/api/app', [`${environment.azureAPI}globalcampo.all`]],
        // ['/service', [`${environment.azureAPI}globalcampo.all`]],
        // ['/rea', [`${environment.azureAPI}globalcampo.all`]],
]);
