
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Injectable } from '@angular/core';
import { LoginService } from "../login.service";
import { Observable, map, take } from "rxjs";
import { urls } from "../../config/urls";

@Injectable({
    providedIn: 'root'
})
export class IfAuthorizedGoToHomePage {
    
    constructor(private login: LoginService, private router: Router) {}
    
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
        /** Si estamos logeados redirigimos a inicio */
        return this.login.isLoggedIn$
        .pipe(
            take(1),
            map(loggedIn => {
                if(!!loggedIn){
                    return this.router.parseUrl(urls.inicio);
                }
                return true;
            } )
        );
    }
}