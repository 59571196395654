import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStatusService } from '../services/app-status.service';
import { tokenCCAString } from '../config/app-config';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private readonly app: AppStatusService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // Headers' processing
        let {headers, params} = req;

          const ccaTOken = this.app.ccaToken;
          if(ccaTOken){
            headers = headers.append(tokenCCAString, ccaTOken);
          }

        /* POR si falla el primer servicio
        if (req.url.includes('sigpac.mapa.es/vectorsdg/vector/recinto')) {
            const newUrl = this.modifyUrl(req.url);
            const modifiedReq = req.clone({ url: newUrl });
            return next.handle(modifiedReq);
          }*/

        req = req.clone({ headers, params });
        return next.handle(req);
    }
    private modifyUrl(oldUrl: string): string {
        return oldUrl.replace('sigpac.mapa.es/vectorsdg/vector/recinto', 'sdg-sigpac.jccm.es/vector/recinto');
      }
}