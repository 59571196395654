import { CommonModule } from '@angular/common';
import { Component, computed, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, filter, lastValueFrom, map, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WelcomeComponent } from './components/info-slides/welcome/welcome.component';
import { HeaderMainComponent } from './components/navigation/header-main/header-main.component';
import { MenuCcaComponent } from './components/navigation/menu-cca/menu-cca.component';
import { initGlobalcampoIcons } from './globalcampo/config/globalcampo-icons.config';
import { urls } from './globalcampo/config/urls';
import { SplitPaneState } from './globalcampo/interfaces/layout.interface';
import { IMenu } from './globalcampo/interfaces/menu-item.interface';
import { AppStatusService } from './globalcampo/services/app-status.service';
import { ILoggerProvider } from './globalcampo/services/logger/logger-provider.interface';
import { LOGGER_PROVIDER } from './globalcampo/services/logger/logger-provider.token';
import { LoginService } from './globalcampo/services/login.service';
import { IProfileService } from './globalcampo/services/profile/profile-service.interface';
import { IProfile } from './globalcampo/services/profile/profile.interface';
import { PROFILE_TOKEN } from './globalcampo/services/profile/profile.token';
import { menuComponentes } from './pages/_components/internalComponents.menu';
import { menuSettings } from './pages/_settings/internalSettings.menu';
import { MenuSettingsComponent } from './components/navigation/menu-settings/menu-settings.component';

@Component({
  selector: 'gc-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule, MenuCcaComponent, HeaderMainComponent, MenuSettingsComponent],
})
export class AppComponent implements OnInit {

  /**
   * Gets the menu components based on the current layout.
   * @returns An array of menu items.
   */
  public get componentesMenu(): IMenu[] {
    if(this.splitPaneContent() === 'settings') {
      return menuSettings;
    }
      return menuComponentes;
  }

  /**
   * Determina si el usuario está o no logueado
   */
  private _loggedIn: boolean = false;
  public get loggedIn(): boolean {
    return this._loggedIn;
  }

  /**
   * Devuelve el contenido del panel dividido.
   *
   * @returns El contenido del panel dividido.
   */
  public splitPaneContent = computed(() => this._appStatus.mainLayout().splitPaneContent);

  /**
   * Indica si el ion-menu debe mostrarse compacto.
   */
  private  _compactMenu: boolean = false;
  public get compactMenu(): boolean {
    return this._compactMenu;
  }

  constructor(
    private readonly _login: LoginService,
    @Inject(PROFILE_TOKEN) private readonly _profile: IProfileService,
    @Inject(LOGGER_PROVIDER) private readonly _logger: ILoggerProvider,
    private readonly _modal: ModalController,
    private readonly _appStatus: AppStatusService,
    private readonly _router: Router,
  ) { }

  ngOnInit(): void {

    /**
     * Inicializa la creación de nuevos iconos
     */
    initGlobalcampoIcons();

    /**
     * Muestra de los datos de entorno por la consola
     */
    this.environmenConsoleLogs();

    // Subscripción a los cambios de usuario
    this._login.isLoggedIn$.pipe(
      tap((user) => {
        this._loggedIn = !!user;
      }),
      switchMap(() => this._profile.getProfile()),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    ).subscribe((profile) => {
        this.applyProfileSettings(profile);
    });
    /**
     * Gestión de inicio de navegación
     */
    this._router.events
    .pipe(
      filter((x) => x instanceof NavigationStart),
      map((x) => (x as NavigationStart).url ),
      )
    .subscribe((url) => {
      this.manageAuthorized(url);
    });

    /**
     * Gestión de finalización de navegación
     */
    this._router.events
    .pipe(
      filter((x) => x instanceof NavigationEnd),
      map((x) => (x as NavigationEnd).url ),
      )
    .subscribe((url) => {
      this.manageLayout(url);
    });

  }

  /**
   * Gestión del estado de autorización en la navegación a una URL
   * @param url URL a la que se quiere navegar
   */
  private manageAuthorized(url: string): void {

    if (!url.includes(urls.notAuthorized) && !this._loggedIn) {
      this._router.navigateByUrl(urls.notAuthorized);
      this._appStatus.setMainLayout({ splitPaneContent: 'none' });
    }

  }
  /**
   * Gestiona el layout de la aplicación en función de la ruta a la que se ha realizado la navegación
   * @param url Url de navegación
   */
  private manageLayout(url: string): void {
    let layout: SplitPaneState = { splitPaneContent: 'none' };

    if (url.includes('cca')) {
      layout = { splitPaneContent: 'cca' };
    }
    else if (url.includes('componentes')) {
      layout = { splitPaneContent: 'componentes' };
    }
    else if (url.includes('settings')) {
      layout = { splitPaneContent: 'settings' };
    }
    else if (this._loggedIn) {
      layout = { splitPaneContent: 'main' };
    }

    this._appStatus.setMainLayout(layout);
  }

  /**
   * Muestra la información de los datos de entorno sobre los que se está ejecutando la aplicación
   */
  private environmenConsoleLogs(): void {
    this._logger.info('API', environment.apiURL);
    this._logger.info('Production', environment.production);
    this._logger.info('Preproduction', environment.preproduction);
  }

   /**
   * Realiza la gestión del perfil del usuario
   * @param profile  El perfil a gestionar
   */
  private async applyProfileSettings(profile: IProfile | null) {
    let prefersDark;
    const defaultVariante = 'default';
    let variante;
    if (profile) {
      prefersDark = profile?.mode === 'dark';
      variante = profile.theme? profile.theme: defaultVariante;
    } else {
      prefersDark = this.isSystemDarkMode();
      variante = defaultVariante;
    }
    this.addDarkClassToBody(prefersDark);
    this.addThemeClassToBody(variante);

    if (!profile?.ModalWelcome) {
      const modal = await this._modal.create({
        component: WelcomeComponent,
        backdropDismiss: false,
      });

      modal.present();

      const updatedProfile: Partial<IProfile> = { ModalWelcome: new Date(), lastAccess: new Date(), mode: prefersDark ? 'dark' : 'light' };
      await lastValueFrom(this._profile.patchProfile(updatedProfile, 'APP component > ModalWelcome'));

      await modal.onDidDismiss();
    }
  }


  /**
   * Devuelve un boolean indicando si el sistema tiene definido modo oscuro
   */
  private isSystemDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Añade las clases necesarias al body para gestionar los estilos de modo light / dark
   * @param shouldAdd Si se debe añadir o no
   */
  private addDarkClassToBody(shouldAdd: boolean){
    if(shouldAdd && !document.body.classList.contains('dark')){
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    }
    if(!shouldAdd && !document.body.classList.contains('light')){
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    }

  }

  /**
   * Añade al body la clase css relativa al theme utilizado
   * @param theme El theme utilizado
   */
  addThemeClassToBody(theme: string|undefined): void {
    this._appStatus.themesAvailable.forEach((availableTheme) => {
      if (!!theme && availableTheme.type === theme) {
        // agregamos la del theme seleccionado
        document.body.classList.add(theme);
      } else {
        // elimina todas las posibles clases de tema actualmente añadidas, y deja solo la seleccionada
        document.body.classList.remove(availableTheme.type);
      }

    });
  }


  /**
   * Establece el modo compacto para el menú.
   *
   * @param compact - Un valor booleano que indica si el menú debe ser compacto.
   */
  menuCompact(compact: boolean): void {
    this._compactMenu = compact;
  }

}
