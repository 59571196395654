/** Enum de los tipos de mensaje que existen en la aplicación */

import { MessageTypes } from "../types/message-types.type";

// Existe un type alias con el mismo nombre que es el tipo de donde optenemos estos tipos. Se crea el enum para usar mas facilmente en caso de ser necesario.
export enum EMessageTypes {
  default = 'default',
  error = 'error',
  warn = 'warn',
  success = 'success',
  question = 'question',
  info = 'info',
  none = 'none',
}
