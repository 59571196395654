import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IWLWeatherData } from './interfaces/weather-data.interface';
import { IWLCurrenStationtWeatherData } from './interfaces/current-weater-data.interface';
import { IWLStation } from './interfaces/station.interface';
import { GeoPointsDistanceCalculator } from 'src/app/utils/geopoints-distance-calculator.util';
import { environment } from 'src/environments/environment';
import { IGCWeatherStationData } from '../../interfaces/globalcampo-wheater-station-data.inteface';
import { IGCCurrentWeatherData } from '../../interfaces/globalcampo-current-weather-data.interface';
import { IGeoPosition } from '../../interfaces/geoposition.inteface';
import { IGCStationsProvider } from '../../interfaces/globalcampo-station-provider.interface';
import { weatherEndPoints } from '../../enpoints/weather.endpoints.v1';

@Injectable()
export class WeatherlinkApiService implements IGCStationsProvider {

  private stationCache: {cacheTime: Date|null, data: IGCWeatherStationData[]| null} = {cacheTime: null, data: null};
  private currentDataCache: { [station_id: string]: any } = {};
  private historicDataCache: { [station_id: string]: any } = {};

  apiKey = environment.weatherLinkApiKey; 
  apiSecret = environment.weatherLinkApiSecret; 

  constructor( private http: HttpClient ) {  }

 /**
  * Toma un objeto de tipo IWLStation y lo convertirá en un objeto de tipo IGCWeatherStationData.
  */
 private mapToIGCWeatherStationData(station: IWLStation): IGCWeatherStationData {
  try {
      let currentData = null;
      if (station.currentData) {
          currentData = this.mapToIGCCurrentWeatherData(station.currentData);
      }
      return {
          name: station.station_name || "",
          position: {
              lat: station.latitude || 0,
              lng: station.longitude || 0
          },
          station_id: station.station_id || 0,
          stationIdUuid: station.station_id_uuid || "",
          currentData: station.currentData,
          sensors: station.sensors,
      };
  } catch (error) {
      console.error("Error during mapping:", error);
      throw error;
  }
}


 /**
  * Toma un objeto de tipo IWLCurrenStationtWeatherData y lo convertirá en un objeto de tipo IGCCurrentWeatherData.
  */
 private mapToIGCCurrentWeatherData(data: IWLCurrenStationtWeatherData): IGCCurrentWeatherData {
  if (!data) {
      throw new Error("Current data is undefined or null");
  }
  return {
      temperature: data.temperature || 0,
      humidity: data.humidity || 0,
      windSpeed: data.windSpeed || 0,
      windDirection: data.windDirection || 0,
  };
};

 /**
 * Obtiene una lista de estaciones meteorológicas. 
 * @param loadStationsData - Si es true, carga los detalles del tiempo actual de cada estación. Si es false solo devuelve una lista básica
 */
getStations(loadStationsData: boolean = false): Observable<IGCWeatherStationData[]> { 
    // Recuperamos los datos guardados en la caché
    const currentTime = new Date().getTime() / 1000; //time in secods
    const oneDay = 86400; // a day in seconds
    if (this.stationCache && this.stationCache.data && ((this.stationCache.cacheTime?.getTime() || 0)/1000 + oneDay) > currentTime) {
      return of(this.stationCache.data);
    }

    const {timeStamp, signature} = this.generateURLParams();
    const urlStation = `https://corsproxy.io/?https://api.weatherlink.com/v2/stations?api-key=${this.apiKey}&t=${timeStamp}&api-signature=${signature}`;
    
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    
    return this.http.get<IWLWeatherData>(urlStation, { headers: headers }).pipe(
      map(response => response.stations.map(station => this.mapToIGCWeatherStationData(station)) ),
      switchMap(stations => {
        
        if (loadStationsData) {
          const detailObs = stations.map(station =>
            this.getCurrentDataByStationId(station.station_id).pipe(
              map(detail => {
                    station.currentData = detail;
                    return station;
                  })
          ));
          return forkJoin(detailObs);
        } else {
          return of(stations);
        }
      }),
      tap(mappedStations => {
        this.stationCache = { cacheTime: new Date(), data: mappedStations};        
      }),
      catchError(error => {
        console.error('Error al recuperar los datos de las estaciones: ', error);
        return of([]);
      })
    );
  };

  
  getNearbyStationData(distance: number, userLocation: IGeoPosition ): Observable<IGCWeatherStationData[] | null> {

      if(!userLocation || isNaN(distance)) {
        return of(null);
      }
      return this.getStations().pipe(
        map(stations => 
          stations.filter(station =>
            GeoPointsDistanceCalculator.haversineDistance(
              userLocation.lat, 
              userLocation.lng, 
              station.position.lat, 
              station.position.lng
              ) <= distance
        )
      )
    );
  };
  
  getStationDataByUIds(stationsUIds: string[]): Observable<IGCWeatherStationData[] | null> { 
    return of(null)
  }


  /**
   * Llamada al detalle de estación por id
   * @returns 
   */
  getCurrentDataByStationId(station_id: number): Observable<IGCCurrentWeatherData | null> {
    // Recuperamos los datos guardados en la caché
    if (this.currentDataCache[station_id]) {
      return of(this.currentDataCache[station_id]);
    };

    // Si no hay datos guardados en caché, llamamos a la API
    const {timeStamp, signature} = this.generateURLParams(station_id);
    const urlCurrent = `https://corsproxy.io/?https://api.weatherlink.com/v2/current/${station_id}?api-key=${this.apiKey}&t=${timeStamp}&api-signature=${signature}`;


    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    
    return this.http.get(urlCurrent, { headers: headers }).pipe(
      map((response: any) => {
        console.log(response);
        const currentWeatherData:  IGCCurrentWeatherData = {
          temperature: null,
          humidity: null,
          windSpeed: null,
          windDirection: null,
        };
        const weatherSensor = response.sensors.find((sensor: any) => sensor.sensor_type === 43);
        if (weatherSensor && weatherSensor.data && weatherSensor.data[0]) {
          const weatherData = weatherSensor.data[0];
          currentWeatherData.temperature = weatherData.temp ? (weatherData.temp - 32) * (5/9) : null;
          currentWeatherData.humidity = weatherData.hum || null;
          currentWeatherData.windSpeed = weatherData.wind_speed_last ? weatherData.wind_speed_last * 1.60934 : null;
          currentWeatherData.windDirection = weatherData.wind_dir_last || null;
          currentWeatherData.when = new Date(weatherData.ts * 1000);
          currentWeatherData.rainYearMM = weatherData.rainfall_year_mm || null;
          currentWeatherData.rainMonthMM = weatherData.rainfall_monthly_mm || null;
          currentWeatherData.rain60MMM = weatherData.rainfall_last_60_min_mm || null;
          currentWeatherData.rain24HMM = weatherData.rainfall_last_24_hr_mm || null;
          currentWeatherData.rain15MMM = weatherData.rainfall_last_15_min_mm || null;
        }
        return currentWeatherData;
      }),
      tap(response => {
        console.log(response);
        if(!!response){
          this.currentDataCache[station_id] = response;
        }
      }),
      catchError(error => {
        console.error('Error CurrentData: ', error);
        return of(null);
      })
    );
  };


    /**
   * Llamada al detalle del histórico de estación por uuid
   * @returns 
   */
    getHistoricStationData(station_id: number, startTimestamp: number, endTimestamp: number): Observable<{data: any, url: string}> {
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
      const calculateUrl = (start: number, end: number) => {
         
          const timeStamp = Math.floor(Date.now() / 1000);
          const currentTime = new Date().getTime();
          
          const sortedParameters = [
            {key: 'api-key', value: this.apiKey},
            {key: 'end-timestamp', value: end.toString()},
            {key: 'start-timestamp', value: start.toString()},
            {key: 'station-id', value: station_id},
            {key: 't', value: timeStamp.toString()},
            {key: 'nc', value: currentTime.toString()},
          ].sort((a, b) => a.key.localeCompare(b.key));
          
          const message = sortedParameters.reduce((acc, cur) => acc + cur.key + cur.value, '');
          const signature = CryptoJS.HmacSHA256(message, this.apiSecret).toString(CryptoJS.enc.Hex);
      
          return `https://corsproxy.io/?https://api.weatherlink.com/v2/historic/${station_id}?api-key=${this.apiKey}&t=${timeStamp}&start-timestamp=${start}&end-timestamp=${end}&nc=${currentTime}&api-signature=${signature}`;
      };
  
      const urlHistoric = calculateUrl(startTimestamp, endTimestamp);
      const urlHistoricNext = calculateUrl(startTimestamp + (24*60*60), endTimestamp + (24*60*60));
  
      const calls = forkJoin([
          this.http.get(urlHistoric, { headers: headers }),
          this.http.get(urlHistoricNext, { headers: headers }),
      ]);
  
      return calls.pipe(
          map(data => {
          console.log('Historic Data:', data);
          return {data, url: urlHistoric};
          }),
          tap(response => {
          this.historicDataCache[station_id] = response;
          }),
          catchError(error => {
          console.error('Error fetching historic data:', error);
          return of({data: {}, url: ''});
          })
      );
  };
  
  
  
  private generateURLParams(station_id?: number): {timeStamp: number, signature: string} {

    let stationIdMessage: string = '';
    if(!!station_id) {
      stationIdMessage += `station-id${station_id}`;
    }
    const timeStamp = Math.floor(Date.now() / 1000);
    let message = `api-key${this.apiKey}${stationIdMessage}t${timeStamp}`;
    const signature = CryptoJS.HmacSHA256(message, this.apiSecret).toString(CryptoJS.enc.Hex);
    return {timeStamp, signature};
  }

  getStationHistoric(stationId: number, from: Date, to: Date): Observable<any> {
      return this.http.put<any>(weatherEndPoints.putHitoricData(stationId), { fchInicio: from.toISOString(), fchFin: to.toISOString()});  
  }
};  