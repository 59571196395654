import { HttpHeaders } from '@angular/common/http';
import { IRequestOptions } from '../interfaces/log/options.interface';


export class HeaderLogConfig {
    /**
     * Comprueba 204 y contenido vacío.
     *
     * @returns opciones para cabeceras GCLogRequest.
     */
    static getMonitoredWithEmptyContent(): { headers: HttpHeaders } {
      const options: IRequestOptions = {
        monitor: true,
        criticalStatus: 204,
        logEmptyContent: true,
      };

      return this.createHeaders(options);
    }

    /**
     * Comprueba 204 y no contenido vacío.
     *
     * @returns opciones para cabeceras GCLogRequest.
     */
    static getMonitoredWithoutEmptyContent(): { headers: HttpHeaders } {
      const options: IRequestOptions = {
        monitor: true,
        criticalStatus: 204,
      };

      return this.createHeaders(options);
    }

    /**
     * Genera cabeceras personalizadas para el log de solicitudes HTTP.
     *
     * Permite configurar opciones específicas para incluir en la cabecera `GCLogRequest`.
     * Las opciones permiten monitoreo, manejo de estados críticos, y más.
     *
     * Opciones disponibles en `IRequestOptions`:
     * - `monitor` (boolean): Activa el monitoreo de la solicitud.
     * - `criticalStatus` (string): Define el estado HTTP que debe considerarse crítico (ej.: '204').
     * - `logEmptyContent` (boolean): Indica si las respuestas 200 vacías deben ser monitoreadas.
     * - `action` ('stop' | 'retry' | 'redirect' | 'log'): Acción a realizar en caso de un estado crítico. (pendiente de implementación).
     * - `toConsole` (boolean): Indica si se debe mostrar el log en la consola.
     * - `notifyUser` (boolean): Notifica al usuario en caso de error con un alert.
     *
     * @param options Opciones de configuración para las cabeceras.
     * @returns Un objeto con las cabeceras configuradas para `GCLogRequest`.
     */
    static getCustomLog(options: IRequestOptions): { headers: HttpHeaders } {
      return this.createHeaders(options);
    }

    /**
     * Construis cabeceras.
     *
     * @param options Opciones de configuración para las cabeceras.
     * @returns cabeceras GCLogRequest bien configuradas.
     */
    private static createHeaders(options: IRequestOptions): { headers: HttpHeaders } {
      return {
        headers: new HttpHeaders({
          'GCLogRequest': JSON.stringify(options),
        }),
      };
    }
  }
