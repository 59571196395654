import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const reaEndPoints = {
    getComprobarSga:
    (codIdentific: string, codComunidadAutonoma: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/comprobarsga/${codIdentific}/${codComunidadAutonoma}`,
    exportarInfoDgc: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/exportarinfodgc/${codIdentific}`,
    exportarMaquinaria: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/exportarmaquinaria/${codIdentific}`,
    exportarMiniaturasDGC: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/exportarminiaturasdgc/${codIdentific}`,
    cargarRea: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/cargarrea/${codIdentific}`,
    exportarRea: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/exportarrea/${codIdentific}`,
    asignacionTecnico: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}rea/${apiVx}/asignaciontecnico/${codIdentific}`,
    comprobarExplotacion: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/comprobarexplotacion`,
};
