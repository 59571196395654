@if (!usuario) {
  <ion-button class="user-btn btn-s-accent-solid-static" (click)="openLogin()" (keydown.enter)="openLogin()">
    <span class="btn-label">{{ loginText | translate }}</span>
    <ion-icon class="btn-icon" name="gc-user-user-05"></ion-icon>
  </ion-button>
}

@if(!!usuario){
  <div class="user-profile" (click)="openModal()"  (keydown.enter)="openModal()">
    <div class="profile-img">
      <span class="img"> {{ img }} </span>
    </div>
    <ion-button class="btn-s-accent-clear-static profile-btn hide-profile-btn">
      {{ usuario | titlecase }}
    </ion-button>
  </div>
}

<ion-modal id="modal" class="gc-user-profile-modal">
  <ng-template>
    <div class="modal-header">
      <ion-icon class="header-cancel" name="gc-user-cross" (click)="closeModal()" (keydown.enter)="closeModal()"></ion-icon>
    </div>
    <div class="modal-content">
      <div class="gc-user-profile-modal-profile">
        <div class="gc-user-profile-img">
          <span class="img"> {{ img }} </span>
        </div>
        <div class="gc-user-profile-user-mail">
          <span class="user"> {{ fullUserName | titlecase }} </span>
          <span class="mail"> {{ mail }} </span>
        </div>
      </div>
      <div class="gc-user-profile-modal-settings-link" (click)="openSettings()" (keydown.enter)="openSettings()">
        <a class="link settings">
          <ion-icon class="link-icon" name="gc-user-settings"></ion-icon>
          {{ "MENU.MAIN.PROFILE.CONFIG" | translate }}
        </a>
      </div>
      <div class="gc-user-profile-modal-logout-link" (click)="closeSession()" (keydown.enter)="closeSession()">
        <a class="link logout">
          <ion-icon class="link-icon" name="gc-arrow-out"></ion-icon>
          {{ "MENU.MAIN.PROFILE.LOGOUT" | translate }}
        </a>
      </div>
    </div>
  </ng-template>
</ion-modal>

