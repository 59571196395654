
export const AzureGicoopTentantConfig = {
    TenantID: 'd8530c2a-49d4-444b-a83f-a0e2cb24a6a8',
    AppID: '020e70ee-7ff7-4310-9ad9-7538022b058f',
    azureAPI: 'api://3caabee1-ebff-4557-9642-04fbca0efee5/',
    GlobalcampoApi: {
        scopes: {
            all: `api://3caabee1-ebff-4557-9642-04fbca0efee5/globalcampo.all `,
            actividades: `api://3caabee1-ebff-4557-9642-04fbca0efee5/globalcampo.actividades `,
        },
    },
};
export const AzureGlobalcampoB2CConfig = {
    TenantID: '8c3a8d2a-4d42-4b9d-9214-ba039524770b',
    AppID: 'b4e32a03-bded-4ccb-9f64-d39f69046596',
    GlobalcampoApi: {
        azureAPI: 'https://globalcampoapp.onmicrosoft.com/e46eaab4-af1b-43dd-83a1-d704e416ff73/',
        scopes: {
            all: `https://globalcampoapp.onmicrosoft.com/e46eaab4-af1b-43dd-83a1-d704e416ff73/globalcampo.all `,
            actividades: `https://globalcampoapp.onmicrosoft.com/e46eaab4-af1b-43dd-83a1-d704e416ff73/globalcampo.actividades `,
        },
    },
};
