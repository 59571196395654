import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermisosService } from '../services/permisos.service';

@Directive({
  selector: '[gcPermisos]',
  standalone: true,
})
export class PermisosDirective implements OnInit, OnDestroy {
  private permiso: string[] | null = null;
  private permisosSubscription: Subscription | null = null;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly permisosService: PermisosService,
  ) {}
  @Input() set gcPermisos(permiso: string[]) {
    this.permiso = permiso;
  }

  ngOnInit(): void {
    this.permisosSubscription = this.permisosService.permisos$.subscribe(() => {
      this.updateView();
    });

    this.updateView();
  }

  /**
   * Actualiza la vista según los permisos del usuario.
   *
   * Si el usuario tiene los permisos requeridos, se renderiza el elemento
   * utilizando el `templateRef`. Si no, se elimina de la vista.
   *
   * @private
   */
  private updateView(): void {
    if (this.permiso) {
      const permisosRequeridos: string[] = Array.isArray(this.permiso)
        ? this.permiso
        : [this.permiso as string];

      const hasPermiss = this.permisosService.hasPermiss(permisosRequeridos);

      this.viewContainer.clear();

      if (hasPermiss) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  ngOnDestroy(): void {
    this.permisosSubscription?.unsubscribe();
  }
}
