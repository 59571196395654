import { Inject, Injectable } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { BehaviorSubject, filter, switchMap, tap } from 'rxjs';
import { CustomNavigationClient } from 'src/app/utils/custom-navigator';
import { IUser } from '../interfaces/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alerts/alert.service';
import { AzureGlobalcampoB2CConfig } from '../config/azure-ad.config';
import { Capacitor } from '@capacitor/core';
import { LOGGER_PROVIDER } from './logger/logger-provider.token';
import { ILoggerProvider } from './logger/logger-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {


  private readonly _isLoggedIn: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
  public readonly isLoggedIn$ = this._isLoggedIn.asObservable();

  //TODO: Eliminar acoplamiento a librería de microsoft e ionic
  constructor(private readonly _msalB: MsalBroadcastService,
              private readonly _msal: MsalService,
              private readonly iab: InAppBrowser,
              private readonly _alert: AlertService,
              private readonly _trans: TranslateService,
              @Inject(LOGGER_PROVIDER) private readonly _logger: ILoggerProvider,
            ) {

    this._msal.instance.setNavigationClient(new CustomNavigationClient(this.iab, this._logger));

    /**
     * Subscripción a eventos de la librería de Azure durante el proceso de Login
     */
    this._msalB.msalSubject$
      .pipe(
        // tap(msg => console.log('maslSubject msg',msg)),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.setUserLoggedIn(payload.account.username, payload.account.name ?? '', payload.account.idTokenClaims?.['family_name'] as string | undefined);
        this._msal.instance.setActiveAccount(payload.account);
      });

    /**
     * Subscripción para eventos de login con proceso de redirect
     */
    this._msal.handleRedirectObservable()
    .pipe(
      filter((res) => !!res),
      tap((res) => this._logger.log('handleRedirectObservable event', res)),
    )
    .subscribe((result) => {
      if(!this._isLoggedIn.value && result?.account){
        this.setUserLoggedIn(result.account.username, result.account.name ?? '', result.account.idTokenClaims?.['family_name'] as string | undefined);
      }
    });

   /**
    * Consultamos si hay cuenta activa (Flujo para actualizaciones de página F5)
    */
   this.manageCurrentAccount();
  }

    /**
     * Obtiene una posible cuenta activa, y la define como la cuenta
     */
  private manageCurrentAccount() : void {
    const currentAccount = this._msal.instance.getActiveAccount();
    if (currentAccount) {
     this.setUserLoggedIn(currentAccount.username, currentAccount.name ?? '', currentAccount.idTokenClaims?.['family_name'] as string | undefined);
    }
  }

  /**
   * Realiza el login de la cuenta
   * @param account La cuenta del usuario
   * @param name El nombre del usuario
   */
  private setUserLoggedIn(account: string, name: string, apellidos?: string): void {
    const dni = account.split('@')[0];
      const user: IUser = {
        dni: dni,
        email: account,
        name: name,
        apellidos: apellidos,
      };
      this.setLoggedIn(user);
  }
  /**
   * Solicitud de inicio de sesión
   * @param provider El proveedor que se usará para realizar el login
   */
  loginRequest(){
      this._msal.initialize()
      .pipe(
        switchMap(() =>{
          const scopes = [AzureGlobalcampoB2CConfig.GlobalcampoApi.scopes.all];
          const prompt = 'select_account';
          const loginOptions = {scopes, prompt};
          if(!Capacitor.isNativePlatform()){
            return this._msal.loginPopup(loginOptions);
          }
          else {
            return this._msal.loginRedirect(loginOptions);
          }

        } ),
      )
      .subscribe((_res) => {});
  }

  /**
   * Define el usuario logeado y emite el valor
   * @param user
   */
  public setLoggedIn(user: IUser | null): void {
    this._isLoggedIn.next(user);
  }

  /**
   * Lanza la pregunta de confirmación para realizar el logOut
   */
  public logOut(): void {
    this._alert.logOutAlert({
      code: 0,
      header: this._trans.instant('ALERT.LOGOUT.HEADER'),
      message: this._trans.instant('ALERT.LOGOUT.MESSAGE'),
      actionButton: () => this.doLogOut(),
      actionButtonLabel: this._trans.instant('ALERT.LOGOUT.ACCION'),
      subActionButton: () => this._alert.dismiss(),
      subActionButtonLabel: this._trans.instant('ALERT.LOGOUT.SUB_ACCION'),
    });
  }

  /**
   * Realiza el logOut del usaurio
   */
  private doLogOut() {
      this._msal.logout({logoutHint: 'Estás saliendo de Globalcampo'})
      .subscribe((_result) => {
        this.setLoggedIn(null);
      });
  }

}

