import { EEstadoAsesoramientoKeys as EEstadoAsesoramiento } from '../enums/estado-asesoramiento.enum';
import { IEstadoAsesoramiento } from '../interfaces/estado-asesoramiento.interface';

export const estadoAsesoramientoConf: Record<EEstadoAsesoramiento, IEstadoAsesoramiento> = {
    NO_REQUIERE_ASESORAMIENTO: {
        codAuxTipo: 10,
        desAuxTipo: 'NO REQUIERE ASESORAMIENTO',
        indAuxTipo: 'S',
    },
    ASESORAMIENTO_NO_SOLICITADO: {
        codAuxTipo: 20,
        desAuxTipo: 'ASESORAMIENTO NO SOLICITADO',
        indAuxTipo: 'S',
    },
    ASESORAMIENTO_SOLICITADO: {
        codAuxTipo: 30,
        desAuxTipo: 'ASESORAMIENTO SOLICITADO',
        indAuxTipo: 'S',
    },
    ASESORADA_NECESARIO_AJUSTES: {
        codAuxTipo: 40,
        desAuxTipo: 'ASESORADA: NECESARIO AJUSTES',
        indAuxTipo: 'S',
    },
    ASESORADA_OK: {
        codAuxTipo: 60,
        desAuxTipo: 'ASESORADA OK',
        indAuxTipo: 'S',
    },
};

export enum desEstadoAsesoramiento {
    NO_REQUIERE_ASESORAMIENTO = 'NO_REQUIERE_ASESORAMIENTO',
    ASESORAMIENTO_NO_SOLICITADO = 'ASESORAMIENTO_NO_SOLICITADO',
    ASESORAMIENTO_SOLICITADO = 'ASESORAMIENTO_SOLICITADO',
    ASESORAMIENTO_REQUERIDO = 'ASESORAMIENTO_REQUERIDO',
    ASESORADA_NECESARIO_AJUSTES = 'ASESORADA_NECESARIO_AJUSTES',
    ASESORADA_OK = 'ASESORADA_OK',
  }
