import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { IonicModule, MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom, tap } from 'rxjs';
import { menuCCA, menuGCType } from 'src/app/globalcampo/config/menus-app.config';
import { StorageKeys } from 'src/app/globalcampo/config/storage-keys.config';
import { urls } from 'src/app/globalcampo/config/urls';
import { PermisosDirective } from 'src/app/globalcampo/directives/permisos.directive';
import { EUsuarioNivelAcceso } from 'src/app/globalcampo/enums/usuario-nivel-acceso.enum';
import { IExplotacion } from 'src/app/globalcampo/interfaces/explotaciones/explotacion.inteface';
import { IInstalacion } from 'src/app/globalcampo/interfaces/instalaciones/instalacion.interface';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { PERMISOS, PERMISOS_ACCIONES } from 'src/app/globalcampo/permissions/permissions.const';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';
import { ActividadesService } from 'src/app/globalcampo/services/cuadernos-de-campo/actividades.service';
import { GlobalcampoService } from 'src/app/globalcampo/services/cuadernos-de-campo/globalcampo.service';
import { IProfileService } from 'src/app/globalcampo/services/profile/profile-service.interface';
import { IProfile } from 'src/app/globalcampo/services/profile/profile.interface';
import { PROFILE_TOKEN } from 'src/app/globalcampo/services/profile/profile.token';

@Component({
  selector: 'gc-menu-cca',
  templateUrl: './menu-cca.component.html',
  styleUrls: ['./menu-cca.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule, FormsModule, PermisosDirective],
})
export class MenuCcaComponent implements OnInit {

  /**
   * Emite un valor booleano que indica si el menú está en estado compacto.
   * @event menuCCACompact
   * @type {boolean}
   */
  @Output() menuCCACompact: EventEmitter<boolean> = new EventEmitter();

  /**
   * Representa el texto para seleccionar una instalación en el menú CCA.
   */
  private readonly _labelSelectInstalacion: string = 'CCA.MENU.SEL_INSTALACION';


  _adminInstalacionesPermiss: string[] = [PERMISOS.ADMIN.INSTALACIONES_EXTERNAS.ADMIN + PERMISOS_ACCIONES.ALL];
  /**
   * La etiqueta para la instalación.
   */
  private _instalacionLabel: string = this._labelSelectInstalacion;
  public get instalacionLabel(): string {
    return this._instalacionLabel;
  }
  /**
   * Representa la instalación.
   */
  private _instalacion: string = '';
  public get instalacion(): string | null {
    return this._instalacion;
  }
  /**
   * Indica si se muestra u oculta el botón de cambio de instalación.
   */
  private _showCambioInstalacion: boolean = false;
  public get showCambioInstalacion(): boolean {
    return this._showCambioInstalacion;
  }

  /**
   * La etiqueta para la explotación.
   */
  private _explotacionLabel: string = '';
  public get explotacionLabel(): string {
    return this._explotacionLabel;
  }
  /**
   * Representa la explotación.
   */
  private _explotacion: string = '';
  public get explotacion(): string | null {
    return this._explotacion;
  }
  /**
   * Indica si se muestra u oculta el botón de cambio de explotación.
   */
  private _showCambioExplotacion: boolean = false;
  public get showCambioExplotacion(): boolean {
    return this._showCambioExplotacion;
  }

  /**
   * Indica si se muestra u oculta el menú de "Explotación".
   */
  private _showMenuExplotacion: boolean = false;
  public get showMenuExplotacion(): boolean {
    return this._showMenuExplotacion;
  }

  /**
   * Representa los nav links del menu
   */
  private readonly _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
   * Representa el switch link de instalación.
   */
  private _switchInstalacionLink!: IMenu;
  public get switchInstalacionLink(): IMenu {
    return this._switchInstalacionLink;
  }

  /**
   * Representa el switch link de explotación.
   */
  private _switchIExplotacionLink!: IMenu;
  public get switchIExplotacionLink(): IMenu {
    return this._switchIExplotacionLink;
  }

  /**
   * Representa el link de salida del footer.
   */
  private _footerExitLink!: IMenu;
  public get footerExitLink(): IMenu {
    return this._footerExitLink;
  }

  private readonly _changeInstalacion: string = StorageKeys.CHANGE_ENTIDAD.name;
  /**
   * Indica si el menú debe mostrarse compacto.
   */
  private  _compactMenu: boolean = false;
  public get compactMenu(): boolean {
    return this._compactMenu;
  }

  /**
   * Indica si la aplicación se está ejecutando en un entorno de escritorio.
   */
  private  _isDesktop: boolean = false;
  public get isDesktop(): boolean {
    return this._isDesktop;
  }

  /**
   * Indica si el tamaño de la pantalla actual es MD o superior.
   */
  private  _isXLUp: boolean = false;
  public get isXLUp(): boolean {
    return this._isXLUp;
  }

  /**
   * Indica la etiqueta para el botón toggle.
   */
  private _toogleLabel: string = 'LIGHT';
  public get toogleLabel(): string {
    return this._toogleLabel;
  }


  /**
   * Representa el nombre del icono que se utilizará en el componente del menú.
   */
  private _iconName: string = 'sun';
  public get iconName(): string {
    return this._iconName;
  }

  /**
   * El color del icono, por defecto es 'amarillo'.
   */
  private _iconColor: string = 'yellow';

  private readonly _iconElement: string = 'gc-footer-btn-icon';

    /**
   * Indica el estado actual del interruptor de modo.
   */
  public modeChanged: boolean = false;

  constructor (  private readonly _router: Router,
                          private readonly _menuController: MenuController,
                          private readonly _navCtrl: NavController,
                          private readonly _actividadesService: ActividadesService,
                          private readonly _appStatusService: AppStatusService,
                          private readonly _elementRef: ElementRef,
                          private readonly _platform: Platform,
                          private readonly _globalcampoService: GlobalcampoService,
                          @Inject(PROFILE_TOKEN) private readonly _profileService: IProfileService,
                         ) { }


  ngOnInit() {

    this.manageLinks();

    this._appStatusService.instalacionChanged$
    .pipe(
      tap(async () => {
        this.toggleCambioInstalacionVisibility();
      }),
      tap((instalacion) => {
        this.generateInstValues(instalacion);
      }),
    )
    .subscribe();

    this._appStatusService.explotacionChanged$
    .pipe(
      tap( (explotacion) => {
        this.generateExpValues(explotacion);
        this.toggleCambioExplotacionVisibility(explotacion);
      }),
    )
    .subscribe();

    this._profileService.getProfile()
    .subscribe((profile) => {
      this.updateModeDataBasedOnProfile(profile);
    });

    this.checkIsDesktop();
    this.checkIsXLUp();
    window.addEventListener('resize', () => {
      this.checkIsXLUp();
    });
    this.loadMenuState();
  }

  /**
   * Alterna la visibilidad de la opción "Cambio Instalacion"
   */
  private async toggleCambioInstalacionVisibility() {
    const storedValue = (await Preferences.get({ key: this._changeInstalacion })).value;
    const isChangeEntidad: boolean = storedValue ? JSON.parse(storedValue).isChangeInstalacion : false;

    if (isChangeEntidad || this._appStatusService.cantidadInstalaciones > 1) {
      this._showCambioInstalacion = !this._showCambioInstalacion;
    }
  }

  /**
   * Alterna la visibilidad de la opción "Cambio Explotación"
   */
  private async toggleCambioExplotacionVisibility(explotacion: IExplotacion | null) {
    const storedValue = (await Preferences.get({ key: StorageKeys.CHANGE_EXPLOTACION.name })).value;
    const isChangeExplotacion: boolean = storedValue ? JSON.parse(storedValue).isChangeExplotacion : false;

    this._showCambioExplotacion = !!explotacion && (isChangeExplotacion || this._appStatusService.cantidadExplotaciones > 1);
  }

  /**
   * Actualiza los datos del modo basado en el perfil proporcionado
   * @param profile - El objeto de perfil que contiene la información del modo. Si es nulo, no se realizan cambios
   */
  public updateModeDataBasedOnProfile(profile: IProfile | null) {
    if (profile) {
      const isDarkMode = profile?.mode === 'dark';
      this.modeChanged = isDarkMode;
      this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
      this._iconName = isDarkMode ? 'moon' : 'sun';
      this._iconColor = isDarkMode ? 'blue' : 'yellow';
      this._elementRef.nativeElement.style.setProperty('--gc-color-footer-icon', `var(--gc-color-${this._iconColor}-tone)`);
      this._elementRef.nativeElement.style.setProperty('--gc-color-footer-icon-hover', `var(--gc-color-${this._iconColor}-opacity-10)`);
    }
  }

  /**
   * Verifica si el ancho de la plataforma es mayor a 1025 píxeles
   */
  public checkIsXLUp() {
    const xlUp: number = 1025;
    this._isXLUp = this._platform.width() > xlUp;
  }

  /**
   * Verifica si la plataforma actual es un escritorio
   */
  public checkIsDesktop() {
    this._isDesktop = this._platform.is('desktop');
  }

  /**
   * Genera los valores para los switch basado en la instalacion proporcionada.
   *
   * @param instalacion - El objeto explotacion para generar los valores.
   */
  public generateInstValues(instalacion: IInstalacion | null) {
    if (instalacion) {
      this._instalacion = instalacion.nombreInstalacion;
      this._instalacionLabel = 'CCA.MENU.INSTALACION';
    } else {
      this._instalacion = '';
      this._instalacionLabel = this._labelSelectInstalacion;
    }
  }

  /**
   * Genera los valores para el componente menu-cca basado en la explotacion proporcionada.
   *
   * @param explotacion - El objeto explotacion para generar los valores.
   */
  public generateExpValues(explotacion: IExplotacion | null) {
    if (explotacion) {
      this._showMenuExplotacion = true;
      this._showCambioExplotacion = !this._showCambioExplotacion;
      this._explotacion = explotacion.codIdentific;
      this._explotacionLabel = 'CCA.MENU.EXPLOTACION';
    } else {
      this._showMenuExplotacion = false;
      this._showCambioExplotacion = false;
      this._explotacion = '';
      this._explotacionLabel = 'CCA.MENU.SEL_EXPLOTACION';
    }
  }

    /**
     * Recupera un array de elementos de menú de navegación.
     *
     * @returns Un array de objetos `IMenu` que representan los elementos de menú.
     */
    public manageNavLinks(): IMenu[] {
      const navLinks: IMenu[] = [];
      const menu: menuGCType = menuCCA;
      navLinks.push(menu['dashboard']);
      navLinks.push(menu['actividades']);
      navLinks.push(menu['titular']);
      navLinks.push(menu['explotacion']);
      navLinks.push(menu['trabajadores']);
      navLinks.push(menu['maquinaria']);
      navLinks.push(menu['parcelario']);
      navLinks.push(menu['carnetFitosanitario']);

      /**
       * De momento solo lo dejamos para superadmins
       */
      if(this._globalcampoService.userCCA?.nivelAcceso === EUsuarioNivelAcceso.superadmin ) {
        navLinks.push(menu['invitaciones']);
      }
      return navLinks;
    }

    /**
     * Gestiona los enlaces para el componente de menú.
     */
    public manageLinks() {
      const menu: menuGCType = menuCCA;
      this._switchInstalacionLink = menu['instalaciones'];
      this._switchIExplotacionLink = menu['explotaciones'];
      this._footerExitLink = menu['inicio'];
    }

    /**
     * Abre el menú de actividades y cierra el controlador de menú.
     */
    public openMenuAct(event: Event) {
      event.preventDefault();
      this._actividadesService.openActividadesMenu();
      this._menuController.close();
    }

    goToAdministracion(): void {
      this._navCtrl.navigateRoot(urls.administracion.usuarios);
    }

    /**
     * Navega a la URL especificada.
     *
     * @param menu - El elemento del menú que contiene el enlace de navegaciónr.
     * @param rootNavigation - Un booleano que indica si se debe realizar una navegación raíz.
     * @param event - (Opcional) El evento que desencadenó la navegación, utilizado para prevenir la acción predeterminada.
     * @returns Una Promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu?: IMenu, rootNavigation: boolean = false, event?: Event) {
      event?.preventDefault();
      const link: string = menu?.link ?? null ?? '';

      this._menuController.close();

      if (rootNavigation) {
        await this._navCtrl.navigateRoot([link]);
      } else {
        await this._router.navigate([link]);
      }

    }

    /**
     * Alterna el botón del menú en el componente de la aplicación.
     */
    public toggleBtnMenuCCA() {
      this._compactMenu = !this._compactMenu;
      this.menuCCACompact.emit(this._compactMenu);
      const menuCCABtnIcon = document.getElementById(this._iconElement) as HTMLElement;
      if(menuCCABtnIcon){
        menuCCABtnIcon.style.transform = this._compactMenu ? 'rotate(180deg)' : 'rotate(0deg)';
        this.saveMenuState(menuCCABtnIcon.style.transform);
      }
    }

     /**
     * Cambia el modo del perfil entre 'oscuro' y 'claro'.
     *
     * @param ev - El objeto de evento.
     */
    async modeChange(ev: CustomEvent | MouseEvent | KeyboardEvent) {
      const isClickEvent: boolean = ev.type === 'click';
      const isDarkMode: boolean = isClickEvent ? !this.modeChanged : ev.detail.checked;
      const updatedProfile: Partial<IProfile> = { mode: isDarkMode ? 'dark' : 'light' };

      this._iconColor = isDarkMode ? 'blue' : 'yellow';
      this._elementRef.nativeElement.style.setProperty('--gc-color-footer-icon', `var(--gc-color-${this._iconColor}-tone)`);
      this._elementRef.nativeElement.style.setProperty('--gc-color-footer-icon-hover', `var(--gc-color-${this._iconColor}-opacity-10)`);

      this.modeChanged = isDarkMode;
      this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
      this._iconName = isDarkMode ? 'moon' : 'sun';

      await lastValueFrom(this._profileService.patchProfile(updatedProfile, 'modeChanged'));
    }

  /**
   * Guarda el estado del menú en las preferencias.
   *
   * @param {string} position - La posición del icono del menú.
   * @returns {Promise<void>}
   */
  async saveMenuState(position: string): Promise<void> {
    await Preferences.set({ key: 'compactMenu', value: JSON.stringify(this._compactMenu) });
    await Preferences.set({ key: 'iconPosition', value: position });
  }

  /**
   * Carga el estado del menú desde las preferencias almacenadas.
   *
   * Recupera el estado compacto del menú y la posición del icono
   * desde las preferencias y actualiza el componente.
   *
   * @returns {Promise<void>}
   */
  async loadMenuState(): Promise<void> {
    const storedCompactMenu = await Preferences.get({ key: 'compactMenu' });
    if (storedCompactMenu.value !== null) {
      this._compactMenu = JSON.parse(storedCompactMenu.value);
      this.menuCCACompact.emit(this._compactMenu);
    }

    const storedIconPosition = await Preferences.get({ key: 'iconPosition' });
    if (storedIconPosition.value !== null) {
      const menuCCABtnIcon = document.getElementById(this._iconElement) as HTMLElement;
      menuCCABtnIcon.style.transform = storedIconPosition.value;
    }
  }

}
