<div class="main-menu">
  <div class="menu-header" routerLink="/">
    <figure class="header-img">
      <img src="{{logo}}" alt=""/>
    </figure>
    <span class="header-title h1">{{ 'GLOBALCAMPO' | translate }}</span>
  </div>
  <div class="menu-content">
      <div class="content-nav">
        @for(navLink of navLinks; track navLink) {
          <a class="nav-link" routerLink="{{navLink.link}}" routerLinkActive="link-selected" (click)="navigationUrl(navLink, $event)" (keydown.enter)="navigationUrl(navLink, $event)" >
            <span class="link-icon">
              <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
              <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
            </span>
            <span class="link-text">{{ navLink.title | translate }}</span>
          </a>
        }
      </div>
  </div>
</div>