import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable, catchError, filter, from, map, of, switchMap, take, tap } from 'rxjs';
import { StorageKeys } from '../../config/storage-keys.config';
import { LoginService } from '../login.service';
import { IProfileService } from './profile-service.interface';
import { IProfile, profileDefaults } from './profile.interface';
import { ProfileServiceWithPerfilPrefers } from './profile.service-with-perfil';

export const PROFILE_KEY = StorageKeys.PROFILE.name;
@Injectable()
export class ProfileServiceWithCapacitorPrefers implements IProfileService {

  private _profileReady: boolean = false;
  private _profileChanged: BehaviorSubject<IProfile|null> = new BehaviorSubject<IProfile|null>(null);


  constructor(
    private readonly storageRemote: ProfileServiceWithPerfilPrefers,
    private readonly _loginService: LoginService,
  ) {

    // Suscripción para actualizar el perfil cuando el usuario inicia sesión
    this._loginService.isLoggedIn$.pipe(
// Filtra para solo procesar cuando el usuario se loguea
      filter((loggedIn) => !!loggedIn),
// Lee el perfil desde el almacenamiento remoto
      switchMap(() => this.readProfileFromRemote()),
      tap((profile) => {
        if (profile) {
          this.saveProfileToLocal(profile).subscribe();
          this._profileChanged.next(profile);
        }
      }),
      catchError((_err) => of(null)),
    ).subscribe();


  }



  /**
   * Lee el perfil del usuario desde el almacenamiento local
   * Si no existe, devuelve null.
   * @returns Observable<IProfile | null>
   */
  private readProfileFromLocal(): Observable<IProfile | null> {
    return from(Preferences.get({ key: PROFILE_KEY })).pipe(
      switchMap(res => {
        let profile: IProfile | null = null;
        try {
          if (!!res.value) {
            profile = JSON.parse(res.value) as IProfile;
          }

          if (!!profile) {
            return of({ ...this.getProfileDefaults(), ...profile });
          }
          return of(null);
        } catch (error) {
          return of(null);
        }
      }),
    );
  }

  /**
   * Lee el perfil del usuario desde el almacenamiento remoto utilizando storageRemote.
   * Si obtiene un perfil, lo guarda en el almacenamiento local y actualiza el BehaviorSubject.
   * @returns Observable<IProfile | null>
   */
  private readProfileFromRemote(): Observable<IProfile | null> {
    return this.storageRemote.getProfile().pipe(
      switchMap((profile) => {
        if (profile) {
          return this.saveProfileToLocal(profile).pipe(
            tap(() => {
              this._profileChanged.next(profile);
            }),
          );
        } else {
          return of(null);
        }
      }),
      catchError((_err) => of(null)),
    );
  }

  /**
   * Devuelve los valores por defecto del perfil.
   * @returns IProfile
   */
  private getProfileDefaults(): IProfile {
    return profileDefaults;
  }

   /**
   * Obtiene el perfil del usuario, primero desde el almacenamiento local.
   * Si el perfil no existe en local y el usuario está logueado, intenta obtener el perfil desde el almacenamiento remoto.
   * Si no tiene en remoto, carga el perfil por defecto
   * @returns Observable<IProfile | null>
   */
    getProfile(): Observable<IProfile | null> {
      // Primero intentamos obtener el perfil del almacenamiento local.
      return this.readProfileFromLocal().pipe(
        switchMap((localProfile) => {
          if (localProfile) {
            // Si existe un perfil en el almacenamiento local, lo utilizamos directamente.
            this._profileChanged.next(localProfile);
            return this._profileChanged.asObservable();
          } else {
            // Si no existe un perfil en el almacenamiento local, verificamos si el usuario está logueado.
            return this._loginService.isLoggedIn$.pipe(
              take(1),
              switchMap((loggedIn) => {
                console.log('getProfile isLoggedIn', loggedIn);
                if (loggedIn) {
                  // Si el usuario está logueado, obtenemos el perfil del almacenamiento remoto.
                  return this.readProfileFromRemote().pipe(
                    switchMap((remoteProfile) => {
                      if (remoteProfile) {
                        // Si obtenemos un perfil del almacenamiento remoto lo actualizamos en el BehaviorSubject.
                        this._profileChanged.next(remoteProfile);
                        return this._profileChanged.asObservable();
                      } else {
                        // Si no hay perfil en el almacenamiento remoto, cargamos y guardamos un perfil por defecto.
                        const defaultProfile: IProfile = { ...this.getProfileDefaults() };
                        this.saveProfileToLocal(defaultProfile).subscribe();
                        this._profileChanged.next(defaultProfile);
                        return of(defaultProfile);
                      }
                    })
                  );
                } else {
                  // Si el usuario no está logueado, cargamos y guardamos un perfil por defecto.
                  console.log('getProfile savingToLocal');
                  const defaultProfile: IProfile = { ...this.getProfileDefaults() };
                  this.saveProfileToLocal(defaultProfile).subscribe();
                  this._profileChanged.next(defaultProfile);
                  return of(defaultProfile);
                }
              }),
            );
          }
        }),
        catchError((_err) => of(null)),
      );
    }

  /**
   * Guarda el perfil del usuario en el almacenamiento local.
   * Si el usuario está logueado, también guarda el perfil en el almacenamiento remoto.
   * @param profile El perfil del usuario a guardar.
   * @returns Observable<IProfile | null>
   */
  saveProfile(profile: IProfile): Observable<IProfile | null> {
    return this.saveProfileToLocal(profile).pipe(
      switchMap(() => this._loginService.isLoggedIn$.pipe(
        take(1),
        switchMap((loggedIn) => {
          if (loggedIn) {
            return this.saveProfileToRemote(profile);
          }
          return of(profile);
        }),
      )),
      tap((savedProfile) => {
        if (savedProfile) {
          this._profileChanged.next(savedProfile);
        }
      }),
      catchError((_err) => of(null)),
    );
  }
  /**
   * Guarda el perfil del usuario en el almacenamiento local
   * @param profile El perfil del usuario a guardar.
   * @returns Observable<IProfile | null>
   */
  private saveProfileToLocal(profile: IProfile): Observable<IProfile | null> {
    return from(Preferences.set({ key: PROFILE_KEY, value: JSON.stringify(profile) }))
      .pipe(
        map(() => profile),
        catchError((_err) => of(null)),
      );
  }

  /**
   * Guarda el perfil del usuario en el almacenamiento remoto utilizando storageRemote.
   * @param profile El perfil del usuario a guardar.
   * @returns Observable<IProfile | null>
   */
  private saveProfileToRemote(profile: IProfile): Observable<IProfile | null> {
    return this.storageRemote.saveProfile(profile).pipe(
      catchError((_err) => of(null)),
    );
  }

 /**
   * Actualiza parcialmente el perfil del usuario.
   * Primero intenta obtener el perfil actual y luego lo actualiza con los nuevos datos.
   * @param profile Los datos del perfil a actualizar.
   * @param from Indica de dónde proviene la actualización.
   * @returns Observable<IProfile | null>
   */
  patchProfile(profile: IProfile, _from: string): Observable<IProfile | null> {
    let obs;

    if (!!this._profileChanged.value) {
      obs = this.patchAndSaveProfile(this._profileChanged.value, profile);
    } else {
      obs = this.readProfileFromLocal().pipe(
        take(1),
        switchMap(existingProfile => {
          if (!!existingProfile) {
            return this.patchAndSaveProfile(existingProfile, profile);
          }

          let newProfile: IProfile = { ...this.getProfileDefaults() };
          return this.patchAndSaveProfile(newProfile, profile);
        }),
      );
    }

    return obs.pipe(switchMap(profile => of(profile)));
  }

   /**
   * Combina el perfil actual con los nuevos datos y guarda el perfil resultante.
   * @param current El perfil actual.
   * @param newData Los nuevos datos a combinar con el perfil actual.
   * @returns Observable<IProfile | null>
   */
  private patchAndSaveProfile(current: IProfile, newData: any): Observable<IProfile | null> {
    const patchedProfile = { ...current, ...newData };
    return this.saveProfile(patchedProfile as IProfile);
  }

  /**
   * Elimina el perfil del usuario del almacenamiento local
   * @returns Observable<void>
   */
  private preferencesRemove(): Observable<void> {
    return from(Preferences.remove({ key: PROFILE_KEY }));
  }

   /**
   * Elimina el perfil del usuario del almacenamiento local y actualiza el BehaviorSubject.
   * @returns Observable<boolean>
   */
  deleteProfile(): Observable<boolean> {
    return this.preferencesRemove().pipe(
      tap(() => {
        this._profileChanged.next(null);
      }),
      map(() => true),
      catchError((_err) => of(false)),
    );
  }
}
