<div class="gc-content-btn">
    @for(dropdownLink of dropdownLinks; track dropdownLink) {
      <span class="gc-btn-link" routerLink="{{dropdownLink.link}}">
        <ion-icon class="link-icon icon-outline" name="{{ dropdownLink.icon }}"></ion-icon>
        <ion-icon class="link-icon icon-solid" name="{{ dropdownLink.iconHover }}"></ion-icon>
        <div class="link-label">
          <span class="label-title"> {{ dropdownLink.title | translate }} </span>
          <span class="label-desc"> {{ dropdownLink.subTitle ?? '' | translate }} </span>
        </div>
      </span>
    }
  </div>
  @if(showDropdownFooter) {
  <div class="gc-content-footer">
    <div class="footer-title"> {{ 'MENU.MAIN.DROPDOWN.FOOTER.TITLE' | translate }} </div>
    <ion-button class="btn-s-accent-clear "> {{ 'MENU.MAIN.DROPDOWN.FOOTER.BUTTON' | translate }} </ion-button>
  </div>
  }