import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, Observable, tap } from 'rxjs';
import { instalacionesConfig } from '../../config/instalaciones-config';
import { explotacionEndPoints } from '../../enpoints/explotacion.endpoint.v1';
import { EMessageTypes } from '../../enums/message-type.enum';
import { IAltaExplotacionData, IPgaExplotacion, TDatosAdicionalesExplotacion } from '../../interfaces/explotaciones/alta-explotacion-data';
import { IExplotacionFeedback } from '../../interfaces/instalaciones/instalacion.interface';
import { IDatosToken } from '../../interfaces/security/datos-token.interface';
import { IUser } from '../../interfaces/user.interface';
import { AlertService } from '../alerts/alert.service';
import { AppStatusService } from '../app-status.service';
import { ExplotacionesService } from '../cuadernos-de-campo/explotaciones.service';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { IProfileService } from '../profile/profile-service.interface';
import { PROFILE_TOKEN } from '../profile/profile.token';
import { IStorageProvider } from '../storage/storage-provider.interface';
import { STORAGE_PROVIDER } from '../storage/storage-provider.token';
import { StorageKeys } from '../../config/storage-keys.config';

@Injectable({
  providedIn: 'root',
})
export class AltaExplotacionService {
  private readonly destroyRef = inject(DestroyRef);
  explotacionData!: IAltaExplotacionData;
  private readonly _altaExplotacionKey: string = StorageKeys.ALTA_EXPLOTACION.name;
  public readonly maxSteps = 5;
  private readonly step = signal(0);
  private readonly _currentTitle: WritableSignal<string> = signal('');
  public get currentTitle(): WritableSignal<string> {
    return this._currentTitle;
  }
  public set currentTitle(value: string) {
    this._currentTitle.set(value);
  }
  /** Indica si se esta obteniendo el perfil */
  private gettingProfile: boolean = false;

  existExplotacion: boolean = false;

  private readonly _feedBack: WritableSignal<IExplotacionFeedback[]> = signal([]);
  public get feedBack(): WritableSignal<IExplotacionFeedback[]> {
    return this._feedBack;
  }
  public get feedBackObserve(): Observable<IExplotacionFeedback[]> {
    return toObservable(this.feedBack);
  }

  private _user: IUser | null = null;
  public get user(): IUser | null {
    return this._user;
  }

  private readonly _userModel = {
    codInstalacion: Number(instalacionesConfig.globalcaja),
    codRol: 30,
  };
  public get userModel() {
    return this._userModel;
  }

  /**
   * Indica si se debe mostrar la alerta de recuperación del proceso de alta de explotación.
   */
  showAlerta: boolean = false;

  procesoFinalizado: boolean = false;

  constructor(private readonly http: HttpClient, @Inject(PROFILE_TOKEN) private readonly _profileService: IProfileService,
  @Inject(STORAGE_PROVIDER) private readonly _storage: IStorageProvider,
    private readonly _app: AppStatusService, private readonly _explotaciones: ExplotacionesService, private readonly _alertService: AlertService,
    private readonly _translateService: TranslateService, private readonly _globalcampoService: GlobalcampoService,
  ) {
    this.init();
  }

  /**
   * Inicializa los datos de la explotación
   */
  public init() {
    this.initExplotacionData();

    this._globalcampoService.getMe().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((me) => {
      this._user = me;
    });

    this._storage.get<IAltaExplotacionData>(this._altaExplotacionKey).pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
    ).subscribe((altaExplotacionData) => {
      if (this.debeMostrarAlertaRecuperacion(altaExplotacionData)) {
        this.mostrarAlertaRecuperacion(altaExplotacionData);
      }
    
      if (!this.gettingProfile) {
        this.gettingProfile = true;
        if (altaExplotacionData) {
          this.addExplotacionData(altaExplotacionData);
        }
      }
    });

    this._globalcampoService.feedBackObserve.subscribe((feedBack) => {
      this.feedBack.set(feedBack);
      if (feedBack.length > 0) {
        this.existExplotacion = true;
      }
    });
  }



  /**
   * Comprueba si se debe mostrar la alerta de recuperación del proceso de alta de explotacion.
   * @param profile
   */
  private debeMostrarAlertaRecuperacion(profile: IAltaExplotacionData | null): boolean {
    const hasProfileAlta = profile && profile.step > 0;
    const validConditions = hasProfileAlta && this.showAlerta && !this.gettingProfile;

    return !!(validConditions && this.explotacionData.step === 0);
  }

  /**
   * Muestra la alerta de recuperación del proceso de alta de explotación.
   * @param profile
   */
  private mostrarAlertaRecuperacion(profile: IAltaExplotacionData | null): void {
    this.showAlerta = false;
    this._alertService.openAlert(EMessageTypes.warn, {
      code: 0,
      header: this._translateService.instant('ALTA_EXPLOTACION.ALERT.RECUPERAR_INFORMACION.HEADER'),
      message: this._translateService.instant('ALTA_EXPLOTACION.ALERT.RECUPERAR_INFORMACION.MESSAGE'),
      actionButton: () => this.getAltaExplotacionFromProfile(profile),
      actionButtonLabel: this._translateService.instant('ALTA_EXPLOTACION.ALERT.RECUPERAR_INFORMACION.ACCION'),
      subActionButtonLabel: this._translateService.instant('ALTA_EXPLOTACION.ALERT.RECUPERAR_INFORMACION.SUB_ACCION'),
      subActionButton: () => this.resetDatosAltaExplotacion(),
      backdropDismiss: false,
    });


  }

  /**
   * Obtiene los datos de la explotación del perfil.
   * @param profile
   */
  private getAltaExplotacionFromProfile(profile: IAltaExplotacionData | null): void {
    if (profile && !this.gettingProfile) {
      this.gettingProfile = true;
      this.addExplotacionData(profile);
    }
  }

  /**
   *
   * @returns Devuelve el paso actual.
   */
  currentStep() {
    return this.step;
  }

  /**
   * Pasa al siguiente paso.
   */
  async nextStep(): Promise<void> {
    if (this.explotacionData.step < this.explotacionData.maxSteps) {
      this.explotacionData.step = this.explotacionData.step + 1;
      // Actualizamos el paso en el perfil.
      const updatedProfileData: IAltaExplotacionData = this.explotacionData;
      this._storage.set(this._altaExplotacionKey, updatedProfileData).subscribe();
    }

    this.step.set(this.explotacionData.step);
  }

  /**
   * Retrocede al paso anterior.
   */
  backsStep(): void {
    if (this.explotacionData.step > 0) {
      this.explotacionData.step--;
    }
    if (this.explotacionData.step === 0) {
      //mostrar la alerta de recuperación del proceso de alta de explotación en el primer paso
      this.showAlerta = true;
    }

    this.step.set(this.explotacionData.step);
  }

  /**
   * Informa los datos adicionales de la explotación.
   * @param datosAdicionales Datos adicionales de la explotación.
   */
  setDatosAdicionalesExplotacion(datosAdicionales: TDatosAdicionalesExplotacion) {
    const { telNotification, emailNotification, fiscalName, fiscalAddress } = datosAdicionales;
    this.explotacionData.telNotification = telNotification;
    this.explotacionData.emailNotification = emailNotification;
    this.explotacionData.fiscalName = fiscalName;
    this.explotacionData.fiscalAddress = fiscalAddress;
  }

  addExplotacionData(data: IAltaExplotacionData) {
    this.explotacionData = data;
    this.step.set(data.step);
    if (!this._app.ccaToken) {
      this._explotaciones.postTokenInstalacionExplotacion(data.codInstalacion, '').pipe(
        tap((token: IDatosToken | null) => this._app.ccaToken = token?.token.accessToken ?? null),
        tap(() => this.gettingProfile = false),
      ).subscribe();
    }

  }

  /**
   * Envía los datos de una explotación al servidor.
   *
   * @param explotacion - Los datos de la explotación a enviar.
   * @returns Un observable que emite la explotación creada.
   */
  postExplotacion(explotacion: IPgaExplotacion): Observable<IPgaExplotacion> {
    return this.http.post<IPgaExplotacion>(explotacionEndPoints.postExplotacion, explotacion);
  }

  /**
   * Reinicia los datos del alta de la explotación.
   */
  resetDatosAltaExplotacion() {
    this.initExplotacionData();    
    const updatedProfileData: IAltaExplotacionData = this.explotacionData;
    this.step.set(updatedProfileData.step);
    this._storage.set(this._altaExplotacionKey, updatedProfileData).subscribe();
  }

  /**
   * Inicializa los datos del alta de la explotación.
   */
  private initExplotacionData() {
    // Inicializamos el objeto de datos de la explotación.
    this.explotacionData = {
      step: 0,
      codInstalacion: instalacionesConfig.globalcaja,
      maxSteps: this.maxSteps,
      tokenComprobarSga: '',
      checkSga: null,
      codIdentific: '',
      codRea: '',
      codSiex: '',
      codComunidadAutonoma: '',
      desComunidadAutonoma: '',
      telNotification: '',
      emailNotification: '',
      fiscalName: '',
      fiscalAddress: '',
      indRepresentante: false,
    };
  }
}
