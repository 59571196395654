import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const cueEndPoints = {

    /**
     * Endpoint to send an activity to the CUE registry
     */
    postEnviarCUE: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cue/${apiVx}/cuaderno/enviarcue`,

    postComprobarCUE: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cue/${apiVx}/cuaderno/comprobarcue`,


};

export const explotacionEndPoints = {
    postExplotacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/explotacioninstalacion`,
    postExplotacionTecnico: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/tecnico/explotacioninstalacion`,
    getExplotacionTecnico: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/explotacioninstalacion/${id}`,
    comprobarAutorizacion: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/tecnico/comprobarautorizacion/${id}`,
};
