import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MainMenuMobileComponent } from '../main-menu-mobile/main-menu-mobile.component';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { UserProfileComponent } from '../../action/gc-user-profile/user-profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs';
import { urls } from 'src/app/globalcampo/config/urls';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';

@Component({
  selector: 'gc-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterModule, MainMenuMobileComponent, MainMenuComponent, UserProfileComponent],
})
export class HeaderMainComponent implements OnInit {

  /**
   * Recibe el contenido del panel dividido
   */
  splitPaneContent = input<string>();

  /**
   * Indica si el usuario está autorizado.
   */
  private _authorized: boolean = true;
  public get authorized(): boolean {
    return this._authorized;
  }

  /**
   * Indica la ruta a la imagen del logo.
   */
  public logo: string = ASSETS_PATHS.logoGlobalcampo;

  constructor( private readonly _router : Router) { }

  ngOnInit(): void {

    /**
     * Comprueba si la ruta contiene /not-authorized para comprobar si el user está
     */
    this._router.events
    .pipe(
      filter((data) => data instanceof NavigationEnd),
      map((data) => (data as NavigationEnd).url ),
    )
    .subscribe((url) => {
      if (url.includes(urls.notAuthorized)) {
        this._authorized = false;
      } else {
        this._authorized = true;
      }
    });

  }

}
