import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationController, IonicModule, IonModal, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { catchError, interval, merge, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { fechaNotificacionFormat } from 'src/app/globalcampo/config/app-config';
import { urls } from 'src/app/globalcampo/config/urls';
import { INotificacion } from 'src/app/globalcampo/interfaces/notificacion.interface';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { NotificacionesService } from 'src/app/globalcampo/services/notificaciones.service';
import { UserNotificationsPopoverComponent } from '../user-notifications-popover-component/user-notifications-popover.component';

@Component({
  selector: 'gc-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private showNotifications = false;

  /**
   * Referencia al componente IonModal dentro del html.
   * Esto permite la interacción con la instancia del modal, como abrirlo o cerrarlo.
   * El modal puede ser indefinido si la vista aún no se ha inicializado.
   */
  @ViewChild('modalNotifications') modal: IonModal | undefined;


  /**
   * Un array de objetos de notificación.
   * Este array contiene la lista de notificaciones para el usuario.
   */
  notifications: INotificacion[] = [];

  private readonly intervalTime = 120000;

  constructor(private readonly _animationCtrl: AnimationController, private readonly _popoverController: PopoverController,
    private readonly _notificationsService: NotificacionesService, private readonly route: Router, private readonly _loginService: LoginService) { }

  /**
   * @inheritdoc
   * Inicializa el componente y configura una suscripción a un intervalo de 2 minutos.
   * que realiza una solicitud para obtener las notificaciones
   */
  ngOnInit() {
    const initialGetNotifications = of(true);
    merge(initialGetNotifications, interval(this.intervalTime), this._notificationsService.notificationsChanged)
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => of()),
        switchMap(() => this._loginService.isLoggedIn$.pipe(
          catchError(() => of()),
          tap((isLoggedIn) => {
            this.showNotifications = !!isLoggedIn;
          }),
        )),
      )
      .subscribe({
        next: () => {
          if (this.showNotifications) {
            this.fetchNotifications();
          }
        },
      });
  }

  /**
   * Obtiene las notificaciones del servicio y filtra aquellas que no han sido leídas.
   * Luego, agrega las nuevas notificaciones filtradas.
   *
   * @private
   */
  private fetchNotifications() {
    this._notificationsService.getNotificaciones({
      fchInicio: null,
      fchFin: null,
      fchLectura: null,
      auxTipoNotificacion: null,
      cadenaBusqueda: null,
      orden: null,
      propiedadOrden: null,
    })
      .subscribe((notificaciones: INotificacion[]) => {
        notificaciones = Array.isArray(notificaciones) ? notificaciones : [];
        const filteredNotifications = notificaciones.filter(
          (notif) => notif.fchLectura === null);
        this.addNewNotifications(filteredNotifications);
      });
  }

  /**
   * Agrega nuevas notificaciones a la lista existente, asegurándose de que no haya duplicados.
   * @param newNotifications - Array de nuevas notificaciones a agregar.
   */
  private addNewNotifications(newNotifications: INotificacion[]): void {
    this.notifications = [...newNotifications];
  }

  /**
   * Si existe una suscripción a intervalos, la cancela para evitar fugas de memoria.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Marca una notificación como leída actualizando su fecha de lectura.
   * @param notification - La notificación que se va a marcar como leída.
   */
  markAsRead(notification: INotificacion): void {
    const fechaLectura = dayjs().format(fechaNotificacionFormat) ?? '';

    this._notificationsService.marcarComoLeida(notification.id ?? '').subscribe({
      next: () => {
        notification.fchLectura = fechaLectura;
        this.notifications = this.notifications.filter(
          (notif) => notif.id !== notification.id);
      },
    });
  }

  /**
   * Marca todas las notificaciones como leídas.
   * Recorre todas las notificaciones y llama a la función `markAsRead` para cada una.
   */
  markAllAsRead(): void {
    this.notifications.forEach((notification) => this.markAsRead(notification));
  }

  /**
   * Abre un popover de opciones para la vista móvil.
   *
   * @param {Event} event - El evento que dispara la apertura del popover.
   * @returns {Promise<void>} Una promesa que se resuelve cuando el popover se ha presentado y se ha manejado la respuesta.
   */
  async abrirPopover(event: Event): Promise<void> {
    const popover = await this._popoverController.create({
      component: UserNotificationsPopoverComponent,
      id: 'popoverNotificacions',
      event,
    });

    await popover.present();
    const resp = await popover.onDidDismiss();

    if (resp.data) {
      const accion: string = resp.data.accion;
      if (accion === 'readAll') {
        this.markAllAsRead();
      }
    }
  }

  /**
   * Abre la modal de notificaciones.
   */
  openModal() {
    this.modal?.present();
  }

  /**
   * Cierra el modal de notificaciones.
   */
  closeModal() {
    this.modal?.dismiss();
  }

  /**
   * Crea y devuelve una animación para la entrada de un modal.
   * Esta animación consta de dos partes:
   * 1. Una animación de fondo que desvanece el elemento de fondo.
   * 2. Una animación del contenedor que desliza el elemento del contenedor del modal de derecha a izquierda.
   * @param baseEl - El elemento HTML base del modal.
   * @returns La animación creada o null si no se encuentra la raíz de sombra.
   */
  enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;
    const duration: number = 300;
    if (!root) {
      return null;
    }

    const backdropAnimation = this._animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop') as HTMLElement)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this._animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper') as HTMLElement)
      .keyframes([
        { offset: 0, opacity: '1', transform: 'translateX(100%)' },
        { offset: 1, opacity: '1', transform: 'translateX(0)' },
      ]);

    return this._animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-in-out')
      .duration(duration)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };


  /**
   * Crea una animación de salida para el elemento base dado invirtiendo la animación de entrada.
   * @param baseEl - El elemento HTML base al que se aplicará la animación de salida.
   * @returns La animación de entrada invertida si existe, de lo contrario, null.
   */
  leaveAnimation = (baseEl: HTMLElement) => {
    const animation = this.enterAnimation(baseEl);
    return animation ? animation.direction('reverse') : null;
  };

  /**
   * Navega a la página de historial de notificaciones.
   */
  public navegarHistorialNotificaciones() {
    this.closeModal();
    this.route.navigateByUrl(urls.notificaciones);
  }

}
