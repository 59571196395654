export const HttpStatusCodes = {
    OK: 200,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    BAD_REQUEST: 400,
    SERVER_ERROR: 500,
    SERVICE_UNAVAIABLE: 503,
    UNPROCESSABLE_CONTENT: 422,
};
