<div class="cca-menu" [ngClass]="{ 'compact': compactMenu && isDesktop}">
  <div class="menu-header">
    <ion-icon class="header-icon" name="gc-user-square"></ion-icon>
    <h2 class="header-title">{{ 'ADMINISTRACION.ADMINISTRACION' | translate }}</h2>
  </div>

  <div class="menu-content">
    <div class="content-nav">
      @for(navLink of navLinks; track navLink) {
      <a class="nav-link" title="{{ navLink.title | translate }}" routerLink="{{navLink.link}}"
        routerLinkActive="link-selected" (click)="navigationUrl(navLink, true)" (keydown.enter)="navigationUrl(navLink,true)">
        <span class="link-icon">
          <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
          <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
        </span>
        <h5 class="link-text">{{ navLink.title | translate }}</h5>
      </a>
      }
    </div>

    <div class="content-switch">
      <a class="switch-link">
        <span class="link-data">
          <p class="data-label">{{ instalacionLabel | translate }}</p>
          <span class="data-text">{{ instalacion | uppercase }}</span>
        </span>
        @if(showCambioInstalacion) {
        <ion-button class="btn-s-accent-outline" (click)="cambioInstalacion()" (keydown.enter)="cambioInstalacion()">{{
          'CCA.MENU.CAMBIAR' | translate }}</ion-button>
        }
      </a>
    </div>
  </div>

  <div class="menu-footer">
    @if(isMdUp) {
    @if(compactMenu) {
    <ion-icon class="gc-footer-icon" name="{{'gc-weather-' + iconName}}" (click)="modeChange($event)"
      (keydown)="modeChange($event)"></ion-icon>
    } @else {
    <div class="gc-footer-toggle">
      <ion-toggle mode="md" class="gc-footer-toggle-btn" [(ngModel)]="modeChanged"
        (ionChange)="modeChange($event)"></ion-toggle>
      <ion-label class="gc-footer-toggle-label">{{ ('MENU.CCA.TOGGLE.' + toogleLabel) | translate }}</ion-label>
    </div>
    }
    <button class="gc-footer-btn ion-hide-md-down" (click)="toggleBtnMenuCCA()" (keydown)="toggleBtnMenuCCA()">
      <ion-icon id="gc-footer-btn-icon" name="gc-arrow-double"></ion-icon>
    </button>
    } @else {
    <a class="gc-footer-link" (click)="navigationUrl(footerExitLink, true)" (keydown.enter)="navigationUrl(footerExitLink,true)">
      <ion-icon class="link-icon" name="gc-arrow-out"></ion-icon>
      <a class="link-text">{{ footerExitLink.title | translate }}</a>
    </a>
    }
  </div>
</div>
