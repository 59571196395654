import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const instalacionesEndPoints = {

    getInstalaciones: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}ctl/users/${apiVx}/me/get-instalaciones`,


    searchPutInstalaciones: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}/instalaciones/${apiVx}`,
    postInstalaciones: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}/instalaciones/${apiVx}`,

    getRolInstalacionUsuario: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}instalaciones/${apiVx}/rolinstalacionusuario`,
};
