import { CommonModule } from '@angular/common';
import { Component, Input, output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IInstalacion } from 'src/app/globalcampo/interfaces/instalaciones/instalacion.interface';

@Component({
  selector: 'gc-gc-instalaciones-usuario-list',
  templateUrl: './gc-instalaciones-usuario-list.component.html',
  styleUrls: ['./gc-instalaciones-usuario-list.component.scss'],
  standalone: true,
  imports:[IonicModule, CommonModule, TranslateModule],
})
export class GcInstalacionesUsuarioListComponent {

  /** Listado de instalaciones */
  @Input()
  public instalaciones:IInstalacion[]|null = null;

  /** Si se define un callBack, se lanza al seleccionar una instalación */
  @Input()
  public selectedInstalacionCallBack: Function|null = null;

  public selectedInstalacion = output<IInstalacion>;

  selectInstalacion(el: IInstalacion): void {
    if(this.selectedInstalacionCallBack){
      this.selectedInstalacionCallBack(el);
    }
    else{
      this.selectedInstalacion().emit(el);
    }
  }

  cancel() {
    if(this.selectedInstalacionCallBack){
        this.selectedInstalacionCallBack();
    }
  }
}
