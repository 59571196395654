import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';
import { menuGCType, menuMAIN } from 'src/app/globalcampo/config/menus-app.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';

@Component({
  selector: 'gc-menu-main-mobile',
  templateUrl: './menu-main-mobile.component.html',
  styleUrls: ['./menu-main-mobile.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MenuMainMobileComponent {

  /**
  * Indica la ruta a la imagen del logo.
  */
  public logo: string = ASSETS_PATHS.logoGlobalcampo;

  /**
 * Indica los nav links del menu
 */
  private readonly _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  constructor( private readonly _router: Router, private readonly _menuController: MenuController ) {}

    /**
   * Recupera un array de elementos de menú de navegación.
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageNavLinks(): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const inicio = menu['inicio'];
      const cca = menu['cca'];
      const lonjas = menu['lonjas'];
      const globalclima = menu['globalclima'];
      const noticias = menu['noticias'];
      const pac = menu['pac'];
      const sar = menu['sar'];
      const gicoop = menu['gicoop'];
      return [inicio, cca, lonjas, globalclima, noticias, pac, sar, gicoop];
    }


    /**
     * Navega a la URL especificada en el elemento del menú y cierra el menú.
     * @param menu - El elemento del menú que contiene el enlace de navegación.
     * @param event - (Opcional) El evento que desencadenó la navegación, utilizado para prevenir la acción predeterminada.
     * @returns Una promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu: IMenu, event?: Event) {
      event?.preventDefault();
      const link: string = menu.link ?? null ?? '';

      this._menuController.close();

      await this._router.navigate([link]);
    }

}
