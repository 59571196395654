
/**
 * Prefijos para la llamadas a los endpoints
 */
export type ENDPOINTS_APPS = typeof ENDPOINTS_PREFIX[keyof typeof ENDPOINTS_PREFIX];
export const ENDPOINTS_PREFIX = {
  api: () => {
    let prefix = 'api/';
    if(window.location.host.includes('ibm')){
      prefix = '';
    }
    return prefix;
  },
} as const;
