<ion-app class="gc-app">
  <ion-menu class="gc-menu ion-hide-md-up" menuId="gc-menu-main" contentId="gc-content">
    <gc-menu-main-mobile></gc-menu-main-mobile>
  </ion-menu>
  <gc-header-main [splitPaneContent]="splitPaneContent()" ></gc-header-main>
  <ion-content class="gc-content" forceOverscroll="false" id="gc-content" >
    <ion-split-pane when="md" class="gc-content-split-pane" contentId="gc-main" [ngClass]="{ 'add-border': splitPaneContent() === 'main' || splitPaneContent() === 'none'}">
      <ion-menu  class="gc-menu" menuId="gc-menu" contentId="gc-main"
        [ngClass]="{ 'main': splitPaneContent() === 'main',
                              'cca': splitPaneContent() === 'cca',
                              'none': splitPaneContent() === 'none',
                              'compact': compactMenu
                            }">
        <!-- MENU CCA -->
        @if (splitPaneContent() === 'cca') { <gc-menu-cca (menuCCACompact)="menuCompact($event)" ></gc-menu-cca> }
        <!-- MENU SUPER ADMIN -->
        @if (splitPaneContent() === 'super-admin') { <gc-menu-super-admin (menuCCACompact)="menuCompact($event)" ></gc-menu-super-admin> }
        <!-- MENU COMPONENTS -->
        @if (splitPaneContent() === 'componentes') { <gc-menu-components (menuCCACompact)="menuCompact($event)"></gc-menu-components>}

      </ion-menu>
      <ion-router-outlet id="gc-main" [ngClass]="{'main': splitPaneContent() === 'main'}"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
</ion-app>
