import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegistroLoggerService } from '../services/registro-logger.service';

@Injectable()
export class MonitoringInterceptor implements HttpInterceptor {
  constructor(private readonly stateLogger: RegistroLoggerService) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const interceptorStart = performance.now();
    const requestOptionsHeader = req.headers.get('GCLogRequest');
    const requestOptions = requestOptionsHeader ? JSON.parse(requestOptionsHeader) : {};
    const { monitor: shouldMonitor, criticalStatus, logEmptyContent = false } = requestOptions;
      return next.handle(req).pipe(
        tap({
          next: (event: HttpEvent<T>) => {
            if (event instanceof HttpResponse && shouldMonitor && (event.status === criticalStatus || logEmptyContent)) {
              setTimeout(() => this.stateLogger.logState('Response', req, event, requestOptions, interceptorStart), 0);
            }
          },
          error: (error: HttpErrorResponse) => {
          if (shouldMonitor !== false) {
              this.stateLogger.logState('Error', req, error,requestOptions, interceptorStart);
            }
          },
        }),
      );
  }
}
