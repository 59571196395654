import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, input, InputSignal, SecurityContext, viewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VectorDirectory } from 'src/app/globalcampo/types/vector-directory.type';

@Component({
  selector: 'gc-svg-wrapper',
  templateUrl: './svg-wrapper.component.html',
  styleUrls: ['./svg-wrapper.component.scss'],
  standalone: true,
})
export class SvgWrapperComponent implements AfterViewInit {
  readonly path: InputSignal<string> = input<string>('assets/images/vectores/');
  readonly directory: InputSignal<VectorDirectory> = input<VectorDirectory>('global');
  readonly name: InputSignal<string> = input.required<string>();
  private readonly svgContainer = viewChild.required<ElementRef>('svgContainer');

  constructor(private readonly http: HttpClient, private readonly _sanitizer: DomSanitizer) { }

  ngAfterViewInit(): void {
    this.loadSvg();
  }

  /**
   * Carga un archivo SVG e inyecta su contenido en el contenedor SVG.
   *
   * Este método construye la ruta del archivo SVG utilizando los métodos `path`, `directory` y `name`,
   * luego realiza una solicitud HTTP GET para recuperar el contenido del SVG como texto. Una vez que se
   * recupera el contenido, se inyecta en el elemento nativo del contenedor SVG.
   *
   * @private
   * @returns {void}
   */
  private loadSvg(): void {
    const format = '.svg';
    const route = `${this.path()}${this.directory()}/${this.name()}${format}`;

    this.http.get(route, { responseType: 'text' }).subscribe((svgContent) => {
      const container = this.svgContainer().nativeElement;
      // Inyecta el SVG
      container.innerHTML = this._sanitizer.sanitize(SecurityContext.NONE, svgContent);
    });
  }

}
