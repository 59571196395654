import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const explotacionesEndPoints = {

    getDatosExplotacion: (codIdentific: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/${codIdentific}`,
    buscarExplotacion: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/filtro/`,

    /**
    *  Obtención de las instalaciones donde codIdentific está apoderado
    * @param codIdentific the identification number of the owner of the exploitation
    * */
    apoderadosCca: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}globalcampo/${apiVx}/apoderadoscca/${codIdentific}`,

    /**
     * Get Rol index
     * @param codIdentific the identification number of the owner of the exploitation
     * */
    getRol:        (codIdentific: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/cuadernos/getRol/${codIdentific}`,

    /**
     * Get entidades relacionadas of an exploitation
     * @param idExploitation
     */
    getEntidadRelacionadByExplotacion: (idExploitation: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/${idExploitation}/entidadrelacionada`,

    /**
     * Get entidades relacionadas of an exploitation
     * @param idExploitation
     */
    getCotitularesByExplotacion: (idExploitation: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/cotitulares/${idExploitation}`,
    /**
     * Get the data of an exploitation
     * @param idExploitation the id of the exploitation
     *  */
    putDgcByExplotacion: (idExploitation: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/cuadernos/explotaciondgcbyexplotacion/${idExploitation}`,

    /**
     * Get the DGC of an exploitation
     * @param idExploitation the id of the exploitation
     *  */
    putDgcByidExplotacion: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/${idExplotacion}?&paginado=false`,

    /**
        * Procesa las miniaturas de las DGC de una explotación
        * @param idExploitation the id of the exploitation
        *  */
    getExportarMiniaturasDGC: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}rea/${apiVx}/exportarminiaturasdgc/${idExplotacion}`,

    putDgcByidExplotacionPagi: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/${idExplotacion}`,


    /**
    * Procesa las miniaturas de una DGC de una explotación y devuelve la url
    * @param idExploitation the id of the exploitation
    * @param dgcId the id of the DGC
    *  */
    getExportarMiniaturaDGC: (idExplotacion: string, dgcId: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}rea/${apiVx}/exportarminiaturasdgc/${idExplotacion}/${dgcId}`,
    /**
     * Obtención de la maquinaria de una explotación
     * @param idExplotacion
     * @returns string
     */
    putMaquinaria: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/maquinariasall/${idExplotacion}`,

    /**
     * Obtención de los trabajadores
     * @param idExplotacion
     * @returns string
     */
    putTrabajadores: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/trabajadoresall/${idExplotacion}`,

    /**
     * Obtención de productos vegetales por codCultivo
     * @param codCultivo
     * @returns string
     */
    getProductosVegetalesCultivo: (codCultivo: number) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/vegetales/${codCultivo}`,

    /**
     * Obtención de los asesores de una instalación
     * @param codInstalacion
     * @returns
     */
    getAsesores: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}instalaciones/${apiVx}/asesores`,

    /**
     * Obtención de los invitados de una explotación
     * @param idExplotacion
     * @returns
     */
    getInvitados: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/invitaciones/${idExplotacion}`,

    /**
     * Creación de un invitado
     * @returns
     */
    postInvitado: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/invitaciones`,

    /**
     * Actualización de un invitado
     * @returns
     */
    patchInvitado: (idAutorizacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/invitaciones/${idAutorizacion}`,

    /**
     * Eliminación de un invitado
     * @returns
     */
    deleteInvitado: (idInvitado: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/invitaciones/${idInvitado}`,
    /**
     * Obtiene los roles definidos en la zona de cuadernos de campo
     * @returns
     */
    getRolesCCA: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}/ctl/users/${apiVx}/rolesexplotacion`,


    getVegtalesExplotacion: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/vegetales/${idExplotacion}`,

    /**
     * Obtiene el listado de edificaciones asociadas a una explotación
     * @param id
     */
    getEdificacionesExplotacion: (id: string): string => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/edificacionesall/${id}`,


    /**
     * Para actualizar los parametros de una explotacion
     * @param id identificador de la explotacion
     * @returns
     */
    patchExplotacionInstalacion: (id: string): string => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/explotacioninstalacion/${id}`,


    getExisteNif: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/existenif/${id}`,
}
export const cuadernosEndPoinst = {

    /**
     * Realiza la búsqueda de producto fitosanitario
     * @returns
     */
    putBusquedaProductoFitosanitario:() => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios`,

    /**
     * Obtiene el pdf de un producto fitosanitario
     * @param id El ID del producto fitosanitario
     * @returns
     */
    getArchivoPDFProductoFitosanitario: (id: string) =>`${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios/archivo/${id}`,


    putProductosFertilizantes:()=> `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fertilizantes`,
    putBusquedaProdFitoExcepciones: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios/excepciones`,

}
