import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, map, Observable, Subject, switchMap, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/feedback/gc-alert/alert.component';
import { IAlertButton } from '../../interfaces/alert/alert.interface';
import { IAlertManagerService, IShowAlertConfig, IShowAlertTemplateConfig, IShowAlertTipoConfig } from './alert-manager.service.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertManagerService implements IAlertManagerService {

  /**
   * Inyecta el ModalController
   */
  private readonly _modalController: ModalController = inject(ModalController);

  /**
   * Subject que escucha cuando se cierra la alerta
   */
  private readonly _listenFormModalClosed: Subject<{data?: IAlertButton}> = new Subject();

  /**
   * Observable que escucha cuando se cierra la alerta
   */
  listenForModalClosed$ = this._listenFormModalClosed.asObservable();

  constructor() { }

  /**
   * Recibe un array de botones y añade la función closeAlert∫
   * a cada uno de ellos. Devuelve el mismo array pero con la
   * función closeAlert añadida
   * @param buttons - Array de botones que se quieren añadir a la alerta
   */
  private buildButtons(buttons: IAlertButton[]): IAlertButton[] {
    return buttons.map( (button) => {
      return {
        tipo: button.tipo,
        text: button.text,
        fn: () => {
          this.closeAlert(button);
        },
      };
    });
  }

  /**
   * Crea una alerta. Devuelve un observable con el data e id que recibe
   * y espera a que se cierre la alerta para devolver un observable con el
   * data e id que recibe en el método closeAlert
   * @param showAlertConfig - Objeto con: tipo, texts, buttons, template e id
   */
  private showAlert(showAlertConfig?: IShowAlertConfig): Observable<{data?: IAlertButton}> {
    const {tipo, texts, buttons, template, backdropDismiss, id} = showAlertConfig ?? {};
    const componentInputs: {[key: string]: unknown} = {
      ...({['tipo'] : tipo}),
      ...(texts?.title ? {['title'] : texts.title} : {}),
      ...(texts?.desc ? {['desc'] : texts.desc} : {}),
      ...(texts?.message ? {['message'] : texts.message} : {}),
      ...(template ? {['template'] : template} : {}),
      ...(buttons ? {['buttons'] : this.buildButtons(buttons)} : {['buttons'] :this.buildButtons([{tipo: 'opacity', text: 'ALERT.BUTTON'}])}),
    };

    return from(this._modalController.create({
      id: id ?? 'gc-alert',
      cssClass: tipo,
      component: AlertComponent,
      backdropDismiss: backdropDismiss ?? true,
      componentProps: componentInputs,
    }))
      .pipe(
        tap((alert) => alert.present()),
        map((alert) => alert.onDidDismiss().then(
          (data) => {
            if(data.role === 'backdrop') {
              this._listenFormModalClosed.next({});
            }
          },
        )),
        switchMap(() => this.listenForModalClosed$ as Observable<{data?: IAlertButton}>),
        take(1),
      );
  }

  private closeAlert(data?: IAlertButton): void {
    this._modalController.dismiss(data).then(() => {
      this._listenFormModalClosed.next({data});
    });

  }

  showAlertDefault(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'default', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertSuccess(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'success', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertError(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'error', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertWarn(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'warn', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertInfo(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'info', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertGlobalcaja(showAlertTipoConfig: IShowAlertTipoConfig): Observable<{data?: IAlertButton}> {
    let {texts, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo: 'globalcaja', texts, buttons, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

  showAlertTemplate(showAlertTipoConfig: IShowAlertTemplateConfig): Observable<{data?: IAlertButton}> {
    const {tipo, template, buttons, backdropDismiss, id} = showAlertTipoConfig ?? {};
    const showAlertConfig: IShowAlertConfig = {tipo, buttons, template, backdropDismiss, id};
    return this.showAlert(showAlertConfig);
  }

}
