
import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const maquinariaEndPoints = {

    postMaquinaria: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/maquinarias`,
    putMaquinaria: (id:string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/maquinarias/${id}`,
    deleteMaquinaria: (id:string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/maquinarias/${id}`,
    getMaquinaria: (id:string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/maquinarias/${id}`,
    putMaquinariaAll: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}cca/${apiVx}/explotacion/maquinariasall/${idExplotacion}`,


};
