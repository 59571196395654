import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonicModule, IonModal, ModalController } from '@ionic/angular';
import { Subject, map, takeUntil } from 'rxjs';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { urls } from 'src/app/globalcampo/config/urls';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'gc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class UserProfileComponent implements OnInit {

  /**
   * Permite controlar el modal del componente
   */
  @ViewChild(IonModal) userProfileModal!: IonModal | null;

  /**
   * Indica el texto utilizado para iniciar una sesión.
   */
  private readonly _loginText: string = 'LOGIN.INICIAR_SESION';
  public get loginText(): string {
    return this._loginText;
  }

  private _isMobileDevice: boolean = false;
  public get isMobileDevice(): boolean {
    return this._isMobileDevice;
  }

  /**
   * Indica el titulo de la modal
   */
  private _modalTitle: string = '';
  public get modalTitle(): string {
    return this._modalTitle;
  }

  /**
   * Indica el usuario
   */
  private _usuario: string | null = null;
  public get usuario(): string | null {
    return this._usuario;
  }
  public set usuario(value: string | null) {
    this._usuario = value;
  }

  /**
 * Indica la imagen del usuario
 */
  private _img: string | null = null;
  public get img(): string | null {
    return this._img;
  }

    /**
   * Indica el nombre completo del usuario
   */
  private _fullUserName: string | null = null;
  public get fullUserName(): string | null {
    return this._fullUserName;
  }

  /**
 * Indica el mail del usuario
 */
  private _mail: string | null = null;
  public get mail(): string | null {
    return this._mail;
  }

  private readonly _unsubs: Subject<unknown> = new Subject();

  constructor( private readonly _loginService: LoginService,
                        private readonly _router: Router,
                        private readonly _modalCtrl: ModalController,
                        private readonly _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this._isMobileDevice = Capacitor.isNativePlatform();
    /**
    * Comprueba si el usuario está logeado
    */
    this._loginService.isLoggedIn$
      .pipe(
        takeUntil(this._unsubs),
        map((data) => {
          const name = data?.name ?? null;
          const surname = data?.apellidos ?? null;
          const mail = data?.email ?? null;
          const userName = name;
          const fullName = `${name} ${surname}`;
          this._fullUserName = fullName;
          this._img = name?.charAt(0).toUpperCase() ?? null;
          this._mail = mail;
          return userName;
        }),
      )
      .subscribe((userName) => {
        this._usuario = userName;
      });

  }

  /**
   * Levanta la modal
   */
  async openModal() {
    this._modalTitle = this._translateService.instant('MODAL.USUARIO.TITLE', { usuario: this._usuario });
    await this.userProfileModal?.present();
  }

  /**
   * Cierra la modal
   */
  public closeModal() {
    this._modalCtrl.dismiss();
  }

  /**
   * Navega a la página de configuración del perfil del usuario.
   */
  public openSettings() {
   this._modalCtrl.dismiss();
   this._router.navigateByUrl(urls.perfil);
  }

  /**
   * Lanza el login
   */
  public openLogin(){
    this._loginService.loginRequest();
  }

  /**
 * Lanza modal de confirmación Logout.
 */
  public closeSession(){
    this._modalCtrl.dismiss();
    this._loginService.logOut();
  }
}
