import { urls } from 'src/app/globalcampo/config/urls';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';

 export const urlSettings = {
    usuarios: 'settings',
 };
 export const menuSettings: IMenu[] = [
    { link: urls.inicio, icon: 'home-outline', cssClass:'margin-bottom', title: 'CCA.MENU.SALIR' },
    { title: 'Usuarios', link: urlSettings.usuarios, icon: 'people-outline', iconHover: 'people' },
];
