import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import {  InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxEchartsModule } from 'ngx-echarts';
import { VisorSigpacNgLibModule } from 'visor-sigpac-ng-lib';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { SIGPAC_UI_SETTINGS } from './app/components/visor-sigpac/sigpac-ui-settings';
import { RequestInterceptor } from './app/globalcampo/helpers/request.interceptor';
import { MonitoringInterceptor } from './app/globalcampo/helpers/logger.interceptor';
import { STATIONS_PROVIDER_TOKEN } from './app/globalcampo/services/globalclima/globalclima.token';
import { LoginService } from './app/globalcampo/services/login.service';
import { ProfileServiceWithCapacitorPrefers } from './app/globalcampo/services/profile/profile.service';
import { PROFILE_TOKEN } from './app/globalcampo/services/profile/profile.token';
import { STORAGE_PROVIDER } from './app/globalcampo/services/storage/storage-provider.token';
import { StorageWithPerfilService } from './app/globalcampo/services/storage/storage-with-perfil.service';
import { WeatherlinkApiService } from './app/globalcampo/services/weatherlinkApi/weatherlinkApi.service';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/es';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LOGGER_PROVIDER } from './app/globalcampo/services/logger/logger-provider.token';
import { LoggerProviderWithNgxLogger } from './app/globalcampo/services/logger/logger-provider-ngx-logger.service';
import { msalConfig, protectedResourcesMap } from './app/globalcampo/config/azure-auth-config';
import { GC_DATE_TOKEN } from './app/globalcampo/services/date/gc-date.token';
import { GcDateService } from './app/globalcampo/services/date/gc-date.service';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

if (environment.production) {
  enableProdMode();
}
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
registerLocaleData(localeDe, 'es-ES');
//permite el uso de formatos personalizados en dayjs
dayjs.extend(customParseFormat);
bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-ES'},
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    provideAnimations(),
    importProvidersFrom(
      IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
      HttpClientModule,
      NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
      LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG}),
      TranslateModule.forRoot({
        defaultLanguage: 'es',
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
      }
      }),
      VisorSigpacNgLibModule.forRoot({ provide: 'SENTINEL_URLS', useValue: {
      URL_BASE_DATA: 'https://ya32ayry19.execute-api.eu-west-1.amazonaws.com/production/sentinel',
      URL_BASE_NDVI_TILER: 'https://ge2294g24e.execute-api.eu-west-1.amazonaws.com/production/sentinel',
      URL_BADE_NDVI: 'https://ya32ayry19.execute-api.eu-west-1.amazonaws.com/production',
      } },
      { provide: 'SIGPAC_HOST', useValue: 'https://us-central1-visorsigpac-57282.cloudfunctions.net/cors?url=https://sigpac.mapa.es' },
      { provide: 'UI_SETTINGS', useValue: SIGPAC_UI_SETTINGS }),

      MsalModule.forRoot(new PublicClientApplication(msalConfig),
        {} as MsalGuardConfiguration,
        {
          // MSAL Interceptor Configuration
          interactionType: InteractionType.Redirect,
          protectedResourceMap: protectedResourcesMap,
      },
      )),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      //Interceptor para anexar información de cooperativa, gestión, empresa
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS,
      //Interceptor para log
     useClass: MonitoringInterceptor,
     multi: true ,
    },
    { provide: PROFILE_TOKEN, useClass: ProfileServiceWithCapacitorPrefers },
    { provide: STORAGE_PROVIDER, useClass: StorageWithPerfilService },
    { provide: STATIONS_PROVIDER_TOKEN, useClass: WeatherlinkApiService },
    { provide: LOGGER_PROVIDER, useClass: LoggerProviderWithNgxLogger },
    { provide: GC_DATE_TOKEN, useClass: GcDateService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    InAppBrowser,
    LoginService,
    provideRouter(routes),
  ],
});
