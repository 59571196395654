import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppStatusService } from '../services/app-status.service';
import { tokenCCAString } from '../config/app-config';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private readonly app: AppStatusService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // Headers' processing
        let {headers, params, url} = req;

          const ccaTOken = this.app.ccaToken;
          if(ccaTOken){
            headers = headers.append(tokenCCAString, ccaTOken);
          }

        /* POR si falla el primer servicio
        if (req.url.includes('sigpac.mapa.es/vectorsdg/vector/recinto')) {
            const newUrl = this.modifyUrl(req.url);
            const modifiedReq = req.clone({ url: newUrl });
            return next.handle(modifiedReq);
          }*/

       if (url.includes('query/RecintoBox') && url.endsWith('.json')) {
              url = url.replace('.json', '');
       }
     if (url.includes('ServiciosVisorSigpac') && url.endsWith('.json')) {
        url = url.replace('.json', '');
      }

        req = req.clone({ headers, params, url });
        return next.handle(req).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && req.url.includes('RecintoBox')) {
              const response = event.body;
    
              if (response?.features?.[0]?.properties) {
                const properties = response.features[0].properties;
                const transformedBody = [properties];
                return event.clone({ body: transformedBody });
              }
            } else if (event instanceof HttpResponse && req.url.includes('ServiciosVisorSigpac') && !req.url.includes('recintobox')) {
              const response = event.body;

              if (response?.features?.[0]?.properties) {
                const properties = (response.features as Array<{ properties: unknown }>).map((item) => item.properties);

                const transformedBody = properties;
                return event.clone({ body: transformedBody });
              }
            }
            return event;
          })
        );
    }
    private modifyUrl(oldUrl: string): string {
        return oldUrl.replace('sigpac.mapa.es/vectorsdg/vector/recinto', 'sdg-sigpac.jccm.es/vector/recinto');
      }
}