import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { urls } from '../../config/urls';
import { Observable,  of} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnderConstructionGuard {

    constructor(private readonly router: Router) {}

    canActivate( _next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return of(this.router.parseUrl(urls.underConstruction));
    }
}
