import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, take } from 'rxjs';
import { urls } from '../../config/urls';
import { IUserAplicacion } from '../../interfaces/user.interface';
import { AlertService } from '../alerts/alert.service';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class CcaVerifiedUserGuard {
  constructor(private readonly router: Router,
              private readonly _globalcampoService: GlobalcampoService,
              private readonly _alertService: AlertService,
              private readonly _translateService: TranslateService,
              private readonly _loading: LoadingService,
            ) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const srv = this._globalcampoService.getMe();
    return srv.pipe(
      map((user: IUserAplicacion|null) => {
        if(user){
          if (user.indVerificado === 'S') {
            return true;
          } else {
              this._alertService.serviceError({
                header:  this._translateService.instant('GUARDS.CCA_AUTHORIZED.HEADER'),
                error: this._translateService.instant('GUARDS.CCA_AUTHORIZED.ERROR'),
              });
          }
        }
        return this.router.parseUrl(urls.inicio);
      }),
      take(1),
    );
  }
}

