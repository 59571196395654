
export const StorageKeys = {
    RECENT_FILTRO_ACT: {
      name: 'RECENT_FILTRO_ACT',
      nivel: 'explotacion',
    },
    RECENT_PRODUCTO_FITO: {
      name: 'RECENT_PRODUCTO_FITO',
      nivel: 'explotacion',
    },
    RECENT_EXPLOTACIONES: {
      name: 'RECENT_EXPLOTACIONES',
      nivel: 'instalacion',
    },
    PROFILE: {
      name: 'PROFILE',
      nivel: 'perfil',
    },
    ALTA_EXPLOTACION: {
      name: 'ALTA_EXPLOTACION',
      nivel: 'perfil',
    },
    CHANGE_ENTIDAD: {
      name: 'ENTIDAD_GESTORA',
      nivel: 'instalacion',
    },
  };
