import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const notificacionesEndPoints = {
  getNotificacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api}notificaciones/${apiVx}/filtro`,
  postNotificacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api}notificaciones/${apiVx}/add`,
  patchNotificacion: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}notificaciones/${apiVx}/edit/${id}`,
  deleteNotificacion: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}notificaciones/${apiVx}/eliminar/${id}`,
};
