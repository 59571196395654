import { EEstadoAsesoramientoKeys as EEstadoAsesoramiento } from '../enums/estado-asesoramiento.enum';
import { IEstadoAsesoramiento } from '../interfaces/estado-asesoramiento.interface';

export const estadoAsesoramientoConf: Record<EEstadoAsesoramiento, IEstadoAsesoramiento> = {
    SOLICITADO:{
        codAuxTipo: 30,
        desAuxTipo: 'ASESORAMIENTO SOLICITADO',
        indAuxTipo: 'S',
    },
    AJUSTES:{
        codAuxTipo: 40,
        desAuxTipo: 'ASESORADA: NECESARIO AJUSTES',
        indAuxTipo: 'S',
    },
    ASESORADA_OK:{
        codAuxTipo: 60,
        desAuxTipo: 'ASESORADA OK',
        indAuxTipo: 'S',
    },
    NO_REQUIERE:{
        codAuxTipo: 10,
        desAuxTipo: 'NO REQUIERE ASESORAMIENTO',
        indAuxTipo: 'S',
    },
    NO_SOLICITADO:{
        codAuxTipo: 20,
        desAuxTipo: 'ASESORAMIENTO NO SOLICITADO',
        indAuxTipo: 'S',
    },
};
