
export const urls = {
    inicio: '/inicio',
    perfil: '/perfil',
    notificaciones: '/historial-notificaciones',
    globalclima: '/globalclima',
    lonjas: '/lonjas',
    gicoop: '/gicoop',
    noticias: '/noticias',
    pac: '/pac',
    sar: '/sar',
    notAuthorized: '/not-authorized',
    underConstruction: '/under-construction',
    internalViewComponents: '/componentes',
    superAdmin: '/super-admin',
    superAdminOptions:{
        newUser: '/super-admin/usuario',
        usuarios: '/super-admin/usuarios',
        logs: '/super-admin/logs',
        log: '/super-admin/log',
        utilidades: '/super-admin/utilidades',
        inicioSettings: '/super-admin/inicio-settings',
        permisos: '/super-admin/permisos',
        lonjas: '/super-admin/lonjas',
        instalaciones: '/super-admin/instalaciones',
    },
    cca: {
        alta_explotacion: '/cca/alta-explotacion',
        instalaciones: '/cca/instalaciones',
        busqueda: '/cca/buscar',
        dashboard: '/cca/dashboard',
        general: '/cca/general',
        titular: '/cca/titular',
        parcelas: '/cca/parcelas',
        invitaciones: '/cca/invitaciones',
        trabajadores: '/cca/trabajadores',
        maquinaria: '/cca/maquinaria',
        actividades: '/cca/actividades',
        // actividades_registro: '/cca/actividades/registro',
        actividad_desconocida: '/cca/actividad/unknow',
        actividades_fitosanitario: '/cca/actividades/tratamiento-fitosanitario',
        actividades_fertilizacion: 'cca/actividades/fertilizacion',
        actividades_analitica: 'cca/actividades/analitica',
        actividades_cosecha: 'cca/actividades/cosecha',
        actividades_datos_cubierta: 'cca/actividades/datos-cubierta',
        actividades_pastoreo: 'cca/actividades/pastoreo',
        actividades_post_cosecha: 'cca/actividades/post-cosecha',
        actividades_tratamiento_edificacion: 'cca/actividades/edificaciones-instalaciones',
        actividades_comercializacion_venta_directa: 'cca/actividades/comercializacion-venta-directa',
        actividades_semilla_tratada: 'cca/actividades/semilla-tratada',
        actividades_plan_abonado: 'cca/actividades/plan-abonado',
        actividades_siembra_plantacion: 'cca/actividades/siembra-plantacion',
        actividades_energia_utilizada: 'cca/actividades/energia-utilizada',
        actividades_actividad_agraria: 'cca/actividades/otra-actividad-agraria',
    },
    interceptorCcaUrsl: ['cca', 'actagraria'],
};

export const externalUrls = {
    lonjas: 'https://globalcampo.es/appweb/lonjas',
};
