const gcNotrabajadoresName = 'gc-notrabajadores';

export const ASSETS_PATHS = {
/** LOGO */
    gcLogoGlobalcampo: '/assets/images/vectores/logo/gc-logo-globalcampo.svg',

/** MAP PIN */
    pinRojo: 'assets/icon/map pin/map-location-red.svg',
    pinVerde: 'assets/icon/map pin/map-location-green.svg',
    pinBrown: 'assets/icon/map pin/map-location-brown.svg',
    pinYellow: 'assets/icon/map pin/map-location-yellow.svg',

/** MODAL LOGIN */
    iconoGlobalcampo: 'assets/images/vectores/logo/icono-globalcampo-derecho.svg',
    iconoGicoop: 'assets/images/vectores/logo/gicoop/icono-gicoop-derecho.svg',
    iconoRuralvia: 'assets/images/vectores/logo/ruralvia/icono-ruralvia-izquierdo.svg',
    imgRuralvia: 'assets/images/vectores/logo/ruralvia/icono-ruralvia.png',

/** MENSAJES INFORMATIVOS */
    vector_error: 'error-resultados',
    vector_acceso_logo: 'error-acceso-logo',
    vector_acceso_accent: 'error-acceso-accent',
    no_actividades: 'gc-noactividades',
    sin_resultados: 'gc-noresultados',
    no_trabajadores: gcNotrabajadoresName,
    /** Debemos tener una imagen genérica de "no resultados"; de momento la mapeo a no-trabajadores que parece que se usa en varios sitios, con lo que cumple el objetivo */
    no_results: gcNotrabajadoresName,
    no_explotaciones: 'gc-noexplotaciones',
    no_maquinaria: gcNotrabajadoresName,

/** MENSAJES INFORMATIVOS */
    logoGlobalcampoBlack: '/assets/images/logo/globalcampo/logo-globalcampo-vGrey.svg',

/** MENU */
    gicoop_outline: '/assets/icon/gc/corporation/gc-gicoop-outline.svg',
    gicoop_fill: '/assets/icon/gc/corporation/gc-gicoop.svg',

/** HOME */
    background_home: '/assets/images/home/background.svg',

/** INICIO */
    background_inicio: '/assets/images/inicio/background.svg',
    tractor: '/assets/images/inicio/tractor.jpg',
    tractor_inicio: '/assets/images/inicio/tractor-inicio.png',

/** CCA - ACTIVIDADES */
    pdfIcon: '/assets/images/cca/actividades/pdf-icon.svg',

/** CCA - PARCELAS  */
    parcela_no_image: 'assets/images/cca/parcelas/no-disponible.png',

/** CCA - DASHBOARD */
    titular_image: 'assets/images/cca/dashboard/titular-img.svg',
    representante_image: 'assets/images/cca/dashboard/representante-img.svg',
    explotacion_image: 'assets/images/cca/dashboard/dexplotacion-img.svg',
};
