<ion-icon id="gc-main-menu-btn" name="gc-user-menu" (click)="toggleMenu()"  (keydown.enter)="toggleMenu()"></ion-icon>

<div id="gc-main-menu-content" #mainMenuContent>
  <div id="gc-main-menu-content-header" routerLink="/" #contentHeader>
    <figure class="header-img">
      <img src="{{logo}}" alt=""/>
    </figure>
    <span class="header-title h1">{{ 'GLOBALCAMPO' | translate }}</span>
  </div>
  <div id="gc-main-menu-content-content">
      <div class="gc-content-nav">
        @for(navLink of navLinks; track navLink) {
          <a class="gc-nav-link" routerLink="{{navLink.link}}" routerLinkActive="active" (click)="navigationUrl(navLink)" (keydown.enter)="navigationUrl(navLink)" >
            <span class="link-icon">
              <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
              <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
            </span>
            <span class="link-title">{{ navLink.title | translate }}</span>
          </a>
        }
      </div>
  </div>
</div>
<div id="gc-main-menu-backdrop" #mainMenuBackdrop></div>
