import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMessageTypes } from '../../enums/message-type.enum';
import { AlertService } from '../alerts/alert.service';
import { AltaExplotacionService } from '../alta-explotacion/alta-explotacion.service';

export const ccaAltaExplotacionSalirProcesoGuard: CanDeactivateFn<unknown> = async () => {
  const alert: AlertService = inject(AlertService);
  const translate: TranslateService = inject(TranslateService);
  const altaExplotacionService: AltaExplotacionService = inject(AltaExplotacionService);

  // Verifica el estado de procesoFinalizado
  if (altaExplotacionService.procesoFinalizado) {
    return true;
  }

  return new Promise<boolean>((resolve) => {
    alert.openAlert(EMessageTypes.warn, {
      code: 0,
      header: translate.instant('ALTA_EXPLOTACION.ALERT.SALIR_PROCESO.HEADER'),
      message: translate.instant('ALTA_EXPLOTACION.ALERT.SALIR_PROCESO.MESSAGE'),
      actionButton: () => resolve(true),
      actionButtonLabel: translate.instant('ALTA_EXPLOTACION.ALERT.SALIR_PROCESO.ACCION'),
      subActionButton: () => resolve(false),
      subActionButtonLabel: translate.instant('ALTA_EXPLOTACION.ALERT.SALIR_PROCESO.SUB_ACCION'),
      backdropDismiss: false,
    });
  });
};
