import { urls } from 'src/app/globalcampo/config/urls';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';

export const urlComponentes = {
   alerts: 'componentes/alerts',
   dataItem: 'componentes/data-item-tester',
   dialogItemList: 'componentes/dialog-item-list',
   cabeceraGlobalcampo: 'componentes/cabecera-globalcampo',
   fechaHora :'componentes/fechaHora',
   iframeViewer :'componentes/iframeviewer',
   inputs: 'componentes/input-custom-tester',
   notificaciones: 'componentes/notificaciones-tester',
   listas: 'componentes/listas-tester',
};

export const menuComponentes: IMenu[] = [
   { link: urls.inicio, icon: 'home-outline', cssClass:'margin-bottom', title: 'CCA.MENU.SALIR' },
   { title: 'Alerts', link: urlComponentes.alerts, icon: 'alert-circle-outline', iconHover: 'alert-circle'},
   // { title: 'Alerts', linkFn: (): ILoadableComponent => ({component: AlertsTesterPage})},
   { title: 'Data Item', link: urlComponentes.dataItem, icon: 'document-text-outline', iconHover: 'document-text'},
   { title: 'Dialog Item List', link: urlComponentes.dialogItemList, icon: 'list-outline', iconHover: 'list'},
   { title: 'Group wrapper', link: urlComponentes.cabeceraGlobalcampo, icon: 'albums-outline', iconHover: 'albums'},
   { title: 'Fecha Hora', link: urlComponentes.fechaHora, icon: 'calendar-outline', iconHover: 'calendar'},
   { title: 'Iframe Viewer', link: urlComponentes.iframeViewer, icon: 'globe-outline', iconHover: 'globe'},
   { title: 'Inputs', link: urlComponentes.inputs, icon: 'document-text-outline', iconHover: 'document-text'},
   { title: 'Notificaciones', link: urlComponentes.notificaciones, icon: 'notifications-outline', iconHover: 'notifications'},
   { title: 'Componentes de listas', link: urlComponentes.listas, icon: 'list-circle-outline', iconHover: 'list-circle'},
]

