import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { notificacionesEndPoints } from '../enpoints/notificaciones.endpoints.v1';
import { INotificacion, INotificacionFiltro, INotificacionPatch } from '../interfaces/notificacion.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {

  /**
   * Notifica que las notificaciones han cambiado.
   */
  public notificationsChanged = new Subject<void>();

  constructor(private readonly http: HttpClient) { }

  public getNotificaciones(body: INotificacionFiltro): Observable<INotificacion[]> {
    return this.http.put<INotificacion[]>(notificacionesEndPoints.getNotificacion, body);
  }

  public postNotificacion(notificacion: INotificacion): Observable<INotificacion> {
    return this.http.post<INotificacion>(notificacionesEndPoints.postNotificacion, notificacion);
  }

  public patchNotificacion(id: string, notificacion: INotificacionPatch[]): Observable<void> {
    return this.http.patch<void>(notificacionesEndPoints.patchNotificacion(id), notificacion);
  }

  /**
   * Marca una notificación como leída.
   * @param id
   */
  public marcarComoLeida(id: string): Observable<void> {
    const fchLectura = new Date().toISOString();
    const notificacionPatch = [{
      'op': 'replace',
      'path': 'fchLectura',
      'value': fchLectura,
    }];
    return this.patchNotificacion(id, notificacionPatch);
  }

  /**
   * Elimina una notificacion
   * @param id
   */
  public deleteNotificacion(id: string): Observable<void> {
    return this.http.delete<void>(notificacionesEndPoints.deleteNotificacion(id));
  }
}
