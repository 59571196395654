import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IAlertButton } from 'src/app/globalcampo/interfaces/alert/alert.interface';
import { IAlertManagerService } from 'src/app/globalcampo/services/alert/alert-manager.service.interface';
import { GC_ALERT_MANAGER_SERVICE_TOKEN } from 'src/app/globalcampo/services/alert/alert-manager.service.token';
import { TAlert } from 'src/app/globalcampo/types/alert.type';
import { SvgWrapperComponent } from '../../contenedores/svg-wrapper/svg-wrapper.component';

@Component({
  selector: 'gc-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SvgWrapperComponent],
})

export class AlertComponent implements OnInit {

  /**
   * Input - Almacena el template que se va a mostrar en el alert:
   * cuando se quiera incluir un template externo se deberá crear
   * un ViewChild en el componente que lo contenga y pasarlo como
   * parámetro a la función showAlertTemplate del servicio de alertas
   */
  @Input() template: TemplateRef<HTMLElement> | undefined;

  /**
   * Input - Almacena el tipo de alerta que se va a mostrar
   * default, success, warn, error, info, globalcaja
   */
  @Input() tipo: TAlert = 'default';

  /**
   * Input - Almacena el título del alert
   */
  @Input() title: string = '';

  /**
   * Input - Almacena la descripción del alert
   */
  @Input() desc: string = '';

  /**
   * Input - Almacena el mensaje del alert
   */
  @Input() message: string = '';

  /**
   * Input - Almacena los botones que se van a mostrar en el alert
   */
  @Input() buttons: IAlertButton[] = [];

  constructor(  private readonly _translateService: TranslateService,
                         private readonly _elementRef: ElementRef,
                         @Inject(GC_ALERT_MANAGER_SERVICE_TOKEN) private readonly _alertManagerService: IAlertManagerService,
  ) {}

  ngOnInit() {
    this.translateContent();
  }


  /**
   * Traduce el contenido textual del alert
   */
  private translateContent(): void {
    this.title = this.title ? this._translateService.instant(this.title) : 'Header title';
    this.desc = this.desc ? this._translateService.instant(this.desc) : '';
    this.message = this.message ? this._translateService.instant(this.message) : 'Message';
    this.buttons = this.buttons.map((button: IAlertButton) => {
      button.text = this._translateService.instant(button.text);
      return button;
    });
  }

}
