import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { IUser, IUserAplicacion } from '../../interfaces/user.interface';
import { urls } from '../../config/urls';

@Injectable({
  providedIn: 'root',
})
export class UserAdminGuard {

  /**
   * Representa al usuario.
   */
  private _user: IUser | null = null;
  public get user(): IUser | null {
    return this._user;
  }

  /**
   * Representa el nivel de acceso del usuario.
   */
  private _nivelAcceso: number = 0;
  public get nivelAcceso(): number {
    return this._nivelAcceso;
  }

  constructor(private readonly router: Router, private readonly _globalcampoService: GlobalcampoService) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._globalcampoService.getMe().pipe(
      map((user: IUserAplicacion) => {
        this._user = user;
        this.getUserData(user);

        if (this._nivelAcceso === 1) {
          return true;
        } else {
          return this.router.parseUrl(urls.inicio);
        }
      }),
      take(1),
    );
  }

  /**
   * Recoge los datos del usuario y los asigna a variables privadas.
   * @param user - El objeto de usuario que contiene los datos.
   */
  public getUserData(user: IUserAplicacion) {
    this._nivelAcceso = user.nivelAcceso;
  }

}
