import { environment } from 'src/environments/environment';
import { apiV1 } from '../config/app-config';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = apiV1;

export const securityEndPoints = {


    /**
     * Endpoint para la obtención del token de selección de instalación y explotación
     */
    tokenInstalacionExplotacion: (): string  => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}sec/${apiVx}/postInfoTokenCca`,

    /**
     * Endpoint para la obtención de la información de una instalación y explotación a patir de un token
     */
    getDataFromTokenCca: (): string  => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}sec/${apiVx}/getDataFromTokenCca`,
};
