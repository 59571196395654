<ion-header #header id="gc-header-main" [ngClass]="{ 'ion-hide-md-down': splitPaneContent() !== 'main' && splitPaneContent() !== 'none'}">
    <ion-icon class="ion-hide-md-up gc-header-main-menu" name="gc-user-menu" (click)="toggleMenu()"  (keydown.enter)="toggleMenu()"></ion-icon>
    <div class="gc-header-main-logo" routerLink="/">
      <figure class="gc-logo-img">
        <img src="{{logo}}" alt="Logotipo" />
      </figure>
      <span class="gc-logo-title">{{ 'GLOBALCAMPO' | translate }}</span>
    </div>
    <gc-menu-main class="ion-hide-md-down"></gc-menu-main>
    <div class="gc-header-main-user">
      @if(authorized){
        <gc-user-notifications></gc-user-notifications>
      }
      <gc-user-profile></gc-user-profile>
    </div>
</ion-header>
