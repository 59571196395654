import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { IInstalacion } from '../interfaces/instalaciones/instalacion.interface';
import { IUser, IUserAplicacion } from '../interfaces/user.interface';
import { ExplotacionesService } from './cuadernos-de-campo/explotaciones.service';
import { GlobalcampoService } from './cuadernos-de-campo/globalcampo.service';
import { LoadingService } from './loading/loading.service';
import { AppStatusService } from './app-status.service';
import { LoginService } from './login.service';
import { GC_MODAL_MANAGER } from './modal-manager/modal-manager.token';
import { IModalManagerService } from './modal-manager/moda-manager.interface';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService  {

  /** Listado de instalaciones cacheadas */
  private _instalacionesCache: IInstalacion[]|null = null;

  constructor(private readonly explotaciones: ExplotacionesService,
              private readonly gc: GlobalcampoService,
              private readonly loading: LoadingService,
              @Inject(GC_MODAL_MANAGER) private readonly modalService: IModalManagerService,
              private readonly appStatusService: AppStatusService,
              private readonly loginService: LoginService,
            ) { }

  /**
   * Gets the information related to the Exploitations' data of the user
   * @param user
   * @returns
   */
  public getCcaData(user: IUser): Observable<IInstalacion[]|null> {

    const getUsuario = this.loading.loadWithMessage(
          this.getMe(user),
          { message: 'MENSAJE_INFORMATIVO.CARGANDO_DATOS_USUARIO', progressValue: 10, keepModalOnFinish: true, openDialog: false});

    const getInstalaciones = this.loading.loadWithMessage(
          this.getInstalaciones(user, 'here'),
          { message: 'MENSAJE_INFORMATIVO.CARGANDO_INSTALACIONES', progressValue: 80, openDialog: false});

    return getUsuario
      .pipe(
        switchMap(() => getInstalaciones),
      );
  }


  /**
   * Muestra el listado de instalaciones disponibles del usuario, o selecciona la única que tenga
   */
  public selectInstalacion(): void {

      const getInstalaciones = this.loginService.isLoggedIn$
      .pipe(
        take(1),
        switchMap((user) => user ? this.getCcaData(user) : of(null)),
      );
      const srv = this.loading.loadWithMessage(getInstalaciones, 'Cargando...');
      srv.pipe(
        switchMap((instalaciones: IInstalacion[]|null) => {
          if((instalaciones || []).length > 0){
            const onNotSelected = this.appStatusService.currentInstalacion;
            return this.modalService.showInstalaciones((instalaciones || []), onNotSelected);
          }
          else{
            return of(null);
          }
      }),
      ).subscribe({
          next: (instalacion: IInstalacion|null) => {
            if(instalacion){
              this.appStatusService.setCurrentInstalacion(instalacion);
            }
            else{
              this.appStatusService.setCurrentInstalacion(null);
            }
          },
      });

  }

  /**
     * Obtiene el índice correspondiente al rol del usuario logeado
     * @param user El usuario a procesar
     * @returns Observable con el usuario con los nuevos datos obtenidos durante el proceso
     */
  private getMe(user: IUser): Observable<IUser> {

    return this.gc.getMe()
      .pipe(
        map((val: IUserAplicacion|null) => {
          user.isActivo = val?.isActivo;
          return user;
        }),
      );
  }

  /**
   * Gets the instalations of a user
   * @param user The user to get the instalations of
   * @param breadCrumb Miga de pan utilizada para indicar desdes donde se ha llamado al método (con fines de trazar el flujo de la aplicación)
   * @returns Observable con la obtención de las intalaciones
   */
  public getInstalaciones(user: IUser, _breadCrumb?: string): Observable<IInstalacion[]|null> {

    let obs = this.gc.getInstalaciones(user.dni)
    .pipe(
      tap((_instalaciones) => this._instalacionesCache = _instalaciones),
    );

    if(this._instalacionesCache) {
      obs = of(this._instalacionesCache);
    }
    return  obs;
}


  /**
   * Gets the representatives of a specific instalation of the user, and fills the user's apoderados property with them
   * @param user The user of which to get the installations data. instalacionSelected's property is required to be informed
   * @returns
   */
  public loadInstalacion(user: IUser): Observable<IUser> {

      const rolIndex = parseInt(user.rolIndex as string, 10);
      if(rolIndex < 0){
        return throwError(() => `No se han podido recuperar sus datos de acceso.`);
      }

      // Data initialization
      user.explotaciones = [];

      if(user.instalacionSelected){
        const instalacion = user.instalacionSelected;
     return this.loading.loadWithMessage(
        this.explotaciones.putSearchExplotacion('', undefined),
        {message: `Cargando Explotaciones de ${instalacion.nombreInstalacion}...`, progressValue: 70},
     )
     .pipe(
          //map(apoderados => ({...user, apoderados}))
          map((explotaciones) => {
            if(explotaciones?.length){
              user.explotaciones = explotaciones;
            }
            return user;
          }),
        );
      }

      // Agricultor
      // if(rolIndex === agricultorRolIndex){
        // // Get get only his own exploitation
        // return this.loading.loadWithMessage(this.explotaciones.getDatosExplotacion(user.dni), { message: 'Obteniendo datos de explotación', progressValue: 70 })
          // .pipe(
            // map(explotacion => {
              // if (!!explotacion) {
                // // Associate himself as a representative
                // const representative: IApoderado = {
                  // codIdentific: user.dni,
                  // codIdentificRelacion: user.dni,
                  // nombreRelacion: user.name,
                  // observaciones: 'Autoapoderamiento para gestión propia'

                // }
                // user.apoderados = [representative]
                // //user = { ...user, explotaciones: [explotacion] };
              // }
              // return user;
            // })
          // )
      // }

      return throwError(() => `Instalación desconocida. El proceso no puede continuar.`);
  }

}
