import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, catchError, finalize, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { explotacionesEndPoints } from '../../enpoints/cuadernos.endpoints.v1';
import { ICcaMaquinaria } from '../../interfaces/cca/actividades/cca-maquinaria.interface';
import { ICcaTrabajador } from '../../interfaces/cca/actividades/cca-trabajador.interface';
import { ICcaEdificacion } from '../../interfaces/cca/actividades/edificacion/cca-actividad-cue-edificacion.interface';
import { ICcaAsesor } from '../../interfaces/cca/actividades/fitosanitario/cca-asesor.interface';
import { IDatosExplotacionDgc, IVegetales } from '../../interfaces/cca/actividades/fitosanitario/datos-dgc.interface';
import { IAsociado, IEntidadRelacionada, IExplotacion, IExplotacionBusqueda } from '../../interfaces/explotaciones/explotacion.inteface';
import { IPaginacionRequest } from '../../interfaces/paginacion-request.interface';
import { LoadingService } from '../loading/loading.service';
import { IDatosToken } from '../../interfaces/security/datos-token.interface';
import { securityEndPoints } from '../../enpoints/security.endpoints.v1';
import { IInstalacionExplotacionDataFromToken } from '../../interfaces/security/instalacion-explotacion-data-from-token.interface';
import { AppStatusService } from '../app-status.service';
import { IPatch } from '../../interfaces/api/patch.interface';

@Injectable({
  providedIn: 'root',
})
export class ExplotacionesService {

  private _cacheDgcs: { [key: string]: IDatosExplotacionDgc[]; } = {};
  public get cacheDgcs(): { [key: string]: IDatosExplotacionDgc[]; } {
    return this._cacheDgcs;
  }
  public set cacheDgcs(value: { [key: string]: IDatosExplotacionDgc[]; }) {
    this._cacheDgcs = value;
  }
  private searchResultState: 'success' | 'empty' | 'error' = 'success';
  private readonly cancelSearch$ = new Subject<void>();

  constructor(private readonly http: HttpClient, private readonly loading: LoadingService) { }

  /**
   * Gets the rol of a user
   * @param codIdentific the identification's number of the user
   * @returns
   */
  getRol(codIdentific: string | null): Observable<number>{
      return this.http.get<number>(explotacionesEndPoints.getRol(codIdentific))
      .pipe(
        catchError((_err) => of(_err)),
      );
  }

  /**
   *
   * @param CodInstalacion El código de la instalación seleccionada
   * @param CodIdentific El NIF de la explotación seleccionada
   * @returns  Token de instalación y explotación
   */
  postTokenInstalacionExplotacion(CodInstalacion: string | undefined, CodIdentific: string): Observable<IDatosToken|null>{
    return this.http.post<IDatosToken|null>(securityEndPoints.tokenInstalacionExplotacion(), { CodInstalacion, CodIdentific })
            .pipe(
              catchError(() => of(null)),
            );
  }

  /**
   * Obtiene la información de instalación y explotación a partir del tokenCca
   * @param Tokencca el token del que obtener la información
   * @returns La Instalación y explotación del token
   */
  getDataFromTokenCca(Tokencca: string): Observable<IInstalacionExplotacionDataFromToken> {
    return this.http.get<IInstalacionExplotacionDataFromToken>(securityEndPoints.getDataFromTokenCca(), {params: {Tokencca}});
  }

  /**
   * Retreive the list of explotaciones of a specified user
   * @param codIdentific the codIdentific of the user
   * @returns
   */
  getDatosExplotacion(codIdentific: string | null): Observable<IExplotacion|null> {
    // Emite un valor para cancelar búsquedas anteriores
    this.cancelSearch$.next();

    this.loading.sendLoadingMessage({ message:'MODAL.LOADING.CARGANDO_EXPLOTACION', progressValue: 50 });
    this.searchResultState = 'success';

    return this.http.get<IExplotacion|null>( explotacionesEndPoints.getDatosExplotacion(codIdentific))
    .pipe(
      map((result) => {
        if (result) {
          return result;
        }
        // Manejar el estado de búsqueda vacía
        this.searchResultState = 'empty';
        return null;
      }),
      // catchError((_error) => {
      //   this.searchResultState = 'error';
      //   this.handleError<IExplotacion|null>('getDatosExplotacion');
      // }),
      finalize(() => {
        if (this.searchResultState === 'error') {
          this.loading.sendLoadingMessage({ message: 'Ha ocurrido un error en la carga.', progressValue: 100 });
        } else if (this.searchResultState === 'empty') {
          this.loading.sendLoadingMessage({ message: 'No se encontraron datos de explotación.', progressValue: 100 });
        } else {
          this.loading.sendLoadingMessage(null);
        }
      }),
      // Cancela la búsqueda si se emite en cancelSearch$
      takeUntil(this.cancelSearch$),
    );
  }


  patchExplotacion(idExploitation:string,updateFields:IPatch[]): Observable<IExplotacionBusqueda | null>{
    return this.http.patch<IExplotacionBusqueda>(explotacionesEndPoints.patchExplotacionInstalacion(idExploitation), updateFields);
  }


  /**
   * Gets all the fields' information
   *  @param busqueda
   * @returns
   */
  putSearchExplotacion(search: string, codInstalacion: string | undefined, paginacion?: IPaginacionRequest, miCartera: boolean | null = null): Observable<IExplotacionBusqueda[]> {
    // Emite un valor para cancelar búsquedas anteriores
    this.cancelSearch$.next();

    let params = new HttpParams();
    params = params.append('paginado', paginacion?.paginado ?? false);
    if(!!paginacion?.pageSize){ params = params.append('pageSize', paginacion.pageSize); }
    if(!!paginacion?.page){ params = params.append('page', paginacion.page); }
    if (miCartera !== null) {
      params = params.append('miCartera', miCartera);
    }

    // const body = { busqueda: search , codInstalacion };
    const body = { busqueda: search , codInstalacion};
    this.loading.sendLoadingMessage({ message: 'Buscando explotaciones...', progressValue: 50 });
    this.searchResultState = 'success';

    return this.http.put<IExplotacionBusqueda[]>(explotacionesEndPoints.buscarExplotacion(), body, {params})
      .pipe(
        switchMap((response: IExplotacionBusqueda[]) => {
          if (!response || response.length === 0) {
            this.searchResultState = 'empty';
          }
          return of(response);
        }),
        // catchError(error => {
        //   this.searchResultState = 'error';
        //   this.handleError<IExplotacionBusqueda|null>('putSearchExplotacion');
        //   return of([]);
        // }),
        finalize(() => {
          this.loading.sendLoadingMessage(null);
        }),
        takeUntil(this.cancelSearch$) // Cancela la búsqueda si se emite en cancelSearch$
      );
  }

  /**
   * Recibe todas las entidades relacionadas de la explotacion
   * @param idExplotacion
   * @returns
   */
  getEntidadRelacionadaByID(idExplotacion: string | null): Observable<IEntidadRelacionada[] | null>{
    return this.http.get<IEntidadRelacionada[]>(explotacionesEndPoints.getEntidadRelacionadByExplotacion(idExplotacion))
    .pipe(
      map((result) => {
        if (result) {
          return result;
        }
        return null;
      }),
    );
  }

  /**
   * Recibe todas los cotitulares de la explotacion
   * @param idExplotacion
   * @returns
   */
  getCotitularesByID(idExplotacion: string): Observable<IAsociado[] | null>{
    return this.http.get<IAsociado[]>(explotacionesEndPoints.getCotitularesByExplotacion(idExplotacion))
    .pipe(
      map((result) => {
        if (result) {
          return result;
        }
        return null;
      }),
    );
  }

  /**
   * Cancela desde el buscador
   */
  cancelSearch(): void {
    this.cancelSearch$.next();
  }

  /**
   * Gets the DGS's data for the specified exploitation
   * @param idExploitation this id of the exploitation to consult to
   * @param filter Filters to restrict the search
   * @returns
   */
  putGetDgcByExplotacion(idExploitation: string, filter?: any): Observable<IDatosExplotacionDgc[]>{
    if(!!this.cacheDgcs[idExploitation]){
      return of(this.cacheDgcs[idExploitation]);
    }
    return this.http.put<IDatosExplotacionDgc[]>(explotacionesEndPoints.putDgcByExplotacion(idExploitation), filter || {}).pipe(
      tap((dgcs) => {
        if(dgcs.length){
          this.cacheDgcs[idExploitation] = dgcs;
        }}
      )
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /**
   * Finds a DGC by ID
   * @param idExploitation the explotacion that the DGC belongs to
   * @param idDgc The Id of the DGC
   * @returns the found DGC or null if not found
   */
  getDgcByID(idExploitation: string, idDgc: string): Observable<IDatosExplotacionDgc|null> {

      const dgc = (this.cacheDgcs[idExploitation] || []).find((el: IDatosExplotacionDgc) => el.id === idDgc);
      return of(dgc ?? null);
  }


  /**
   * Loads DGC's data of a given exploitation codIdentific instead of exploitation ID.
   * @param codIdentific the codIdentific of the person to load
   * @returns Obserbable<IDatosExplotacionDgc[]>
   */
  customGetDGCsByCodIdentific(codIdentific: string): Observable<{explotacion: IExplotacion|null, dgcs: IDatosExplotacionDgc[]}> {

    return this.loading.loadWithMessage(this.getDatosExplotacion(codIdentific), {message: 'Obteniendo explotación...', progressValue: 50})
    .pipe(
      switchMap( (exp: IExplotacion|null) => {
        console.log(exp);
        if(!!exp){
          return this.loading.loadWithMessage(this.putGetDgcByExplotacion(exp.id), {message: `Obteniendo DGC's`, progressValue: 90})
          .pipe( map(dgcs => ({explotacion: exp, dgcs})))
        }
        return of({explotacion: exp, dgcs: []});
      })
    )

  }

  /**
   * Obtiene la maquinaria de la explotación
   * @param idExploitation
   * @param search Posible parámetro de búsqueda
   * @returns
   */
  putMaquinariaExplotacion(idExploitation: string, search?: {busqueda: string}): Observable<ICcaMaquinaria[]> {
    if(!search){
      // El servicio requiere enviar un body no null
      search = {busqueda: ''};
    }
    return this.http.put<ICcaMaquinaria[]>(explotacionesEndPoints.putMaquinaria(idExploitation), search);
  }

  /**
   * Obtiene los trabajadores de una explotación
   * @param idExploitation
   * @param search Posible parámetro de búsqueda
   * @returns
   */
  putTrabajadoresExplotacion(idExploitation: string, search?: {busqueda: string}): Observable<ICcaTrabajador[]> {
    if(!search){
      // El servicio requiere enviar un body no null
      search = {busqueda: ''};
    }
    return this.http.put<ICcaTrabajador[]>(explotacionesEndPoints.putTrabajadores(idExploitation), search);
  }

  /**
   * Obtiene Asesores de una instalación
   * @param codInstalacion
   * @returns
   */
  getAsesores(codInstalacion: string): Observable<ICcaAsesor[]> {
    return this.http.get<ICcaAsesor[]>(explotacionesEndPoints.getAsesores(), { params: { codInstalacion }});
  }


  /**
   * Obtiene los productos vegetales de una explotcion
   * @param idExploitation el identificador de una explotacion
   * @returns un array con los productos vegetales de una explotacion
   */
  getVegtalesExplotacion(idExploitation: string): Observable<IVegetales[]>{
    return this.http.get<IVegetales[]>(explotacionesEndPoints.getVegtalesExplotacion(idExploitation))
  }

  /**
   * Obtiene el listado de edificaciones asociadas a una explotación
   * @param codInstalacion
   * @returns
   */
  getEdificaciones(codInstalacion: string): Observable<ICcaEdificacion[]> {
    return this.http.get<ICcaEdificacion[]>(explotacionesEndPoints.getEdificacionesExplotacion(codInstalacion));
  }

}
