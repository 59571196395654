export const PERMISOS = {
  ADMIN: {
    ADMIN: 'app.admin',
    LISTADO_LOGS: 'app.admin.listado_logs',
    USUARIOS: 'app.admin.usuarios',
    LONJAS: {
      LONJAS: 'app.admin.lonjas',
      MESAS: 'app.admin.lonjas.mesas',
      CATEGORIAS: 'app.admin.lonjas.categorias',
      VARIEDADES: 'app.admin.lonjas.variedades',
      PRODUCTOS: 'app.admin.lonjas.productos',
      PRECIOS: 'app.admin.lonjas.precios',
    },
  },
  CCA: {
    CCA: 'cca',
    ACTIVIDADES: {
      ACTIVIDADES: 'cca.actividades',
      TRATAMFITO: 'cca.actividades.tratamfito',
    },
    INVITADOS: 'cca.invitados',
  },
} as const;

export const PERMISOS_ACCIONES = {
  LECTURA: '.leer',
  CREACION: '.crear',
  EDICION: '.editar',
  ELIMINACION: '.borrar',
  ALL: '.*',
} as const;
