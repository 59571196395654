import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, map, filter } from 'rxjs';
import { PermisosService } from '../permisos.service';
import { IPermisoDetalle } from '../../interfaces/permiso.interface';

@Injectable({
  providedIn: 'root',
})
export class PermisosGuard {
  /**
   * Permisos del usuario actual
   */
  private _permisos: Record<string, IPermisoDetalle> | null = null;

  public get permisos(): Record<string, IPermisoDetalle> | null {
    return this._permisos;
  }

  constructor(private readonly permisosService: PermisosService) {}

  /**
   * Método que determina si una ruta puede ser activada basándose en los permisos del usuario.
   *
   * @param route - El snapshot de la ruta actual que contiene los datos y parámetros de la ruta.
   * @returns Un Observable que emite un valor booleano indicando si la ruta puede ser activada.
   * Para utilizar agregar data: { permiso: EPermiss.nombre_permiso } debajo de la ruta en el archivo de rutas.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permisoRequerido = route.data['permiso'] as string | string[];

    return this.permisosService.permisos$.pipe(
      filter((permisos) => permisos !== null && Object.keys(permisos).length > 0),
      map((permisos) => {
        this._permisos = permisos;

        const permisosRequeridos = Array.isArray(permisoRequerido)
          ? permisoRequerido
          : [permisoRequerido];

        if (permisoRequerido && this.permisosService.hasPermiss(permisosRequeridos)) {
          return true;
        } else {
          this.permisosService.showAlert();
          return false;
        }
      }),
    );
  }
}
