import { inject, Injectable, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, Observable, Subject, switchMap, tap } from 'rxjs';
import { GcInstalacionesUsuarioListComponent } from 'src/app/components/instalaciones-usuario-list/gc-instalaciones-usuario-list.component';
import { IInstalacion } from '../../interfaces/instalaciones/instalacion.interface';
import { IModalManagerService } from './moda-manager.interface';

@Injectable(
    { providedIn: 'root' },
)
export class ModalManagerService implements IModalManagerService {
    private readonly _modalService: ModalController = inject(ModalController);
    private readonly _listenFormModalClosed: Subject<{modalName: string, data?: unknown}> = new Subject();
    listenForModalClosed$ = this._listenFormModalClosed.asObservable();

    /**
     * Muestra el selector de instalaciones
     * @param instalaciones
     */
    showInstalaciones(instalaciones: IInstalacion[], onNotSelected: IInstalacion | null): Observable<IInstalacion | null> {
        const instalacionSelected: Subject<IInstalacion | null> = new Subject();
        return from(this._modalService.create({
            component: GcInstalacionesUsuarioListComponent,
            backdropDismiss: false,
            id: 'modal',
            cssClass: 'show-instalaciones-selector',
            componentProps: {
                instalaciones,
                selectedInstalacionCallBack: (instalacion: IInstalacion) => {
                    if (instalacion) {
                        instalacionSelected.next(instalacion);
                    }else{
                        instalacionSelected.next(onNotSelected);
                    }
                },
            },
        })).pipe(
            tap((modal) => modal.present()),
            switchMap((modal) => instalacionSelected.asObservable().pipe(
                tap(() => modal.dismiss()),
            )),
        );
    }

   showComponent<T>(
    componente: Type<T>,
    modalName: string,
    options?: {id?: string, cssClass?: string, componentInputs?: {[key: string]: unknown}}): Observable< {modalName: string, data?: unknown}>{

      const defaultOptions = {id: 'modal', cssClass: 'md modal-default'};
      const componentProps = {...options?.componentInputs, modalName};
      const finalOptions = {...defaultOptions, ...options, componentProps};
      return from(this._modalService.create({
            component: componente,
            backdropDismiss: true,
            ...finalOptions,
        }))
        .pipe(
            tap((modal) => modal.present()),
            switchMap( () => this.listenForModalClosed$),
        );
    }

    async hideModal(name: string, data?: unknown){
        await this._modalService.dismiss();
        this._listenFormModalClosed.next({modalName: name, data});
    }
}
