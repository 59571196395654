import { Routes } from '@angular/router';
import { urls } from './globalcampo/config/urls';
import { CcaVerifiedUserGuard } from './globalcampo/services/guards/cca-verified-user.guard';
import { IfAuthorizedGoToHomePage } from './globalcampo/services/guards/if-autorized-go-home.guard';
import { LonjasRedirectionGuard } from './globalcampo/services/guards/lonjas-redirection.guard';
import { notInProdAndPreEnvironmentGuard } from './globalcampo/services/guards/notInProdAndPreEnvironment.guard';
import { UserAdminGuard } from './globalcampo/services/guards/user-admin.guard';
import { UserLoggedInGuard } from './globalcampo/services/guards/user-logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/navigation/menu-main/menu-main.routes').then((m) => m.routes),
  },
  {
    path: 'inicio',
    canActivate: [UserLoggedInGuard],
    loadComponent: () =>
      import('./pages/inicio/inicio.page').then((m) => m.InicioPage),
  },
  {
    path: 'cca',
    canActivate: [UserLoggedInGuard, CcaVerifiedUserGuard],
    loadChildren: () => import('./pages/cca/cca.routes').then((m) => m.routes),
  },
  {
    // NOTA: De momento quito el guard para así tener feedback en la analítica de si se accede a la página
    path: 'globalclima',
    // canActivate: [UnderConstructionGuard],
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
    // loadComponent: () => import('./pages/globalclima/globalclima.page').then( (m) => m.GlobalclimaPage),
  },
  {
    // aplico el replace para quitarle el / a la ruta definida
    path: urls.internalViewComponents.replace('/',''),
    canActivate: [notInProdAndPreEnvironmentGuard, UserLoggedInGuard],
    loadChildren: () => import('./pages/_components/components.routes').then((m) => m.routes),
  },
  {
    path: 'super-admin',
    canActivate: [UserLoggedInGuard, UserAdminGuard],
    loadChildren: () => import('./pages/_super-admin/super-admin.routes').then( (m) => m.routes),
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/logout/logout.page').then( (m) => m.LogoutPage),
  },
  {
    canActivate: [IfAuthorizedGoToHomePage],
    path: 'not-authorized',
    loadComponent: () => import('./pages/not-authorized/not-authorized.page').then( (m) => m.NotAuthorizedPage),
  },
  {
    path: 'under-construction',
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
  },
  {
    path: 'noticias',
    // NOTA: De momento quito el guard para así tener feedback en la analítica de si se accede a la página
    // canActivate: [UnderConstructionGuard],
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
    // loadComponent: () => import('./pages/noticias/noticias.page').then( (m) => m.NoticiasPage),
  },
  {
    path: 'gicoop',
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
    // canActivate: [UnderConstructionGuard],
    // loadComponent: () => import('./pages/gicoop/gicoop.page').then( (m) => m.GicoopPage),
  },
  {
    path: 'sar',
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
    // canActivate: [UnderConstructionGuard],
    // loadComponent: () => import('./pages/sar/sar.page').then( (m) => m.SarPage),
  },
  {
    path: 'pac',
    loadComponent: () => import('./pages/under-construction/under-construction.page').then( (m) => m.UnderConstructionPage),
    // canActivate: [UnderConstructionGuard],
    // loadComponent: () => import('./pages/pac/pac.page').then( (m) => m.PacPage),
  },
  {
    path: 'lonjas',
    canActivate: [LonjasRedirectionGuard],
    loadComponent: () => import('./pages/lonjas/lonjas.page').then( (m) => m.LonjasPage),
  },

];
