import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EMessageTypes } from '../../enums/message-type.enum';
import { IAlertContent } from '../../interfaces/alerts/alert-content.interface';
import { IAlertError, IAlertErrorCustomOptions } from '../../interfaces/alerts/back-error.interface';
import { IServiceError } from '../../interfaces/alerts/service-error.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCodes } from '../../config/http-status-codes.config';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(private readonly _alertCtrl: AlertController, private  readonly _trans: TranslateService) { }

  /**
   * Crea la alerta  con los valores introducidos.
   * @param id El id de la alerta que se va a crear.
   * @param cssClass la clase o clases de la alerta.
   * @param alert Los datos necesarios para configurar la alerta.
  */
  async createAlert(id: string, cssClass: string | string[], alert: IAlertContent): Promise<HTMLIonAlertElement> {

    const alertOptions = {
      ...{showIcon:true, backdropDismiss: true},
      ...alert,
    };

   const alertDialog = await this._alertCtrl.create({
      id: id,
      cssClass: cssClass,
      header: alertOptions.header,
      subHeader: alertOptions.subHeader,
      message: alertOptions.message,
      buttons: this.configureButtons(alertOptions, cssClass),
      backdropDismiss: alertOptions?.backdropDismiss,
    });

    if(alertOptions.showIcon) {
      alertDialog.cssClass = cssClass + ' showIcon';
    }

    alertDialog.present();

    return alertDialog;
  }

  /**
   * Construye los botones de la alerta
   * @param alert Los datos necesarios para configurar la alerta.
   * @returns Devuelve los botones configurados.
   */
  private configureButtons(alert: IAlertContent, cssClass: string | string[]): AlertButton[] {
    const buttons: AlertButton[] = [];
    const action = {
      id: 'accion',
      cssClass: `btn-m-${  cssClass  }-solid`,
      text: alert.actionButtonLabel,
      handler: () => alert.actionButton(),
    };
    // Añadimos el botón secundario si se ha configurado.
    if (alert.subActionButton) {
      const subAction = {
        id: 'subAccion',
        cssClass: `btn-m-${  cssClass  }-clear`,
        text: alert.subActionButtonLabel ?? '',
        handler: () => alert.subActionButton ? alert.subActionButton() : () => {},
      };
      buttons.push(subAction);
    }
    buttons.push(action);

    return buttons;
  }

  /**
   * Abre la alerta del tipo con la configuración indicada.
   * @param type Tipo de la alerta que se va a abrir.
   * @param alert Configuración de la alerta.
   */
  openAlert(type: EMessageTypes, alert: IAlertContent): Promise<HTMLIonAlertElement> {
    return this.createAlert('alert', type.toLowerCase(), alert);
  }

  /**
   * Alerta de cerrar sesión.
   * @param alert Configuración de la alerta.
   */
  logOutAlert(alert: IAlertContent): Promise<HTMLIonAlertElement> {
    return this.createAlert('alert', EMessageTypes.default, alert);
  }

  /**
   * Maneja los errores del servicio y devuelve una promesa que se resuelve en un elemento HTMLIonAlertElement.
   * @param error - El objeto de error o HttpErrorResponse.
   * @param customOptions - Opciones personalizadas para la alerta.
   * @returns Una promesa que se resuelve en un elemento HTMLIonAlertElement.
   */
  serviceError( error: IAlertError|HttpErrorResponse,
    customOptions?: IAlertErrorCustomOptions,
    subAction?: Pick<IAlertContent, 'subActionButton' | 'subActionButtonLabel'>): Promise<HTMLIonAlertElement> {
    let alert;
    /** Si existe error.informacionError o no es un error Http, se construye el mensaje por checkHowBuildError */
    if((error.error as IServiceError)?.informacionError || !(error instanceof HttpErrorResponse)){
      alert = this.checkHowBuildError(error);
    }
    else {
      alert = this.buildAlertErrorFromHttpErrorResponse(error, customOptions, subAction);
    }
    return this.openAlert(EMessageTypes.error, alert);
  }

  /**
   * Comprobamos en que tipo obtenemos el error para construirlo de la forma correcta.
   * @param error Objeto de error recibido desde el servicio.
   * @returns
   */
  checkHowBuildError(error: IAlertError): IAlertContent {

    const alert: IAlertContent = this.buildAlertError(error.error, error.header);
    return alert;
  }

  /**
   * Construye la alerta dependiendo del tipo del mensaje.
   * @param error Error del servicio o capturado en el observable
   * @returns Mensaje de la alerta construido.
   */
  private buildAlertError(error: IServiceError | string, header: string | undefined): IAlertContent {
    const alertDialogError: IAlertContent = {
      code: 0,
      showIcon: true,
      header: header ? header : 'Error del servicio',
      message: 'Error no controlado',
      actionButton: () => this.dismiss(),
      actionButtonLabel: 'Cerrar',
    };

    // Añadimos los datos dependiendo del tipo recibido.
    if (typeof error === 'string') {
      //Aqui se gestionan los erroes que back no tienen controlados
      alertDialogError.message = this._trans.instant(error);
    } else {
      const backError = error?.informacionError;
      alertDialogError.code = backError?.codigo;
      const msg = backError?.resultado ? `ALERT.MENSAJE_ERROR_SERVICIO.${backError?.resultado}` : `ALERT.MENSAJE_ERROR_SERVICIO.ERROR_INESPERADO`;
      alertDialogError.message =  this._trans.instant(msg);
      if (backError?.observacionError && backError?.observacionError !== backError?.resultado) {
        alertDialogError.message += `\n${backError.observacionError}`;
      }
      if (backError?.referenciaError) {
          alertDialogError.message += `\nReferencia error: ${backError.referenciaError}`;
      }
    }


    return alertDialogError;
  }

  /**
   *  Construye un mensaje de error a partir de un error Http
   * @param error El error recibido de la llamada del servicio
   * @param options las opciones personalizadas para la alerta
   * @param subAction Opción de incorporar una subacción al diálogo
   * @returns
   */
  private buildAlertErrorFromHttpErrorResponse(
    error: HttpErrorResponse,
    options?: IAlertErrorCustomOptions,
    subAction?: Pick<IAlertContent, 'subActionButton' | 'subActionButtonLabel'>): IAlertContent {

    const alertDialogError: IAlertContent = {
      code: 0,
      showIcon: true,
      header: this._trans.instant('ALERT.ERROR.ERROR_DE_ACCESO_A_URL'),
      message: this._trans.instant('ALERT.ERROR.ERROR_HTTP_NO_PROCESADO') + ` (${error.status})`,
      actionButton: () => this.dismiss(),
      actionButtonLabel: this._trans.instant('GENERAL.CERRAR'),
    };

    if(subAction) {
      alertDialogError.subActionButton = subAction.subActionButton;
      alertDialogError.subActionButtonLabel = subAction.subActionButtonLabel;
    }

    const url = this.removeDomainFromURL(error.url) ?? '';

    switch(error.status){
      case HttpStatusCodes.FORBIDDEN:
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.ACCESO_PROHIBIDO`) + ` a ${url}`;
        break;
      case HttpStatusCodes.UNAUTHORIZED:
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.ACCESO_NO_AUTORIZADO`) + ` a ${url}. ${this._trans.instant('ALERT.ERROR.ACCESO_NO_AUTORIZADO_EXPLAIN')}`;
      break;
      case HttpStatusCodes.NOT_FOUND:
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.NOT_FOUND`) + `: ${url}`;
      break;
      case HttpStatusCodes.BAD_REQUEST:
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.BAD_REQUEST`, {explicacion: options?.explicacion ?? ''}) + `: ${url}`;
      break;
      case HttpStatusCodes.SERVER_ERROR:
        alertDialogError.header =  this._trans.instant('ALERT.ERROR.ERROR_DE_PROCESAMIENTO');
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.ERROR_EN_EL_SERVIDOR`) + ` (${error.status}) al procesar ${url}`;
      break;
      case HttpStatusCodes.SERVICE_UNAVAIABLE:
        alertDialogError.header =  this._trans.instant('ALERT.ERROR.ERROR_DE_PROCESAMIENTO');
        alertDialogError.message = this._trans.instant(`ALERT.ERROR.SERVICIO_NO_DISPONIBLE`) + ` (${error.status}) al procesar ${url}`;
      break;
      case HttpStatusCodes.UNPROCESSABLE_CONTENT:
        alertDialogError.header =  this._trans.instant('ALERT.ERROR.UNPROCESSABLE_CONTENT');
        alertDialogError.message = this._trans.instant('ALERT.ERROR.UNPROCESSABLE_CONTENT_EXPLAIN');
      break;
    }

    return alertDialogError;
  }


  /**
   * Quita la parte del protocolo y dominio de la URL
   * @param url La URL a limpiar
   * @returns Ruta sin el dominio
   */
  private removeDomainFromURL(url: string|null): string {
    return url?.split(/https:\/\/[.A-Za-z0-9-_]+\//)[1] ?? '';
  }

  /**
   * Alerta de cambios sin guardar.
   * @returns Retornamos el observable con la alerta para que pueda ser utilizada por el guard.
   */
  unsavedChanges(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.createAlert('alert',  EMessageTypes.warn, {
        code: 0,
        showIcon: true,
        header: this._trans.instant('ALERT.SALIR_SIN_GUARDAR.HEADER'),
        message: this._trans.instant('ALERT.SALIR_SIN_GUARDAR.MESSAGE'),
        subActionButton: () => observer.next(false),
        subActionButtonLabel: this._trans.instant('ALERT.SALIR_SIN_GUARDAR.SUB_ACCION'),
        actionButton: () => observer.next(true),
        actionButtonLabel: this._trans.instant('ALERT.SALIR_SIN_GUARDAR.ACCION'),
      });
    });
  }

  /**
   * Cierra la alerta abierta.
   * Si no se indica id se cierra la alerta que este arriba.
   * @param data
   * @param role
   * @param id Identificador del alert.
   */
  dismiss(data?: unknown, role?: string, id?: string): void {
    this._alertCtrl.dismiss(data, role, id);
  }

}
