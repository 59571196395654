import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { GlobalcampoService } from '../cuadernos-de-campo/globalcampo.service';
import { IUserAplicacion } from '../../interfaces/user.interface';
import { urls } from '../../config/urls';
import { Injectable } from '@angular/core';
import { AlertService } from '../alerts/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CcaAuthorizedGuard {
  constructor(private readonly router: Router,
              private readonly _globalcampoService: GlobalcampoService,
              private readonly _alertService: AlertService, private readonly _translateService: TranslateService,
            ) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._globalcampoService.getMe().pipe(
      map((user: IUserAplicacion) => {
        if (user.indVerificado === 'S') {
          return true;
        } else {
          this._alertService.serviceError({
            header:  this._translateService.instant('GUARDS.CCA_AUTHORIZED.HEADER'),
            error: this._translateService.instant('GUARDS.CCA_AUTHORIZED.ERROR'),
          });
          return this.router.parseUrl(urls.inicio);
        }
      }),
      take(1),
    );
  }
}

