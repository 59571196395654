import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const permisosEndPoints = {
  getPermisos: (id:string|null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/aplicacion/${id}`,
  putPermisos: (id:string|null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/aplicacion/${id}`,
  getPermisosDisponibles: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/aplicacion`,
  postPermiso: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/permisos`,
  getGrupoPermisos: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/grupo`,
  getPermisosAgrupacion: (agrupacion:string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/permisos-padres/${agrupacion}`,
  putAllPermisos: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/permisos`,
  getPermiso: (codPermiso: number | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/permisos/${codPermiso}`,
  putPermiso: (codPermiso: number | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/permisos/${codPermiso}`,
  getTipoPermiso: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/tipopermiso`,
  postTipoPermiso: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/tipopermiso`,
  putExplotacionesFiltradas: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/filtroexplotacion`,
  getPermisosUsuarioExplotacion: (
    idPgaExplotacion: string | null,
    idUsuario: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/${idPgaExplotacion}/${idUsuario}`,
  putPermisosUsuarioExplotacion: (idUsuario: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/explotacion/${idUsuario}`,
  getPermisosUsuarioExplotacionGenerales: (
    codInstalacion: number | null,
    idUsuario: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisos/${apiVx}/admin/explotacion/${codInstalacion}/${idUsuario}`,
  getPermisosExplotacionUsuario: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}permisoscca/${apiVx}/explotacion`,
};
