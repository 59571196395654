
import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const actividadesEndPoints = {

    postTratamientoFitosanitario: `${environment.apiURL}${ENDPOINTS_PREFIX.api}acttratamfito/${apiVx}`,
    getActTratamFito: (idActivTratamFitosan: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}acttratamfito/${apiVx}/${idActivTratamFitosan}`,
    putTratamientoFitosanitario: (idActivTratamFitosan: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}acttratamfito/${apiVx}/${idActivTratamFitosan}`,

    patchActTratamFito: (idProperty: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}acttratamfito/${apiVx}/${idProperty}`,
    patchActTratamFitoApliMaqui: (idIdentificaAplicador: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}acttratamfito/${apiVx}/${idIdentificaAplicador}`,

    postProductoFitoActTratamFito: `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productofito`,
    deleteProductoFitoActTratamFito: (idIdentificadorActividad: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}productofito/${apiVx}/${idIdentificadorActividad}`,

    getCultivosExplotacion: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/cultivos/${idExplotacion}`,
    getCultivosActividad: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/cultivos/${idExplotacion}`,
    getCultivosActividadSubactividad: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/cultivos/${idExplotacion}`,
    putActividadesAgrarias: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actagraria/${apiVx}/filtro`,
    getUltimaActividadAgraria: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actagraria/${apiVx}/ultima-actividad`,

    deleteActAgraria: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actagraria/${apiVx}/${idAct}`,
    duplicarActAgraria: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actagraria/${apiVx}/duplicar/${idAct}`,

    //Respuestas Errores
    getActRespuestas: (idActividad: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actagraria/${apiVx}/respuestas/${idActividad}`,

    //Analitica
    postActAnalitica: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actanalitica/${apiVx}`,
    getActAnalitica: (idActividadAnalitica: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actanalitica/${apiVx}/${idActividadAnalitica}`,
    putActAnalitica: (idActividadAnalitica: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actanalitica/${apiVx}/${idActividadAnalitica}`,
    deleteActAnalitica: (idActividadAnalitica: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actanalitica/${apiVx}/${idActividadAnalitica}`,

    //ACTIVIDAD EDIFICACION
    getActEdificacion: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actedificacion/${apiVx}/${idAct}`,
    postTratamientoEdificacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actedificacion/${apiVx}`,
    putActEdificacion: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actedificacion/${apiVx}/${idAct}`,

    //Post-cosecha
    postActPostCosecha: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actpostcosecha/${apiVx}`,
    getActPostCosecha: (idActividadPostCosecha: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actpostcosecha/${apiVx}/${idActividadPostCosecha}`,
    putActPostCosecha: (idActividadPostCosecha: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actpostcosecha/${apiVx}/${idActividadPostCosecha}`,

    //ACTIVIDAD PLAN ABONADO
    getPlanAbonado: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actplanabonado/${apiVx}/${idAct}`,
    postPlanAbonado: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actplanabonado/${apiVx}`,
    putPlanAbonado: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actplanabonado/${apiVx}/${idAct}`,

    //Siembra Plantacion
    postActSiembraPlantacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actsiembraplantacion/${apiVx}`,
    getActSiembraPlantacion: (idActividad: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actsiembraplantacion/${apiVx}/${idActividad}`,
    putActSiembraPlantacion: (idActividad: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actsiembraplantacion/${apiVx}/${idActividad}`,
    //ACTIVIDAD ENERGIA UTLIZADA
    getEnergiaUtilizada: (idAct: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actenergia/${apiVx}/${idAct}`,
    postEnergiaUtilizada: `${environment.apiURL}${ENDPOINTS_PREFIX.api}actenergia/${apiVx}`,
    putEnergiaUtilizada: (idActividad: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}actenergia/${apiVx}/${idActividad}`,
}
