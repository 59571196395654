import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const usersEndPoints = {

    me: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/me`,
    registro: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/registro`,
    listado: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/usuarios/aplicacion`,
    getUser: (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/usuarios/${id}`,
    rolesAplicacion: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/rolesaplicacion`,
    rolesUser: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/roles`,
    postUserAdmin : `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/admin/registro`,
    getUserAdmin : (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/usuarios/${id}`,
    putUserAdmin : (id: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/usuarios/${id}`,
    getInstalacionesampliado: `${environment.apiURL}${ENDPOINTS_PREFIX.api}ctl/users/${apiVx}/me/get-instalacionesampliado`,

    deleteUser: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}app/${apiVx}/eliminar-user`,
}
