// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AzureGlobalcampoB2CConfig } from 'src/app/globalcampo/config/azure-ad.config';
import { FIREBASE_API_KEY, WEATHERLINK_API_KEY, WEATHERLINK_API_SECRET} from 'src/app/globalcampo/config/env-vars.config';

const apiURL = 'https://api-dev.globalcampo.es/';
const azureAPI = AzureGlobalcampoB2CConfig.GlobalcampoApi.azureAPI;

export const environment = {
  production: false,
  preproduction: false,
  apiURL,
  azureAPI,
  azureReditectUri: 'https://app-dev.globalcampo.es/',
  azureProtectedResourcesMap: new Map([
    [`${apiURL}api/*`, [`${azureAPI}globalcampo.all`]],
  ]),
  azureB2cPolicies: {
    signIn: 'B2C_1_InicioSesion_DEV',
    signUpSignInAuthority: 'https://globalcampoapp.b2clogin.com/globalcampoapp.onmicrosoft.com/B2C_1_InicioSesion_DEV',
  },
  weatherLinkApiKey: WEATHERLINK_API_KEY,
  weatherLinkApiSecret: WEATHERLINK_API_SECRET,
  googleApiKey: FIREBASE_API_KEY,
  log: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
