
import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageKeys } from '../config/storage-keys.config';
import { perfilEndPoints, perfilExplotacionEndPoints, perfilInstalacionEndPoints } from '../enpoints/perfil.endpoints.v1';
import { IActividadCCA } from '../interfaces/actividad-cca.interface';
import { IExplotacion } from '../interfaces/explotaciones/explotacion.inteface';
import { IInstalacion } from '../interfaces/instalaciones/instalacion.interface';
import { SplitPaneState } from '../interfaces/layout.interface';
import { ITheme, themes } from './profile/profile.interface';


@Injectable({
  providedIn: 'root',
})
export class AppStatusService {


  private readonly _instalacion: BehaviorSubject<IInstalacion|null> = new BehaviorSubject<IInstalacion|null>(null);
  private readonly _instalaciones: WritableSignal<IInstalacion[]|null> = signal(null);
  public get instalacionesOfUser(): IInstalacion[]|null {
    return this._instalaciones();
  }
  public set instalacionesOfUser(value: IInstalacion[]|null) {
    this._instalaciones.set(value ? [...value] : null);
  }

  private readonly INSTALACION_KEY: string = 'GC_INSTALACION';
  private readonly EXPLOTACION_KEY: string = 'GC_EXPLOTACION';
  private readonly FILTRO_KEY: string = 'recentFiltro';
  /**
   * Observable que emite un nuevo valor en el cambio de instalación
   * @default null
   */
  public readonly instalacionChanged$ = this._instalacion.asObservable();

  /**
   * Obtiene la instalación actual
   */
  public get currentInstalacion(): IInstalacion|null {
    return this._instalacion.value;
  }

  /**
   * Obtiene la instalación actual con Signal
   * Se implementa como una opción posterior al mismo valor que emite el BehaviorSubject para utilizarla en funciones computed
   */
  private readonly _currentInstalacionSignal = signal<IInstalacion|null>(null);
  public get currentInstalacionSignal() {
    return this._currentInstalacionSignal;
  }

  /**
   * Establece el token para trabajar en el cuaderno de campo
   * @param accessToken El token con el que se va a trabajar
   */
  private readonly _ccaToken = signal<string|null>(null);
  public set ccaToken(token: string|null){
    this._ccaToken.set(token);
  }
  public get ccaToken(): string|null {
    return this._ccaToken();
  }

  private readonly _explotacion: BehaviorSubject<IExplotacion|null> = new BehaviorSubject<IExplotacion|null>(null);

  /**
   * Observable que emite valor cada cambio de explotación
   * @default null
   */
  public readonly explotacionChanged$ = this._explotacion.asObservable();

  /**
   * Obtención de la explotación actual
   * @returns IExplotacion|null
   */
  public get currentExplotacion(): IExplotacion|null {
    return this._explotacion.value;
  }

/**
   * Obtiene la explotacion actual con Signal
   * Se implementa como una opción posterior al mismo valor que emite el BehaviorSubject para utilizarla en funciones computed
   */
  private readonly _currentExplotacionSignal = signal<IExplotacion|null>(null);
  public get currentExplotacionSignal() {
    return this._currentExplotacionSignal;
  }
  /** Registro y emisión de la actividad en curso */
  private readonly _actividad: BehaviorSubject<IActividadCCA|null> = new BehaviorSubject<IActividadCCA|null>(null);

  public readonly actividadCCAChanged$ = this._actividad.asObservable();

  /**
   * Obtención de la actividad actual
   * @returns IActividadCCA|null
   */
  public get currentActividad(): IActividadCCA|null {
    return this._actividad.value;
  }

  private _codIdentific: string | null = null;
  public get codIdentific(): string | null {
    return this._codIdentific;
  }
  public set codIdentific(value: string | null) {
    this._codIdentific = value;
  }

  private readonly _themesAvailable: ITheme[] = themes;
  public get themesAvailable(): ITheme[] {
    return this._themesAvailable;
  }

  public mainLayout = signal<SplitPaneState>({splitPaneContent: 'main'});

  private _cantidadInstalaciones: number = 0;

  public get cantidadInstalaciones(): number {
    return this._cantidadInstalaciones;
  }

  public set cantidadInstalaciones(value: number) {
    this._cantidadInstalaciones = value;
  }

  private _cantidadExplotaciones: number = 0;
  public get cantidadExplotaciones(): number {
    return this._cantidadExplotaciones;
  }
  public set cantidadExplotaciones(value: number) {
    this._cantidadExplotaciones = value;
  }

   constructor() { }



  public setMainLayout(state: SplitPaneState): void {
    // Obtener el estado actual y actualizarlo con los valores parciales
    const currentState = this.mainLayout();
    const updatedState = {
      ...currentState,
      ...state,
    };
    this.mainLayout.set(updatedState);
  }

  /**
   * Define la instalación que se usará como currentInstalacion
   * @param instalacion
   */
  public async setCurrentInstalacion(instalacion: IInstalacion|null){
    if(!instalacion || (!!instalacion && instalacion?.codInstalacion !== this.currentInstalacion?.codInstalacion)){
      if(this.currentInstalacion?.codInstalacion){
        // Ante el cambio de instalación cancelamos el token que pudiéramos tener, siempre que tuviéramos otra seleccionada
        this._ccaToken.set(null);

      }
      this._explotacion.next(null);
      this._currentExplotacionSignal.set(null);
      this._instalacion.next(instalacion);
      this._currentInstalacionSignal.set(instalacion);
    }
  }

  /**
   * Define la explotación en curso
   * @param explotacion
   */
  public async setCurrentExplotacion(explotacion: IExplotacion|null){
    // Evitamos que si le pasamos la misma explotación, dispare un nuevo valor
    if(!!explotacion && explotacion?.id !== this._explotacion.value?.id){
      this._explotacion.next(explotacion);
      this._currentExplotacionSignal.set(explotacion);
      // Se ha movido la lógica de selección de Layoute al appcomponent
      // this.setMainLayout({menuprincipalLayout: false, menupaginaLayout: true});
    }
    if(!explotacion) {
      this._explotacion.next(null);
      this._currentExplotacionSignal.set(null);
    }
  }

  /**
   * Define la actividad en curdo
   * @param actividad
   */
  public setCurrentActividadCCA(actividad: IActividadCCA|null){
   this._actividad.next(actividad);
  }


  getEndpoints(baseKey: string) {
    const storageKey = Object.values(StorageKeys).find((key) => key.name === baseKey);

    if (!storageKey) {
      // Si la clave no está en la configuración, devuelve la clave base
      return perfilEndPoints;
    }
    switch (storageKey.nivel) {
      case 'perfil':
        return perfilEndPoints;
      case 'explotacion':
        return perfilExplotacionEndPoints;
      case 'instalacion':
        return perfilInstalacionEndPoints;
      default:
        throw new Error(`Nivel desconocido: ${storageKey.nivel}`);
    }
  }

}
