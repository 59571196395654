import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import {  Router, RouterModule } from '@angular/router';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { IonicModule, MenuController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserNotificationsComponent } from 'src/app/components/notificaciones/gc-user-notifications/user-notifications.component';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';
import { UserProfileComponent } from '../../action/gc-user-profile/user-profile.component';
import { MenuMainComponent } from '../menu-main/menu-main.component';
import { MenuMainMobileComponent } from '../menu-main-mobile/menu-main-mobile.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gc-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterModule, MenuMainComponent, MenuMainMobileComponent, UserProfileComponent, UserNotificationsComponent],
})
export class HeaderMainComponent  implements OnInit {

  private readonly destroyRef = inject(DestroyRef);

  /**
   * Recibe el contenido del panel dividido
   */
  splitPaneContent = input<string>();

  /**
   * Indica si el usuario está autorizado.
   */
  private _authorized: boolean = false;
  public get authorized(): boolean {
    return this._authorized;
  }

  /**
   * Indica la ruta a la imagen del logo.
   */
  public logo: string = ASSETS_PATHS.logoGlobalcampo;

  constructor( private readonly _router : Router, private readonly _menuCtrl: MenuController, private readonly _login: LoginService) { }
  ngOnInit(): void {
   this._login.isLoggedIn$
   .pipe(
    takeUntilDestroyed(this.destroyRef),
   )
   .subscribe((loggedIn) => {
    this._authorized = !!loggedIn;
   });
  }

  /**
 * Alterna el menú abriendo el 'gc-menu-main'
 */
  public toggleMenu(): void {
    this._menuCtrl.open('gc-menu-main');
  }


}
