
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { urls } from '../../config/urls';
import { AppStatusService } from '../app-status.service';
import { UsuarioService } from '../usuario.service';

@Injectable({
    providedIn: 'root',
})
export class CcaInstalacionRequired {

    constructor(private readonly app: AppStatusService, private readonly router: Router, private readonly UserService: UsuarioService) {}

    canActivate(
        _next: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot,
    ):  boolean | UrlTree  {

        // Si no hay instalación seleccionada, le mostramos la selección de instalaciones
        return this.app.currentInstalacion ?  true : this.router.parseUrl(urls.cca.instalaciones);

    }
}
