import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';
import { EMessageTypes } from 'src/app/globalcampo/enums/message-type.enum';
import { urls } from 'src/app/globalcampo/config/urls';

@Injectable({
  providedIn: 'root',
})
export class LoggerAlertService {
  constructor(
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly navCtrl: NavController,
  ) {}

  mostrarAlertaDeError(): void {

    this.alertService.createAlert('alert', EMessageTypes.error, {
      code: 0,
      header: this.translateService.instant('ALERT.ERROR.CARGA_CATALOGOS.HEADER'),
      subHeader: '',
      message: this.translateService.instant('ALERT.ERROR.CARGA_CATALOGOS.MESSAGE'),
      actionButton: () => this.navCtrl.navigateRoot(urls.cca.actividades),
      actionButtonLabel: 'Aceptar',
      subActionButton: () => {},
      subActionButtonLabel: '',
      backdropDismiss: false,
    });
  }
}
