import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { IGcDate, TGcDateUnits } from '../../interfaces/gc-date.interface';
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

export const defaultFormat = 'YYYY-MM-DDTHH:mm:ssZ';

@Injectable({
    providedIn: 'root',
})
export class GcDateService implements IGcDate {

    date(): string {
        return dayjs(new Date()).format(defaultFormat);
    }
    /**
     * Formatea una fecha
     * @param targetFormat formato de la fecha destino
     * @param date por defecto es la fecha actual
     * @param sourceFormat formato de la fecha origen
     */
    format(targetFormat: string = defaultFormat, date: Date | string | undefined = undefined, sourceFormat?: string): string {
        return dayjs(date, sourceFormat).format(targetFormat);
    }

    /**
     * Comprueba que una fecha es posterior a otra
     * @param date Fecha base
     * @param dateToCompare Fecha a comparar
     * @param sourceFormat Formato de las fechas
     */
    isAfter(date: Date | string, dateToCompare: Date | string, sourceFormat?: string): boolean {
        const date1 = dayjs(date, sourceFormat);
        const date2 = dayjs(dateToCompare, sourceFormat);
        return dayjs(date1).isAfter(date2);
    }

    /**
     * Comprueba que una fecha es anterior a otra
     * @param date Fecha base
     * @param dateToCompare Fecha a comparar
     * @param sourceFormat Formato de las fechas
     * @param unitCompare Indica la unidad de tiempo a comparar; si se indica mes se comparará mes y año de la fecha
     */
    isBefore(date: Date | string, dateToCompare: Date | string, sourceFormat?: string, unitCompare: TGcDateUnits | undefined = undefined): boolean {
        const date1 = dayjs(date, sourceFormat);
        const date2 = dayjs(dateToCompare, sourceFormat);
        return dayjs(date1).isBefore(date2, unitCompare);
    }

    /**
     * Comprueba si una fecha es válida
     * @param date Fecha a comprobar
     * @param sourceFormat Formato de la fecha
     */
    isValid(date: Date | string, sourceFormat?: string): boolean {
        return dayjs(date, sourceFormat, true).isValid();
    }

    /**
     * Incrementa una fecha
     * @param date Fecha origen
     * @param number Numero de unidades a añadir
     * @param addUnitType Tipo de unidad a añadir
     * @param targetFormat Formato de salida de la fecha generada '[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]')// 'YYYYescape 2019-01-25T00:00:00-02:00Z'
     * @param sourceFormat Formato de entrada de la fecha indicada
     */
    add(date: Date | string, number: number, addUnitType: TGcDateUnits, targetFormat: string = defaultFormat, sourceFormat?: string): string {
        return dayjs(date, sourceFormat).add(number, addUnitType).format(targetFormat);
    }

    /**
     * Resta una fecha
     * @param date Fecha origen
     * @param number Numero de unidades a restar
     * @param subtractUnitType Tipo de unidad a restar
     * @param targetFormat Formato de salida de la fecha generada
     * @param sourceFormat Formato de entrada de la fecha indicada
     */
    subtract(date: Date | string, number: number, subtractUnitType: TGcDateUnits, targetFormat: string = defaultFormat, sourceFormat?: string): string {
        return dayjs(date, sourceFormat).subtract(number, subtractUnitType).format(targetFormat);
    }

    /**
     * Obtiene el año de una fecha
     * @param date Fecha de la que obtener el año
     */
    year(date?: Date | string, sourceFormat?: string): number {
        return dayjs(date, sourceFormat).year();
    }

    /**
     * Obtiene el mes de una fecha
     * @param date Fecha de la que obtener el mes
     */
    month(date?: Date | string, sourceFormat?: string): number {
        return dayjs(date, sourceFormat).month();
    }

    /**
     * Obtiene el dia de una fecha
     * @param date Fecha de la que obtener el dia
     */
    monthDate(date?: Date | string, sourceFormat?: string): number {
        return dayjs(date, sourceFormat).date();
    }

    /**
     * Devuelve la fecha al comienzo de la unidad de tiempo especificada
     * @param date Fecha origen a mover
     * @param unit Unidad a la que mover la fecha
     * @param outputFormat Formato de la fecha de salida
     * @param sourceFormat Formato de la fecha de entrada
     */
    startOf(date: Date | string, unit: TGcDateUnits | 'isoWeek', outputFormat: string, sourceFormat?: string): string {
        return dayjs(date, sourceFormat).startOf(unit).format(outputFormat);
    }

    /**
     * Devuelve la fecha al final de la unidad de tiempo especificada
     * @param date Fecha origen a mover
     * @param unit Unidad a la que mover la fecha
     * @param outputFormat Formato de la fecha de salida
     * @param sourceFormat Formato de la fecha de entrada
     */
    endOf(date: Date | string, unit: TGcDateUnits | 'isoWeek', outputFormat: string = defaultFormat, sourceFormat?: string): string {
        return dayjs(date, sourceFormat).endOf(unit).format(outputFormat);
    }

    /**
     * Comprueba si una fecha es igual o anterior a otra
     * @param date
     * @param dateToCompare
     * @param sourceFormat
     */
    isSameOrBefore(date: Date | string, dateToCompare: Date | string, sourceFormat?: string): boolean {
        const sourceDate = dayjs(date, sourceFormat);
        const comparisonDate = dayjs(dateToCompare, sourceFormat);
        return sourceDate.isSameOrBefore(comparisonDate);
    }

    /**
     * Convierte una fecha a un objeto Date
     * @param date
     * @param sourceFormat
     */
    toDate(date: string, sourceFormat?: string): Date {
        return dayjs(date, sourceFormat).toDate();
    }

    /**
     * Indica la diferencia entre dos fechas
     * @param date
     * @param dateToCompare
     * @param unit
     * @param sourceFormat
     */
    diff(date: Date | string, dateToCompare: Date | string, unit: TGcDateUnits, sourceFormat?: string): number {
        return dayjs(date, sourceFormat).diff(dayjs(dateToCompare, sourceFormat), unit);
    }
}
