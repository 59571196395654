import { addIcons } from 'ionicons';
import { IGlobalcampoIcons, TGlobalcampoIcon, TIconType } from '../interfaces/globalcampo.icon.interface';

const assetsGCPath = '/assets/icon/gc/';
const assetsActivitiesPath = '/assets/icon/gc/activities/';
const assetsImagesCCA = '/assets/images/cca/';
const assetsImagesVectors = '/assets/images/vectores/';

const buildGCPath = (folder: string, name: string) => `${assetsGCPath}${folder}/${name}.svg`;
const buildActivitiesPath = (folder: string, name: string) => `${assetsActivitiesPath}${folder}/${name}.svg`;
const buildImagesCCA = (folder: string, name: string) => `${assetsImagesCCA}${folder}/${name}.svg`;
const buildImagesVectors = (folder: string, name: string) => `${assetsImagesVectors}${folder}/${name}.svg`;


//activitiesFolder
const gcActividadAgrariaFolder = 'actividad-agraria';
const gcActividadAgrariaName = 'gc-actividad-agraria';
const gcActividadAgrariaOutlineName = `${gcActividadAgrariaName}-outline`;
export const gcActividadAgrariaSolid = { [gcActividadAgrariaName]: buildActivitiesPath(gcActividadAgrariaFolder, gcActividadAgrariaName) };
export const gcActividadAgrariaOutline = { [gcActividadAgrariaOutlineName]: buildActivitiesPath(gcActividadAgrariaFolder, gcActividadAgrariaOutlineName) };
const gcAnaliticaFolder = 'analitica';
const gcAnaliticaName = 'gc-analitica';
const gcAnaliticaOutlineName = `${gcAnaliticaName}-outline`;
export const gcAnaliticaSolid = { [gcAnaliticaName]: buildActivitiesPath(gcAnaliticaFolder, gcAnaliticaName) };
export const gcAnaliticaOutline = { [gcAnaliticaOutlineName]: buildActivitiesPath(gcAnaliticaFolder, gcAnaliticaOutlineName) };
const gcComercializacionVentaFolder = 'comercializacion-venta-directa';
const gcComercializacionVentaName = 'gc-comercializacion-venta';
const gcComcercializacionVentaOutlineName = `${gcComercializacionVentaName}-outline`;
export const gcComcercializacionVentaSolid = { [gcComercializacionVentaName]: buildActivitiesPath(gcComercializacionVentaFolder, gcComercializacionVentaName) };
export const gcComcercializacionVentaOutline = { [gcComcercializacionVentaOutlineName]: buildActivitiesPath(gcComercializacionVentaFolder, gcComcercializacionVentaOutlineName) };
const cosechaFolder = 'cosecha';
const gcCosechaName = 'gc-cosecha';
const gcCosechaOutlineName = `${gcCosechaName}-outline`;
export const gcCosechaSolid = { [gcCosechaName]: buildActivitiesPath(cosechaFolder, gcCosechaName) };
export const gcCosechaOutline = { [gcCosechaOutlineName]: buildActivitiesPath(cosechaFolder, gcCosechaOutlineName) };
const gcDatosCubiertaFolder = 'datos-cubierta';
const gcDatosCubiertaName = 'gc-datos-cubierta';
const gcDatosCubiertaOutlineName = `${gcDatosCubiertaName}-outline`;
export const gcDatosCubiertaSolid = { [gcDatosCubiertaName]: buildActivitiesPath(gcDatosCubiertaFolder, gcDatosCubiertaName) };
export const gcDatosCubiertaOutline = { [gcDatosCubiertaOutlineName]: buildActivitiesPath(gcDatosCubiertaFolder, gcDatosCubiertaOutlineName) };
const gcEdificacionesFolder = 'edificaciones-instalaciones';
const gcEdificacionesName = 'gc-edificaciones';
const gcEdificacionesOutlineName = `${gcEdificacionesName}-outline`;
export const gcEdificacionesSolid = { [gcEdificacionesName]: buildActivitiesPath(gcEdificacionesFolder, gcEdificacionesName) };
export const gcEdificacionesOutline = { [gcEdificacionesOutlineName]: buildActivitiesPath(gcEdificacionesFolder, gcEdificacionesOutlineName) };
const gcEnergiaUtilizadaFolder = 'energia-utilizada';
const gcEnergiaUtilizadaName = 'gc-energia-utilizada';
const gcEnergiaUtilizadaOutlineName = `${gcEnergiaUtilizadaName}-outline`;
export const gcEnergiaUtilizadaSolid = { [gcEnergiaUtilizadaName]: buildActivitiesPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaName) };
export const gcEnergiaUtilizadaOutline = { [gcEnergiaUtilizadaOutlineName]: buildActivitiesPath(gcEnergiaUtilizadaFolder, gcEnergiaUtilizadaOutlineName) };
const gcFertilizacionFolder = 'fertilizacion';
const gcFertilizacionName = 'gc-fertilizacion';
const gcFertilizacionOutlineName = `${gcFertilizacionName}-outline`;
export const gcFertilizacionSolid = { [gcFertilizacionName]: buildActivitiesPath(gcFertilizacionFolder, gcFertilizacionName) };
export const gcFertilizacionOutline = { [gcFertilizacionOutlineName]: buildActivitiesPath(gcFertilizacionFolder, gcFertilizacionOutlineName) };
const gcFitosanitarioFolder = 'fitosanitario';
const gcFitosanitarioName = 'gc-fitosanitario';
const gcFitosanitarioOutlineName = `${gcFitosanitarioName}-outline`;
export const gcFitosanitarioSolid = { [gcFitosanitarioName]: buildActivitiesPath(gcFitosanitarioFolder, gcFitosanitarioName) };
export const gcFitosanitarioOutline = { [gcFitosanitarioOutlineName]: buildActivitiesPath(gcFitosanitarioFolder, gcFitosanitarioOutlineName) };
const gcPastoreoFolder = 'pastoreo';
const gcPastoreoName = 'gc-pastoreo';
const gcPastoreoOutlineName = `${gcPastoreoName}-outline`;
export const gcPastoreoSolid = { [gcPastoreoName]: buildActivitiesPath(gcPastoreoFolder, gcPastoreoName) };
export const gcPastoreoOutline = { [gcPastoreoOutlineName]: buildActivitiesPath(gcPastoreoFolder, gcPastoreoOutlineName) };
const gcPlanAbonadoFolder = 'plan-abonado';
const gcPlanAbonadoName = 'gc-plan-abonado';
const gcPlanAbonadoOutlineName = `${gcPlanAbonadoName}-outline`;
export const gcPlanAbonadoSolid = { [gcPlanAbonadoName]: buildActivitiesPath(gcPlanAbonadoFolder, gcPlanAbonadoName) };
export const gcPlanAbonadoOutline = { [gcPlanAbonadoOutlineName]: buildActivitiesPath(gcPlanAbonadoFolder, gcPlanAbonadoOutlineName) };
const gcPostCosechaFolder = 'post-cosecha';
const gcPostCosechaName = 'gc-post-cosecha';
const gcPostCosechaOutlineName = `${gcPostCosechaName}-outline`;
export const gcPostCosechaSolid = { [gcPostCosechaName]: buildActivitiesPath(gcPostCosechaFolder, gcPostCosechaName) };
export const gcPostCosechaOutline = { [gcPostCosechaOutlineName]: buildActivitiesPath(gcPostCosechaFolder, gcPostCosechaOutlineName) };
const gcUsoSemillaTratadaFolder = 'semilla-tratada';
const gcUsoSemillaTratadaName = 'gc-uso-semilla-tratada';
const gcUsoSemillaTratadaOutlineName = `${gcUsoSemillaTratadaName}-outline`;
export const gcUsoSemillaTratadaSolid = { [gcUsoSemillaTratadaName]: buildActivitiesPath(gcUsoSemillaTratadaFolder, gcUsoSemillaTratadaName) };
export const gcUsoSemillaTratadaOutline = { [gcUsoSemillaTratadaOutlineName]: buildActivitiesPath(gcUsoSemillaTratadaFolder, gcUsoSemillaTratadaOutlineName) };
const gcSiembraPlantacionFolder = 'siembra-plantacion';
const gcSiembraPlantacionName = 'gc-siembra-plantacion';
const gcSiembraPlantacionOutlineName = `${gcSiembraPlantacionName}-outline`;
export const gcSiembraPlantacionSolid = { [gcSiembraPlantacionName]: buildActivitiesPath(gcSiembraPlantacionFolder, gcSiembraPlantacionName) };
export const gcSiembraPlantacionOutline = { [gcSiembraPlantacionOutlineName]: buildActivitiesPath(gcSiembraPlantacionFolder, gcSiembraPlantacionOutlineName) };

//addFolder
const addFolder = 'add';
const gcAddFolderName = 'gc-add-folder';
export const gcAddFolderSolid = { [gcAddFolderName]: buildGCPath(addFolder, gcAddFolderName) };
const gcAddMapName = 'gc-add-map';
export const gcAddMapSolid = { [gcAddMapName]: buildGCPath(addFolder, gcAddMapName) };
const gcAddUserName = 'gc-add-user';
export const gcAddUserSolid = { [gcAddUserName]: buildGCPath(addFolder, gcAddUserName) };
const gcAddTracktorName = 'gc-add-tracktor';
export const gcAddTracktorSolid = { [gcAddTracktorName]: buildGCPath(addFolder, gcAddTracktorName) };

//advertisementFolder
const advertisementFolder = 'advertisement';
const gcAdNewsName = 'gc-ad-news';
const gcAdNewsOutlineName = `${gcAdNewsName}-outline`;
export const gcAdNewsSolid = { [gcAdNewsName]: buildGCPath(advertisementFolder, gcAdNewsName) };
export const gcAdNewsOutline = { [gcAdNewsOutlineName]: buildGCPath(advertisementFolder, gcAdNewsOutlineName) };

//agricultureFolder
const agricultureFolder = 'agriculture';
const gcAgriPlantName = 'gc-agriculture-plant';
export const gcAgriPlantSolid = { [gcAgriPlantName]: buildGCPath(agricultureFolder, gcAgriPlantName) };
const gcAgriLeafName = 'gc-agriculture-leaf';
const gcAgriLeafOutlineName = `${gcAgriLeafName}-outline`;
export const gcAgriLeafSolid = { [gcAgriLeafName]: buildGCPath(agricultureFolder, gcAgriLeafName) };
export const gcAgriLeafOutline = { [gcAgriLeafOutlineName]: buildGCPath(agricultureFolder, gcAgriLeafOutlineName) };
const gcAgriSeedFitoName = 'gc-agriculture-seed-fito';
const gcAgriSeedFitoOutlineName = `${gcAgriSeedFitoName}-outline`;
export const gcAgriSeedFitoSolid = { [gcAgriSeedFitoName]: buildGCPath(agricultureFolder, gcAgriSeedFitoName) };
export const gcAgriSeedFitoOutline = { [gcAgriSeedFitoOutlineName]: buildGCPath(agricultureFolder, gcAgriSeedFitoOutlineName) };
const gcAgricultureLeaf01OutlineName = 'gc-agriculture-leaf-01-outline';
export const gcAgricultureLeaf01Outline = { [gcAgricultureLeaf01OutlineName]: buildGCPath(agricultureFolder, gcAgricultureLeaf01OutlineName) };

//arrowFolder
const arrowFolder = 'arrow';
const gcArrowFlatDownName = 'gc-arrow-flat-down';
export const gcArrowFlatDownSolid = { [gcArrowFlatDownName]: buildGCPath(arrowFolder, gcArrowFlatDownName) };
const gcArrowOutName = 'gc-arrow-out';
export const gcArrowOutSolid = { [gcArrowOutName]: buildGCPath(arrowFolder, gcArrowOutName) };
const gcArrowDoubleName= 'gc-arrow-double';
export const gcArrowDoubleSolid = { [gcArrowDoubleName]: buildGCPath(arrowFolder, gcArrowDoubleName) };
const gcArrowBackwardName = 'gc-arrow-backward';
export const gcArrowBackwardSolid = { [gcArrowBackwardName]: buildGCPath(arrowFolder, gcArrowBackwardName) };
const gcArrowForwardName = 'gc-arrow-forward';
export const gcArrowForwardSolid = { [gcArrowForwardName]: buildGCPath(arrowFolder, gcArrowForwardName) };
const gcArrowLeftName = 'gc-arrow-left';
export const gcArrowLeftSolid = { [gcArrowLeftName]: buildGCPath(arrowFolder, gcArrowLeftName) };
const gcArrowRightName = 'gc-arrow-right';
export const gcArrowRightSolid = { [gcArrowRightName]: buildGCPath(arrowFolder, gcArrowRightName) };
const gcArrowDownName = 'gc-arrow-down';
export const gcArrowDownSolid = { [gcArrowDownName]: buildGCPath(arrowFolder, gcArrowDownName) };
const gcArrowUpName = 'gc-arrow-up';
export const gcArrowUpSolid = { [gcArrowUpName]: buildGCPath(arrowFolder, gcArrowUpName) };
const gcArrowFlatRightName = 'gc-arrow-flat-right';
export const gcArrowFlatRightSolid = { [gcArrowFlatRightName]: buildGCPath(arrowFolder, gcArrowFlatRightName) };
const gcArrowExitName = 'gc-arrow-exit';
export const gcArrowExitSolid = { [gcArrowExitName]: buildGCPath(arrowFolder, gcArrowExitName) };
const gcArrowRight05Name = 'gc-arrow-right-05';
export const gcArrowRight05Solid = { [gcArrowRight05Name]: buildGCPath(arrowFolder, gcArrowRight05Name) };

//businessFolder
const businessFolder = 'business';
const gcBusinessIdName = 'gc-business-id-name';
const gcBusinessIdOutlineName = `${gcBusinessIdName}-outline`;
export const gcBusinessIdNameSolid = { [gcBusinessIdName]: buildGCPath(businessFolder, gcBusinessIdName) };
export const gcBusinessIdNameOutline = { [gcBusinessIdOutlineName]: buildGCPath(businessFolder, gcBusinessIdOutlineName) };
const gcBusinessIdNumberName = 'gc-business-id-number';
const gcBusinessIdNumberOutlineName = `${gcBusinessIdNumberName}-outline`;
export const gcBusinessIdNumberSolid = { [gcBusinessIdNumberName]: buildGCPath(businessFolder, gcBusinessIdNumberName) };
export const gcBusinessIdNumberOutline = { [gcBusinessIdNumberOutlineName]: buildGCPath(businessFolder, gcBusinessIdNumberOutlineName) };
const gcBusinessFolderName = 'gc-business-folder';
const gcBusinessFolderOutlineName = `${gcBusinessFolderName}-outline`;
export const gcBusinessFolderSolid = { [gcBusinessFolderName]: buildGCPath(businessFolder, gcBusinessFolderName) };
export const gcBusinessFolderOutline = { [gcBusinessFolderOutlineName]: buildGCPath(businessFolder, gcBusinessFolderOutlineName) };
const gcBusinessNetworkName = 'gc-network-optimization';
export const gcBusinessNetworkSolid = { [gcBusinessNetworkName]: buildGCPath(businessFolder, gcBusinessNetworkName) };
const gcBusinessClipboardName = 'gc-business-clipboard';
const gcBusinessClipboardOutlineName = `${gcBusinessClipboardName}-outline`;
export const gcBusinessClipboardSolid = { [gcBusinessClipboardName]: buildGCPath(businessFolder, gcBusinessClipboardName) };
export const gcBusinessClipboardOutline = { [gcBusinessClipboardOutlineName]: buildGCPath(businessFolder, gcBusinessClipboardOutlineName) };
const gcBusinessTrayName = 'gc-business-tray';
const gcBusinessTrayOutlineName = `${gcBusinessTrayName}-outline`;
export const gcBusinessTraySolid = { [gcBusinessTrayName]: buildGCPath(businessFolder, gcBusinessTrayName) };
export const gcBusinessTrayOutline = { [gcBusinessTrayOutlineName]: buildGCPath(businessFolder, gcBusinessTrayOutlineName) };
const gcBusinessBarChartName = 'gc-business-bar-chart';
const gcBusinessBarChartOutlineName = `${gcBusinessBarChartName}-outline`;
export const gcBusinessBarChartSolid = { [gcBusinessBarChartName]: buildGCPath(businessFolder, gcBusinessBarChartName) };
export const gcBusinessBarChartOutline = { [gcBusinessBarChartOutlineName]: buildGCPath(businessFolder, gcBusinessBarChartOutlineName) };
const gcBusinessTrophytName = 'gc-business-trophy';
export const gcBusinessTrophySolid = { [gcBusinessTrophytName]: buildGCPath(businessFolder, gcBusinessTrophytName) };

//chatFolder
const chatFolder = 'chat';
const gcChatCommentName = 'gc-chat-comment';
const gcChatCommentOutlineName = `${gcChatCommentName}-outline`;
export const gcChatCommentSolid = { [gcChatCommentName]: buildGCPath(chatFolder, gcChatCommentName) };
export const gcChatCommentOutline = { [gcChatCommentOutlineName]: buildGCPath(chatFolder, gcChatCommentOutlineName) };
const gcChatEditName = 'gc-chat-edit';
export const gcChatEditSolid = { [gcChatEditName]: buildGCPath(chatFolder, gcChatEditName) };

//corporationFolder
const corporationFolder = 'corporation';
const gcGicoopName = 'gc-gicoop';
const gcGicoopOutlineName = `${gcGicoopName}-outline`;
export const gcGicoopSolid = { [gcGicoopName]: buildGCPath(corporationFolder, gcGicoopName) };
export const gcGicoopOutline = { [gcGicoopOutlineName]: buildGCPath(corporationFolder, gcGicoopOutlineName) };

//dateFolder
const dateFolder = 'date';
const gcDateCalendarName = 'gc-date-calendar';
const gcDateCalendarOutlineName = `${gcDateCalendarName}-outline`;
export const gcDateCalendarSolid = { [gcDateCalendarName]: buildGCPath(dateFolder, gcDateCalendarName) };
export const gcDateCalendarOutline = { [gcDateCalendarOutlineName]: buildGCPath(dateFolder, gcDateCalendarOutlineName) };

//deviceFolder
const deviceFolder = 'device';
const gcDevicePhoneName = 'gc-device-phone';
export const gcDevicePhoneSolid = { [gcDevicePhoneName]: buildGCPath(deviceFolder, gcDevicePhoneName) };

//emailFolder
const emailFolder = 'email';
const gcEmailSendName = 'gc-email-send';
const gcEmailSendOutlineName = `${gcEmailSendName}-outline`;
export const gcEmailSendSolid = { [gcEmailSendName]: buildGCPath(emailFolder, gcEmailSendName) };
export const gcEmailSendOutline = { [gcEmailSendOutlineName]: buildGCPath(emailFolder, gcEmailSendOutlineName) };
const gcEmailOutlineName = 'gc-email-outline';
export const gcEmailOutline = { [gcEmailOutlineName]: buildGCPath(emailFolder, gcEmailOutlineName) };
const gcEmailReadedName = 'gc-email-readed';
export const gcEmailReadedSolid = { [gcEmailReadedName]: buildGCPath(emailFolder, gcEmailReadedName) };

//farmingFolder
const farmingFolder = 'farming';
const gcFarmingFarmerName = 'gc-farming-farmer';
const gcFarmingFarmerOutlineName = `${gcFarmingFarmerName}-outline`;
export const gcFarmingFarmerSolid = { [gcFarmingFarmerName]: buildGCPath(farmingFolder, gcFarmingFarmerName) };
export const gcFarmingFarmerOutline = { [gcFarmingFarmerOutlineName]: buildGCPath(farmingFolder, gcFarmingFarmerOutlineName) };
const gcFarmingBarnName = 'gc-farming-barn';
const gcFarmingBarnOutlineName = `${gcFarmingBarnName}-outline`;
export const gcFarmingBarnSolid = { [gcFarmingBarnName]: buildGCPath(farmingFolder, gcFarmingBarnName) };
export const gcFarmingBarnOutline = { [gcFarmingBarnOutlineName]: buildGCPath(farmingFolder, gcFarmingBarnOutlineName) };
const gcFarmingGrapesName = 'gc-farming-grapes';
const gcFarmingGrapesOutlineName = `${gcFarmingGrapesName}-outline`;
export const gcFarmingGrapesSolid = { [gcFarmingGrapesName]: buildGCPath(farmingFolder, gcFarmingGrapesName) };
export const gcFarmingGrapesOutline = { [gcFarmingGrapesOutlineName]: buildGCPath(farmingFolder, gcFarmingGrapesOutlineName) };
const gcFarmingFillName = 'gc-farming-fill';
export const gcFarmingFillSolid = { [gcFarmingFillName]: buildGCPath(farmingFolder, gcFarmingFillName) };

//filesFolder
const filesFolder = 'files';
const gcFilesPDFName = 'gc-files-pdf';
export const gcFilesPDFSolid = { [gcFilesPDFName]: buildGCPath(filesFolder, gcFilesPDFName) };
const gcFilesDeleteName = 'gc-files-delete';
const gcFilesDeleteOutlineName = `${gcFilesDeleteName}-outline`;
export const gcFilesDeleteOutline = { [gcFilesDeleteOutlineName]: buildGCPath(filesFolder, gcFilesDeleteOutlineName) };
const gcFilesWarnName = 'gc-files-warn';
export const gcFilesWarnSolid = { [gcFilesWarnName]: buildGCPath(filesFolder, gcFilesWarnName) };
const gcFilesFileName = 'gc-files-file';
export const gcFilesFileSolid = { [gcFilesFileName]: buildGCPath(filesFolder, gcFilesFileName) };
const gcFilesFileOutlineName = 'gc-files-file-outline';
export const gcFilesFileOutline = { [gcFilesFileOutlineName]: buildGCPath(filesFolder, gcFilesFileOutlineName) };
const gcFilesProtectionOutlineName = 'gc-files-protection-outline';
export const gcFilesProtectionOutline = { [gcFilesProtectionOutlineName]: buildGCPath(filesFolder, gcFilesProtectionOutlineName) };
const gcFilesFile01Name = 'gc-files-file-01';
export const gcFilesFile01Solid = { [gcFilesFile01Name]: buildGCPath(filesFolder, gcFilesFile01Name) };

//folderFolder
const folderFolder = 'folder';
const gcFolderErrorName = 'gc-folder-error';
export const gcFolderErrorSolid = { [gcFolderErrorName]: buildGCPath(folderFolder, gcFolderErrorName) };
const gcFolderWarnName = 'gc-folder-warn';
export const gcFolderWarnSolid = { [gcFolderWarnName]: buildGCPath(folderFolder, gcFolderWarnName) };
const gcFolderInfoName = 'gc-folder-info';
export const gcFolderInfoSolid = { [gcFolderInfoName]: buildGCPath(folderFolder, gcFolderInfoName) };
const gcFolderSuccessName = 'gc-folder-success';
export const gcFolderSuccessSolid = { [gcFolderSuccessName]: buildGCPath(folderFolder, gcFolderSuccessName) };

//mapFolder
const mapFolder = 'map';
const gcMapName = 'gc-map';
const gcMapOutlineName = `${gcMapName}-outline`;
export const gcMapSolid = { [gcMapName]: buildGCPath(mapFolder, gcMapName) };
export const gcMapOutline = { [gcMapOutlineName]: buildGCPath(mapFolder, gcMapOutlineName) };

//MenuFolder
const menuFolder = 'menu';
const gcFilesLock = 'gc-files-lock';
const gcFilesLockOutlineName = `${gcFilesLock}-outline`;
export const gcFilesLockSolid = { [gcFilesLock]: buildGCPath(menuFolder, gcFilesLock) };
export const gcFilesLockOutline = { [gcFilesLockOutlineName]: buildGCPath(menuFolder, gcFilesLockOutlineName) };

//starFolder
const starFolder = 'star';
const gcStarName = 'gc-star';
export const gcStarSolid = { [gcStarName]: buildGCPath(starFolder, gcStarName) };

//shapeFolder
const shapeFolder = 'shape';
const gcShapeGridName = 'gc-shape-grid';
export const gcShapeGridSolid = { [gcShapeGridName]: buildGCPath(shapeFolder, gcShapeGridName) };

const gcShapeSmallCircleName = 'gc-shape-small-circle';
export const gcShapeSmallCircleSolid = { [gcShapeSmallCircleName]: buildGCPath(shapeFolder, gcShapeSmallCircleName) };

const gcShapeArrowUpName = 'gc-shape-arrow-up';
const gcShapeArrowDownName = 'gc-shape-arrow-down';
const gcShapeNoChangesName = 'gc-shape-no-changes';

export const gcShapeArrowUpSolid = { [gcShapeArrowUpName]: buildGCPath(shapeFolder, gcShapeArrowUpName) };
export const gcShapeArrowDownSolid = { [gcShapeArrowDownName]: buildGCPath(shapeFolder, gcShapeArrowDownName) };
export const gcShapeNoChangesSolid = { [gcShapeNoChangesName]: buildGCPath(shapeFolder, gcShapeNoChangesName) };

const locationFolder = 'location';
const gcLocationName = 'gc-location-pin';
export const gcLocationSolid = { [gcLocationName]: buildGCPath(locationFolder, gcLocationName) };

//listFolder
const listFolder = 'list';
const gclistName = 'gc-list-outline';
export const gcListOutline = { [gclistName]: buildGCPath(listFolder, gclistName) };


//userFolder
const userFolder = 'user';
const gcUserUser05Name = 'gc-user-user-05';
export const gcUserUser05Solid = { [gcUserUser05Name]: buildGCPath(userFolder, gcUserUser05Name) };
const gcUserName = 'gc-user-profile';
const gcUserOutlineName = `${gcUserName}-outline`;
export const gcUserSolid = { [gcUserName]: buildGCPath(userFolder, gcUserName) };
export const gcUserOutline = { [gcUserOutlineName]: buildGCPath(userFolder, gcUserOutlineName) };
const gcUserMenuName = 'gc-user-menu';
export const gcUserMenuSolid = { [gcUserMenuName]: buildGCPath(userFolder, gcUserMenuName) };
const gcUserNotifOFFName = 'gc-user-notifications';
export const gcUserNotifOFFSolid = { [gcUserNotifOFFName]: buildGCPath(userFolder, gcUserNotifOFFName) };
const gcUserSettingsName = 'gc-user-settings';
export const gcUserSettingsSolid = { [gcUserSettingsName]: buildGCPath(userFolder, gcUserSettingsName) };
const gcUserPlusName = 'gc-user-plus';
export const gcUserPlusSolid = { [gcUserPlusName]: buildGCPath(userFolder, gcUserPlusName) };
const gcUserRefreshName = 'gc-user-refresh';
export const gcUserRefreshSolid = { [gcUserRefreshName]: buildGCPath(userFolder, gcUserRefreshName) };
const gcUserHomeName = 'gc-user-home';
const gcUserHomeOutlineName = `${gcUserHomeName}-outline`;
export const gcUserHomeSolid = { [gcUserHomeName]: buildGCPath(userFolder, gcUserHomeName) };
export const gcUserHomeOutline = { [gcUserHomeOutlineName]: buildGCPath(userFolder, gcUserHomeOutlineName) };
const gcUserMultipleName = 'gc-user-multiple';
const gcUserMultipleOutlineName = `${gcUserMultipleName}-outline`;
export const gcUserMultipleSolid = { [gcUserMultipleName]: buildGCPath(userFolder, gcUserMultipleName) };
export const gcUserMultipleOutline = { [gcUserMultipleOutlineName]: buildGCPath(userFolder, gcUserMultipleOutlineName) };
const gcInputLockSolidName = 'gc-user-lock';
export const gcInputLockSolid = { [gcInputLockSolidName]: buildGCPath(userFolder, gcInputLockSolidName) };
const gcUserCrossName = 'gc-user-cross';
export const gcUserCrossSolid = { [gcUserCrossName]: buildGCPath(userFolder, gcUserCrossName) };
const gcUserDuplicateName = 'gc-user-duplicate';
const gcUserDuplicateOutlineName = `${gcUserDuplicateName}-outline`;
export const gcUserDuplicateOutline = { [gcUserDuplicateOutlineName]: buildGCPath(userFolder, gcUserDuplicateOutlineName) };
const gcUserFilterName = 'gc-user-filter';
export const gcUserFilterSolid = { [gcUserFilterName]: buildGCPath(userFolder, gcUserFilterName) };
const gcUserLayersName = 'gc-user-layers';
export const gcUserLayersSolid = { [gcUserLayersName]: buildGCPath(userFolder, gcUserLayersName) };
const gcUserLocationName = 'gc-user-location';
export const gcUserLocationSolid = { [gcUserLocationName]: buildGCPath(userFolder, gcUserLocationName) };
const gcUserOptionsName = 'gc-user-options';
export const gcUserOptionsSolid = { [gcUserOptionsName]: buildGCPath(userFolder, gcUserOptionsName) };
const gcUserEyeName = 'gc-user-eye';
export const gcUserEyeSolid = { [gcUserEyeName]: buildGCPath(userFolder, gcUserEyeName) };
const gcUserInfoNamw = 'gc-user-info';
export const gcUserInfoSolid = { [gcUserInfoNamw]: buildGCPath(userFolder, gcUserInfoNamw) };
const gcUserSearchName = 'gc-user-search';
export const gcUserSearchSolid = { [gcUserSearchName]: buildGCPath(userFolder, gcUserSearchName) };
const gcUserEyeHideName = 'gc-user-eye-hide';
export const gcUserEyeHideSolid = { [gcUserEyeHideName]: buildGCPath(userFolder, gcUserEyeHideName) };
const gcUserWarn = 'gc-user-warn';
export const gcUserWarnSolid = { [gcUserWarn]: buildGCPath(userFolder, gcUserWarn) };
const gcUserTick = 'gc-user-tick';
export const gcUserTickSolid = { [gcUserTick]: buildGCPath(userFolder, gcUserTick) };
const gcUserRuler = 'gc-user-ruler';
export const gcUserRulerSolid = { [gcUserRuler]: buildGCPath(userFolder, gcUserRuler) };
const gcUserBookName = 'gc-user-book';
const gcUserBookOutlineName = `${gcUserBookName}-outline`;
export const gcUserBookOutline = { [gcUserBookOutlineName]: buildGCPath(userFolder, gcUserBookOutlineName) };

const gcUserSquare = 'gc-user-square';
export const gcUserSquareSolid = { [gcUserSquare]: buildGCPath(userFolder, gcUserSquare) };

//weatherFolder
const weatherFolder = 'weather';
const gcWeatherDropName = 'gc-weather-drop';
const gcWeatherDropOutlineName = `${gcWeatherDropName}-outline`;
export const gcWeatherDropSolid = { [gcWeatherDropName]: buildGCPath(weatherFolder, gcWeatherDropName) };
export const gcWeatherDropOutline = { [gcWeatherDropOutlineName]: buildGCPath(weatherFolder, gcWeatherDropOutlineName) };
const gcWeatherMoonName = 'gc-weather-moon';
const gcWeatherMoonOutlineName = `${gcWeatherMoonName}-outline`;
export const gcWeatherMoonSolid = { [gcWeatherMoonName]: buildGCPath(weatherFolder, gcWeatherMoonName) };
export const gcWeatherMoonOutline = { [gcWeatherMoonOutlineName]: buildGCPath(weatherFolder, gcWeatherMoonOutlineName) };
const gcWeatherSunName = 'gc-weather-sun';
const gcWeatherSunOutlineName = `${gcWeatherSunName}-outline`;
export const gcWeatherSunSolid = { [gcWeatherSunName]: buildGCPath(weatherFolder, gcWeatherSunName) };
export const gcWeatherSunOutline = { [gcWeatherSunOutlineName]: buildGCPath(weatherFolder, gcWeatherSunOutlineName) };
const gcWeatherRainDropName = 'gc-weather-raindrop';
const gcWeatherRainDropOutlineName = `${gcWeatherRainDropName}-outline`;
export const gcWeatherRainDropSolid = { [gcWeatherRainDropName]: buildGCPath(weatherFolder, gcWeatherRainDropName) };
export const gcWeatherRainDropOutline = { [gcWeatherRainDropOutlineName]: buildGCPath(weatherFolder, gcWeatherRainDropOutlineName) };

//idFolder
const idFolder = 'id';
const gcIdNumberSinColor = 'gc-id-number-sin-color';
export const gcIdNumberSinSolid = { [gcIdNumberSinColor]: buildGCPath(idFolder, gcIdNumberSinColor) };

//interfaceFolder
const interfaceFolder = 'interface';
const gcInterfaceMenuName = 'gc-interface-menu';
export const gcInterfaceMenuSolid = { [gcInterfaceMenuName]: buildGCPath(interfaceFolder, gcInterfaceMenuName) };
const gcInterfaceInfoName = 'gc-interface-info';
export const gcInterfaceInfoSolid = { [gcInterfaceInfoName]: buildGCPath(interfaceFolder, gcInterfaceInfoName) };
const gcInterfaceWarnName = 'gc-interface-warn';
export const gcInterfaceWarnSolid = { [gcInterfaceWarnName]: buildGCPath(interfaceFolder, gcInterfaceWarnName) };
const gcInterfaceErrorName = 'gc-interface-error';
export const gcInterfaceErrorSolid = { [gcInterfaceErrorName]: buildGCPath(interfaceFolder, gcInterfaceErrorName) };
const gcInterfaceSuccessName = 'gc-interface-success';
export const gcInterfaceSuccessSolid = { [gcInterfaceSuccessName]: buildGCPath(interfaceFolder, gcInterfaceSuccessName) };
const gcInterfaceMinusName = 'gc-interface-minus';
export const gcInterfaceMinusSolid = { [gcInterfaceMinusName]: buildGCPath(interfaceFolder, gcInterfaceMinusName) };
const gcInterfacePendingName = 'gc-interface-pending';
export const gcInterfacePendingSolid = { [gcInterfacePendingName]: buildGCPath(interfaceFolder, gcInterfacePendingName) };
const gcInterfaceLayersName = 'gc-interface-layers';
const gcInterfaceLayersOutlineName = `${gcInterfaceLayersName}-outline`;
export const gcInterfaceLayersOutline = { [gcInterfaceLayersOutlineName]: buildGCPath(interfaceFolder, gcInterfaceLayersOutlineName) };
const gcInterfaceListName = 'gc-interface-list';
const gcInterfaceListOutlineName = `${gcInterfaceListName}-outline`;
export const gcInterfaceListSolid = { [gcInterfaceListName]: buildGCPath(interfaceFolder, gcInterfaceListName) };
export const gcInterfaceListOutline = { [gcInterfaceListOutlineName]: buildGCPath(interfaceFolder, gcInterfaceListOutlineName) };
const gcInterfaceGridName = 'gc-interface-grid';
const gcInterfaceGridOutlineName = `${gcInterfaceGridName}-outline`;
export const gcInterfaceGridSolid = { [gcInterfaceGridName]: buildGCPath(interfaceFolder, gcInterfaceGridName) };
export const gcInterfaceGridOutline = { [gcInterfaceGridOutlineName]: buildGCPath(interfaceFolder, gcInterfaceGridOutlineName) };

//dashboardFolder
const dashboardFolder = 'dashboard';
const gcDashExplotacionName = 'gc-dashboard-explotacion';
export const gcDashExplotacionsolid = { [gcDashExplotacionName]: buildImagesCCA(dashboardFolder, gcDashExplotacionName) };
const gcDashRepresentanteName = 'gc-dashboard-representante';
export const gcDashRepresentantesolid = { [gcDashRepresentanteName]: buildImagesCCA(dashboardFolder, gcDashRepresentanteName) };
const gcDashTitularName = 'gc-dashboard-titular';
export const gcDashTitularsolid = { [gcDashTitularName]: buildImagesCCA(dashboardFolder, gcDashTitularName) };

//globalFolder
const globalFolder = 'global';
const gcGlobalAccesibleName = 'gc-global-accesible';
export const gcGlobalAccesiblesolid = { [gcGlobalAccesibleName]: buildImagesVectors(globalFolder, gcGlobalAccesibleName) };
const gcGlobalGicoopName = 'gc-global-gicoop';
export const gcGlobalGicoopsolid = { [gcGlobalGicoopName]: buildImagesVectors(globalFolder, gcGlobalGicoopName) };
const gcGlobalFacilName = 'gc-global-facil';
export const gcGlobalFacilsolid = { [gcGlobalFacilName]: buildImagesVectors(globalFolder, gcGlobalFacilName) };
const gcGlobalWeatherName = 'gc-global-weather';
export const gcGlobalWeathersolid = { [gcGlobalWeatherName]: buildImagesVectors(globalFolder, gcGlobalWeatherName) };
const gcGlobalShoppingName = 'gc-global-shopping';
export const gcGlobalShoppingsolid = { [gcGlobalShoppingName]: buildImagesVectors(globalFolder, gcGlobalShoppingName) };
const gcGlobalGestionName = 'gc-global-gestion';
export const gcGlobalGestionsolid = { [gcGlobalGestionName]: buildImagesVectors(globalFolder, gcGlobalGestionName) };
const gcGlobalPlatformsName = 'gc-global-platforms';
export const gcGlobalPlatformssolid = { [gcGlobalPlatformsName]: buildImagesVectors(globalFolder, gcGlobalPlatformsName) };
const gcGlobalWeatherappName = 'gc-global-weatherapp';
export const gcGlobalWeatherappsolid = { [gcGlobalWeatherappName]: buildImagesVectors(globalFolder, gcGlobalWeatherappName) };
const gcGlobalAhorroName = 'gc-global-ahorro';
export const gcGlobalAhorrosolid = { [gcGlobalAhorroName]: buildImagesVectors(globalFolder, gcGlobalAhorroName) };
const gcGlobalNormativaName = 'gc-global-normativa';
export const gcGlobalNormativasolid = { [gcGlobalNormativaName]: buildImagesVectors(globalFolder, gcGlobalNormativaName) };
const gcGlobalUsuarioName = 'gc-global-usuario';
export const gcGlobalUsuariosolid = { [gcGlobalUsuarioName]: buildImagesVectors(globalFolder, gcGlobalUsuarioName) };
const gcGlobalCooperativaName = 'gc-global-cooperativa';
export const gcGlobalCooperativasolid = { [gcGlobalCooperativaName]: buildImagesVectors(globalFolder, gcGlobalCooperativaName) };
const gcGlobalInstagramName = 'gc-global-instagram';
export const gcGlobalInstagramsolid = { [gcGlobalInstagramName]: buildImagesVectors(globalFolder, gcGlobalInstagramName) };
const gcGlobalTwitterName = 'gc-global-twitter';
export const gcGlobalTwittersolid = { [gcGlobalTwitterName]: buildImagesVectors(globalFolder, gcGlobalTwitterName) };
const gcGlobalYoutubeName = 'gc-global-youtube';
export const gcGlobalYoutubesolid = { [gcGlobalYoutubeName]: buildImagesVectors(globalFolder, gcGlobalYoutubeName) };
const gcGlobalNoactividadesName = 'gc-global-noactividades';
export const gcGlobalNoactividadessolid = { [gcGlobalNoactividadesName]: buildImagesVectors(globalFolder, gcGlobalNoactividadesName) };
const gcGlobalNoexplotacionesName = 'gc-global-noexplotaciones';
export const gcGlobalNoexplotacionessolid = { [gcGlobalNoexplotacionesName]: buildImagesVectors(globalFolder, gcGlobalNoexplotacionesName) };
const gcGlobalNoresultadosName = 'gc-global-noresultados';
export const gcGlobalNoresultadossolid = { [gcGlobalNoresultadosName]: buildImagesVectors(globalFolder, gcGlobalNoresultadosName) };
const gcGlobalNotrabajadoresName = 'gc-global-notrabajadores';
export const gcGlobalNotrabajadoressolid = { [gcGlobalNotrabajadoresName]: buildImagesVectors(globalFolder, gcGlobalNotrabajadoresName) };
const gcGlobalNoNotificationName = 'gc-global-no-notification';
export const gcGlobalNoNotificationsolid = { [gcGlobalNoNotificationName]: buildImagesVectors(globalFolder, gcGlobalNoNotificationName) };
const gcGlobalLogOutName = 'gc-global-logout';
export const gcGlobalLogOutsolid = { [gcGlobalLogOutName]: buildImagesVectors(globalFolder, gcGlobalLogOutName) };
const gcGlobalSignName = 'gc-global-sign';
export const gcGlobalSignsolid = { [gcGlobalSignName]: buildImagesVectors(globalFolder, gcGlobalSignName) };
const gcGlobalConstructionName = 'gc-global-construction';
export const gcGlobalConstructionsolid = { [gcGlobalConstructionName]: buildImagesVectors(globalFolder, gcGlobalConstructionName) };

//alta-explotacion Folder
const altaExplotacionFolder = 'alta-explotacion';
const gcMensajeCheckDatosName = 'gc-mensaje-check-datos';
export const gcMensajeCheckDatos = { [gcMensajeCheckDatosName]: buildImagesVectors(altaExplotacionFolder, gcMensajeCheckDatosName) };
const gcMensajeDatosAdicionalesName = 'gc-mensaje-datos-adicionales';
export const gcMensajeDatosAdicionales = { [gcMensajeDatosAdicionalesName]: buildImagesVectors(altaExplotacionFolder, gcMensajeDatosAdicionalesName) };
const gcMensajeDescargaCompletadoName = 'gc-mensaje-descarga-completado';
export const gcMensajeDescargaCompletado = { [gcMensajeDescargaCompletadoName]: buildImagesVectors(altaExplotacionFolder, gcMensajeDescargaCompletadoName) };
const gcMensajeDescargaExplotacionName = 'gc-mensaje-descarga-explotacion';
export const gcMensajeDescargaExplotacion = { [gcMensajeDescargaExplotacionName]: buildImagesVectors(altaExplotacionFolder, gcMensajeDescargaExplotacionName) };
const gcMensajeIntroduccionName = 'gc-mensaje-introduccion';
export const gcMensajeIntroduccion = { [gcMensajeIntroduccionName]: buildImagesVectors(altaExplotacionFolder, gcMensajeIntroduccionName) };
const gcMensajePedirDocumentoName = 'gc-mensaje-pedir-documento';
export const gcMensajePedirDocumento = { [gcMensajePedirDocumentoName]: buildImagesVectors(altaExplotacionFolder, gcMensajePedirDocumentoName) };

//farming
const farmingTracktorFolder = 'farming';
const gcFarmingTracktorName = 'gc-farming-tracktor';
const gcFarmingTracktorOutlineName = `${gcFarmingTracktorName}-outline`;
export const gcFarmingTracktorSolid = { [gcFarmingTracktorName]: buildGCPath(farmingTracktorFolder, gcFarmingTracktorName) };
export const gcFarmingTracktorOutline = { [gcFarmingTracktorOutlineName]: buildGCPath(farmingTracktorFolder, gcFarmingTracktorOutlineName) };

//mobile
const mobileFolder = 'mobile';
const gcMobilePhoneOutlineName = 'gc-mobile-phone-outline';
export const gcMobilePhoneOutline = { [gcMobilePhoneOutlineName]: buildGCPath(mobileFolder, gcMobilePhoneOutlineName) };

//telephone
const telephoneFolder = 'telephone';
const gcTelephoneOutlineName = 'gc-telephone-outline';
export const gcTelephoneOutline = { [gcTelephoneOutlineName]: buildGCPath(telephoneFolder, gcTelephoneOutlineName) };

// Interface
const gcInterfaceOptionsVertical = 'gc-interface-options-vertical';
export const gcInterfaceOptionsVerticalSolid = { [gcInterfaceOptionsVertical]: buildGCPath(interfaceFolder, gcInterfaceOptionsVertical) };

export const globalcampoIconos: IGlobalcampoIcons = {

    addFolder: {
        solid: {
            name: gcAddFolderName,
            iconData: gcAddFolderSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addMap: {
        solid: {
            name: gcAddMapName,
            iconData: gcAddMapSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addUser: {
        solid: {
            name: gcAddUserName,
            iconData: gcAddUserSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    addTracktor: {
        solid: {
            name: gcAddTracktorName,
            iconData: gcAddTracktorSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    adNews: {
        solid: {
            name: gcAdNewsName,
            iconData: gcAdNewsSolid,
        },
        outline: {
            name: gcAdNewsOutlineName,
            iconData: gcAdNewsOutline,
        },
    },
    agriculturePlant: {
        solid: {
            name: gcAgriPlantName,
            iconData: gcAgriPlantSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    agricultureLeaf: {
        solid: {
            name: gcAgriLeafName,
            iconData: gcAgriLeafSolid,
        },
        outline: {
            name: gcAgriLeafOutlineName,
            iconData: gcAgriLeafOutline,
        },
    },
    agricultureSeedFito: {
        solid: {
            name: gcAgriSeedFitoName,
            iconData: gcAgriSeedFitoSolid,
        },
        outline: {
            name: gcAgriSeedFitoOutlineName,
            iconData: gcAgriSeedFitoOutline,
        },
    },
    agricultureLeaf01: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcAgricultureLeaf01OutlineName,
            iconData: gcAgricultureLeaf01Outline,
        },
    },
    arrowFlatDown: {
        solid: {
            name: gcArrowFlatDownName,
            iconData: gcArrowFlatDownSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowOut: {
        solid: {
            name: gcArrowOutName,
            iconData: gcArrowOutSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowDouble: {
        solid: {
            name: gcArrowDoubleName,
            iconData: gcArrowDoubleSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowBackward: {
        solid: {
            name: gcArrowBackwardName,
            iconData: gcArrowBackwardSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowForward: {
        solid: {
            name: gcArrowForwardName,
            iconData: gcArrowForwardSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowLeft: {
        solid: {
            name: gcArrowLeftName,
            iconData: gcArrowLeftSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowRight: {
        solid: {
            name: gcArrowRightName,
            iconData: gcArrowRightSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowDown: {
        solid: {
            name: gcArrowDownName,
            iconData: gcArrowDownSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowUp: {
        solid: {
            name: gcArrowUpName,
            iconData: gcArrowUpSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowFlatRight: {
        solid: {
            name: gcArrowFlatRightName,
            iconData: gcArrowFlatRightSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowExit: {
        solid: {
            name: gcArrowExitName,
            iconData: gcArrowExitSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    arrowRight05: {
        solid: {
            name: gcArrowRight05Name,
            iconData: gcArrowRight05Solid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    businesIdName: {
        solid: {
            name: gcBusinessIdName,
            iconData: gcBusinessIdNameSolid,
        },
        outline: {
            name: gcBusinessIdOutlineName,
            iconData: gcBusinessIdNameOutline,
        },
    },
    businesIdNumber: {
        solid: {
            name: gcBusinessIdNumberName,
            iconData: gcBusinessIdNumberSolid,
        },
        outline: {
            name: gcBusinessIdNumberOutlineName,
            iconData: gcBusinessIdNumberOutline,
        },
    },
    businessFolder: {
        solid: {
            name: gcBusinessFolderName,
            iconData: gcBusinessFolderSolid,
        },
        outline: {
            name: gcBusinessFolderOutlineName,
            iconData: gcBusinessFolderOutline,
        },
    },
    networkFolder: {
        solid: {
            name: gcBusinessNetworkName,
            iconData: gcBusinessNetworkSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    businessClipboard: {
        solid: {
            name: gcBusinessClipboardName,
            iconData: gcBusinessClipboardSolid,
        },
        outline: {
            name: gcBusinessClipboardOutlineName,
            iconData: gcBusinessClipboardOutline,
        },
    },
    businessTray: {
        solid: {
            name: gcBusinessTrayName,
            iconData: gcBusinessTraySolid,
        },
        outline: {
            name: gcBusinessTrayOutlineName,
            iconData: gcBusinessTrayOutline,
        },
    },
    businessBarChart: {
        solid: {
            name: gcBusinessBarChartName,
            iconData: gcBusinessBarChartSolid,
        },
        outline: {
            name: gcBusinessBarChartOutlineName,
            iconData: gcBusinessBarChartOutline,
        },
    },
    chatComment: {
        solid: {
            name: gcChatCommentName,
            iconData: gcChatCommentSolid,
        },
        outline: {
            name: gcChatCommentOutlineName,
            iconData: gcChatCommentOutline,
        },
    },
    chatEdit: {
        solid: {
            name: gcChatEditName,
            iconData: gcChatEditSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    gicoop: {
        solid: {
            name: gcGicoopName,
            iconData: gcGicoopSolid,
        },
        outline: {
            name: gcGicoopOutlineName,
            iconData: gcGicoopOutline,
        },
    },
    dateCalendar: {
        solid: {
            name: gcDateCalendarName,
            iconData: gcDateCalendarSolid,
        },
        outline: {
            name: gcDateCalendarOutlineName,
            iconData: gcDateCalendarOutline,
        },
    },
    devicePhone: {
        solid: {
            name: gcDevicePhoneName,
            iconData: gcDevicePhoneSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    emailSend: {
        solid: {
            name: gcEmailSendName,
            iconData: gcEmailSendSolid,
        },
        outline: {
            name: gcEmailSendOutlineName,
            iconData: gcEmailSendOutline,
        },
    },
    emailReaded: {
        solid: {
            name: gcEmailReadedName,
            iconData: gcEmailReadedSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    farmingFarmer: {
        solid: {
            name: gcFarmingFarmerName,
            iconData: gcFarmingFarmerSolid,
        },
        outline: {
            name: gcFarmingFarmerOutlineName,
            iconData: gcFarmingFarmerOutline,
        },
    },
    farmingBarn: {
        solid: {
            name: gcFarmingBarnName,
            iconData: gcFarmingBarnSolid,
        },
        outline: {
            name: gcFarmingBarnOutlineName,
            iconData: gcFarmingBarnOutline,
        },
    },
    farmingGrapes: {
        solid: {
            name: gcFarmingGrapesName,
            iconData: gcFarmingGrapesSolid,
        },
        outline: {
            name: gcFarmingGrapesOutlineName,
            iconData: gcFarmingGrapesOutline,
        },
    },
    farmingFill: {
        solid: {
            name: gcFarmingFillName,
            iconData: gcFarmingFillSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    filesPDF:{
        solid: {
            name: gcFilesPDFName,
            iconData: gcFilesPDFSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    filesDelete:{
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcFilesDeleteOutlineName,
            iconData: gcFilesDeleteOutline,
        },
    },
    filesWarn:{
        solid: {
            name: gcFilesWarnName,
            iconData: gcFilesWarnSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    filesFile:{
        solid: {
            name: gcFilesFileName,
            iconData: gcFilesFileSolid,
        },
        outline: {
            name: gcFilesFileOutlineName,
            iconData: gcFilesFileOutline,
        },
    },
    filesFile01:{
        solid: {
            name: gcFilesFile01Name,
            iconData: gcFilesFile01Solid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    folderError: {
        solid: {
            name: gcFolderErrorName,
            iconData: gcFolderErrorSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    folderWarn: {
        solid: {
            name: gcFolderWarnName,
            iconData: gcFolderWarnSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    folderInfo: {
        solid: {
            name: gcFolderInfoName,
            iconData: gcFolderInfoSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    folderSuccess: {
        solid: {
            name: gcFolderSuccessName,
            iconData: gcFolderSuccessSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    map: {
        solid: {
            name: gcMapName,
            iconData: gcMapSolid,
        },
        outline: {
            name: gcMapOutlineName,
            iconData: gcMapOutline,
        },
    },
    menu : {
        solid: {
            name: gcFilesLock,
            iconData: gcFilesLockSolid,
        },
        outline: {
            name: gcFilesLockOutlineName,
            iconData: gcFilesLockOutline,
        },
    },
    shapeStar: {
        solid: {
            name: gcStarName,
            iconData: gcStarSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeGrid: {
        solid: {
            name: gcShapeGridName,
            iconData: gcShapeGridSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeSmallCircle: {
        solid: {
            name: gcShapeSmallCircleName,
            iconData: gcShapeSmallCircleSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeArrowUp: {
        solid: {
            name: gcShapeArrowUpName,
            iconData: gcShapeArrowUpSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeArrowDown: {
        solid: {
            name: gcShapeArrowDownName,
            iconData: gcShapeArrowDownSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeNoChanges: {
        solid: {
            name: gcShapeNoChangesName,
            iconData: gcShapeNoChangesSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    shapeLocation: {
        solid: {
            name: gcLocationName,
            iconData: gcLocationSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
   listLocation: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gclistName,
            iconData: gcListOutline,
        },
    },
    userProfile: {
        solid: {
            name: gcUserName,
            iconData: gcUserSolid,
        },
        outline: {
            name: gcUserOutlineName,
            iconData: gcUserOutline,
        },
    },
    userMenu: {
        solid: {
            name: gcUserMenuName,
            iconData: gcUserMenuSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userNotifOFF: {
        solid: {
            name: gcUserNotifOFFName,
            iconData: gcUserNotifOFFSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userSettings: {
        solid: {
            name: gcUserSettingsName,
            iconData: gcUserSettingsSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userPlus: {
        solid: {
            name: gcUserPlusName,
            iconData: gcUserPlusSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userRefresh: {
        solid: {
            name: gcUserRefreshName,
            iconData: gcUserRefreshSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userHome: {
        solid: {
            name: gcUserHomeName,
            iconData: gcUserHomeSolid,
        },
        outline: {
            name: gcUserHomeOutlineName,
            iconData: gcUserHomeOutline,
        },
    },
    userMultiple: {
        solid: {
            name: gcUserMultipleName,
            iconData: gcUserMultipleSolid,
        },
        outline: {
            name: gcUserMultipleOutlineName,
            iconData: gcUserMultipleOutline,
        },
    },
    userLock: {
        solid: {
            name: gcInputLockSolidName,
            iconData: gcInputLockSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userCross: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcUserCrossName,
            iconData: gcUserCrossSolid,
        },
    },
    userDuplicate: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcUserDuplicateOutlineName,
            iconData: gcUserDuplicateOutline,
        },
    },
    userFilter: {
        solid: {
            name: gcUserFilterName,
            iconData: gcUserFilterSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userLayers: {
        solid: {
            name: gcUserLayersName,
            iconData: gcUserLayersSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userLocation: {
        solid: {
            name: gcUserLocationName,
            iconData: gcUserLocationSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userOptions: {
        solid: {
            name: gcUserOptionsName,
            iconData: gcUserOptionsSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userEye: {
        solid: {
            name: gcUserEyeName,
            iconData: gcUserEyeSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userInfo: {
        solid: {
            name: gcUserInfoNamw,
            iconData: gcUserInfoSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userSearch: {
        solid: {
            name: gcUserSearchName,
            iconData: gcUserSearchSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userEyeHide: {
        solid: {
            name: gcUserEyeHideName,
            iconData: gcUserEyeHideSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    UserWarn: {
        solid: {
            name: gcUserWarn,
            iconData: gcUserWarnSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    user05: {
        solid: {
            name: gcUserUser05Name,
            iconData: gcUserUser05Solid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    weatherDrop: {
        solid: {
            name: gcWeatherDropName,
            iconData: gcWeatherDropSolid,
        },
        outline: {
            name: gcWeatherDropOutlineName,
            iconData: gcWeatherDropOutline,
        },
    },
    weatherRainDrop: {
        solid: {
            name: gcWeatherRainDropName,
            iconData: gcWeatherRainDropSolid,
        },
        outline: {
            name: gcWeatherRainDropOutlineName,
            iconData: gcWeatherRainDropOutline,
        },
    },
    weatherSun: {
        solid: {
            name: gcWeatherSunName,
            iconData: gcWeatherSunSolid,
        },
        outline: {
            name: gcWeatherSunOutlineName,
            iconData: gcWeatherSunOutline,
        },
    },
    weatherMoon: {
        solid: {
            name: gcWeatherMoonName,
            iconData: gcWeatherMoonSolid,
        },
        outline: {
            name: gcWeatherMoonOutlineName,
            iconData: gcWeatherMoonOutline,
        },
    },
    cosecha: {
        solid: {
            name: gcCosechaName,
            iconData: gcCosechaSolid,
        },
        outline: {
            name: gcCosechaOutlineName,
            iconData: gcCosechaOutline,
        },
    },
    fitosanitario: {
        solid: {
            name: gcFitosanitarioName,
            iconData: gcFitosanitarioSolid,
        },
        outline: {
            name: gcFitosanitarioOutlineName,
            iconData: gcFitosanitarioOutline,
        },
    },
    edificaciones: {
        solid: {
            name: gcEdificacionesName,
            iconData: gcEdificacionesSolid,
        },
        outline: {
            name: gcEdificacionesOutlineName,
            iconData: gcEdificacionesOutline,
        },
    },
    usoSemillaTratada: {
        solid: {
            name: gcUsoSemillaTratadaName,
            iconData: gcUsoSemillaTratadaSolid,
        },
        outline: {
            name: gcUsoSemillaTratadaOutlineName,
            iconData: gcUsoSemillaTratadaOutline,
        },
    },
    postCosecha: {
        solid: {
            name: gcPostCosechaName,
            iconData: gcPostCosechaSolid,
        },
        outline: {
            name: gcPostCosechaOutlineName,
            iconData: gcPostCosechaOutline,
        },
    },
    fertilizacion: {
        solid: {
            name: gcFertilizacionName,
            iconData: gcFertilizacionSolid,
        },
        outline: {
            name: gcFertilizacionOutlineName,
            iconData: gcFertilizacionOutline,
        },
    },
    analitica: {
        solid: {
            name: gcAnaliticaName,
            iconData: gcAnaliticaSolid,
        },
        outline: {
            name: gcAnaliticaOutlineName,
            iconData: gcAnaliticaOutline,
        },
    },
    siembraPlantacion: {
        solid: {
            name: gcSiembraPlantacionName,
            iconData: gcSiembraPlantacionSolid,
        },
        outline: {
            name: gcSiembraPlantacionOutlineName,
            iconData: gcSiembraPlantacionOutline,
        },
    },
    comercializacionVenta: {
        solid: {
            name: gcComercializacionVentaName,
            iconData: gcComcercializacionVentaSolid,
        },
        outline: {
            name: gcComcercializacionVentaOutlineName,
            iconData: gcComcercializacionVentaOutline,
        },
    },
    datosCubierta: {
        solid: {
            name: gcDatosCubiertaName,
            iconData: gcDatosCubiertaSolid,
        },
        outline: {
            name: gcDatosCubiertaOutlineName,
            iconData: gcDatosCubiertaOutline,
        },
    },
    energiaUtilizada: {
        solid: {
            name: gcEnergiaUtilizadaName,
            iconData: gcEnergiaUtilizadaSolid,
        },
        outline: {
            name: gcEnergiaUtilizadaOutlineName,
            iconData: gcEnergiaUtilizadaOutline,
        },
    },
    pastoreo: {
        solid: {
            name: gcPastoreoName,
            iconData: gcPastoreoSolid,
        },
        outline: {
            name: gcPastoreoOutlineName,
            iconData: gcPastoreoOutline,
        },
    },
    actividadAgraria: {
        solid: {
            name: gcActividadAgrariaName,
            iconData: gcActividadAgrariaSolid,
        },
        outline: {
            name: gcActividadAgrariaOutlineName,
            iconData: gcActividadAgrariaOutline,
        },
    },
    planAbonado: {
        solid: {
            name: gcPlanAbonadoName,
            iconData: gcPlanAbonadoSolid,
        },
        outline: {
            name: gcPlanAbonadoOutlineName,
            iconData: gcPlanAbonadoOutline,
        },
    },
    idNumberSinColor: {
        solid: {
            name: gcIdNumberSinColor,
            iconData: gcIdNumberSinSolid,
        },
        outline: {
            name: ``,
            iconData: {},
        },
    },
    dashExplotacion: {
        solid: {
            name:gcDashExplotacionName,
            iconData: gcDashExplotacionsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    dashRepresentante: {
        solid: {
            name:gcDashRepresentanteName,
            iconData: gcDashRepresentantesolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    dashTitular: {
        solid: {
            name:gcDashTitularName,
            iconData: gcDashTitularsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalAccesible: {
        solid: {
            name:gcGlobalAccesibleName,
            iconData: gcGlobalAccesiblesolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalGicoop: {
        solid: {
            name:gcGlobalGicoopName,
            iconData: gcGlobalGicoopsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalFacil: {
        solid: {
            name:gcGlobalFacilName,
            iconData: gcGlobalFacilsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalWeather: {
        solid: {
            name:gcGlobalWeatherName,
            iconData: gcGlobalWeathersolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalShopping: {
        solid: {
            name:gcGlobalShoppingName,
            iconData: gcGlobalShoppingsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalGestion: {
        solid: {
            name:gcGlobalGestionName,
            iconData: gcGlobalGestionsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalPlatforms: {
        solid: {
            name:gcGlobalPlatformsName,
            iconData: gcGlobalPlatformssolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalWeatherapp: {
        solid: {
            name:gcGlobalWeatherappName,
            iconData: gcGlobalWeatherappsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalAhorro: {
        solid: {
            name:gcGlobalAhorroName,
            iconData: gcGlobalAhorrosolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNormativa: {
        solid: {
            name:gcGlobalNormativaName,
            iconData: gcGlobalNormativasolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalUsuario: {
        solid: {
            name:gcGlobalUsuarioName,
            iconData: gcGlobalUsuariosolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalCooperativa: {
        solid: {
            name:gcGlobalCooperativaName,
            iconData: gcGlobalCooperativasolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalYoutube: {
        solid: {
            name:gcGlobalYoutubeName,
            iconData: gcGlobalYoutubesolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalInstagram: {
        solid: {
            name:gcGlobalInstagramName,
            iconData: gcGlobalInstagramsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalTwitter: {
        solid: {
            name:gcGlobalTwitterName,
            iconData: gcGlobalTwittersolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNoactividades: {
        solid: {
            name:gcGlobalNoactividadesName,
            iconData: gcGlobalNoactividadessolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNoexplotaciones: {
        solid: {
            name:gcGlobalNoexplotacionesName,
            iconData: gcGlobalNoexplotacionessolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNotrabajadores: {
        solid: {
            name:gcGlobalNotrabajadoresName,
            iconData: gcGlobalNotrabajadoressolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNoresultados: {
        solid: {
            name:gcGlobalNoresultadosName,
            iconData: gcGlobalNoresultadossolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalNoNotification: {
        solid: {
            name:gcGlobalNoNotificationName,
            iconData: gcGlobalNoNotificationsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    businessTrophy: {
        solid: {
            name: gcBusinessTrophytName,
            iconData: gcBusinessTrophySolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userTick: {
        solid: {
            name: gcUserTick,
            iconData: gcUserTickSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajeCheckDatos: {
        solid: {
            name: gcMensajeCheckDatosName,
            iconData: gcMensajeCheckDatos,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajeDatosAdicionales: {
        solid: {
            name: gcMensajeDatosAdicionalesName,
            iconData: gcMensajeDatosAdicionales,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajeDescargaCompletado: {
        solid: {
            name: gcMensajeDescargaCompletadoName,
            iconData: gcMensajeDescargaCompletado,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajeDescargaExplotacion: {
        solid: {
            name: gcMensajeDescargaExplotacionName,
            iconData: gcMensajeDescargaExplotacion,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajeIntroduccion: {
        solid: {
            name: gcMensajeIntroduccionName,
            iconData: gcMensajeIntroduccion,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    mensajePedirDocumento: {
        solid: {
            name: gcMensajePedirDocumentoName,
            iconData: gcMensajePedirDocumento,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    farmingTracktor: {
        solid: {
            name: gcFarmingTracktorName,
            iconData: gcFarmingTracktorSolid,
        },
        outline: {
            name: gcFarmingTracktorOutlineName,
            iconData: gcFarmingTracktorOutline,
        },
    },
    filesProtection: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcFilesProtectionOutlineName,
            iconData: gcFilesProtectionOutline,
        },
    },
    email: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcEmailOutlineName,
            iconData: gcEmailOutline,
        },
    },
    mobile: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcMobilePhoneOutlineName,
            iconData: gcMobilePhoneOutline,
        },
    },
    telephone: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcTelephoneOutlineName,
            iconData: gcTelephoneOutline,
        },
    },
    userRuler: {
        solid: {
            name: gcUserRuler,
            iconData: gcUserRulerSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalLogOut: {
        solid: {
            name:gcGlobalLogOutName,
            iconData: gcGlobalLogOutsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    globalSign: {
        solid: {
            name:gcGlobalSignName,
            iconData: gcGlobalSignsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    construction: {
        solid: {
            name: gcGlobalConstructionName,
            iconData: gcGlobalConstructionsolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceError: {
        solid: {
            name: gcInterfaceErrorName,
            iconData: gcInterfaceErrorSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceMenu: {
        solid: {
            name: gcInterfaceMenuName,
            iconData: gcInterfaceMenuSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceInfo:{
        solid: {
            name: gcInterfaceInfoName,
            iconData: gcInterfaceInfoSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceWarn: {
        solid: {
            name: gcInterfaceWarnName,
            iconData: gcInterfaceWarnSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceSuccess: {
        solid: {
            name: gcInterfaceSuccessName,
            iconData: gcInterfaceSuccessSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceMinus: {
        solid: {
            name: gcInterfaceMinusName,
            iconData: gcInterfaceMinusSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfacePending: {
        solid: {
            name: gcInterfacePendingName,
            iconData: gcInterfacePendingSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    interfaceLayers: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcInterfaceLayersOutlineName,
            iconData: gcInterfaceLayersOutline,
        },
    },
    interfaceList: {
        solid: {
            name: gcInterfaceListName,
            iconData: gcInterfaceListSolid,
        },
        outline: {
            name: gcInterfaceListOutlineName,
            iconData: gcInterfaceListOutline,
        },
    },
    interfaceGrid: {
        solid: {
            name: gcInterfaceGridName,
            iconData: gcInterfaceGridSolid,
        },
        outline: {
            name: gcInterfaceGridOutlineName,
            iconData: gcInterfaceGridOutline,
        },
    },
    userBook: {
        solid: {
            name: '',
            iconData: {},
        },
        outline: {
            name: gcUserBookOutlineName,
            iconData: gcUserBookOutline,
        },
    },
    interfaceOptionsVertical: {
        solid: {
            name: gcInterfaceOptionsVertical,
            iconData: gcInterfaceOptionsVerticalSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
    userSquare: {
        solid: {
            name: gcUserSquare,
            iconData: gcUserSquareSolid,
        },
        outline: {
            name: '',
            iconData: {},
        },
    },
};

/**
 * Inicializa los iconos globalcampo al set de iconos de ionic
 */
export const initGlobalcampoIcons = () => {
    for (const iconNameKey in globalcampoIconos) {
        if (globalcampoIconos[iconNameKey]) {
            const iconValue = globalcampoIconos[iconNameKey];
            addIconTypes(iconValue);
        }

    }
};

/**
 * Añade los tipos de un icono al set de iconos de ionic
 * @param iconValue
 */
const addIconTypes = (iconValue: TIconType) => {
    for (const iconTypeKey in iconValue) {
        if (iconValue[iconTypeKey]) {
            const iconType = iconValue[iconTypeKey];
            addIcons(iconType.iconData);
        }
    }
};

/**
 * Añade los iconos indicados al set de iconos de ionic
 * @param icons
 */
export const addCustomIcons = (icons: TGlobalcampoIcon[]): void => {
    icons.forEach((icon) => {
        addIcons(icon);
    });
};
