import { IActividadCCA } from '../interfaces/actividad-cca.interface';
import { urls } from './urls';
import { IActividadAgrariaFilter } from '../interfaces/cca/actividades/agraria/actividad-agraria-filter.interface';
import { globalcampoIconos } from './globalcampo-icons.config';

export const actividadesCCACodLineas = {
    POST_COSECHA: '90',
    EDIFICACIONES_INSTALACIONES: '91',
    SIEMBRA_PLANTACION: '92',
    COSECHA: '93',
    FITOSANITARIO: '94',
    FERTILIZACION: '95',
    ENERGIA_UTILIZADA: '96',
    COMERCIALIZACION: '97',
    ANALITICA: '98',
    PASTOREO: '99',
    DATOS_CUBIERTA: '100',
    SEMILLA_TRATADA: '101',
    ACTIVIDAD_AGRARIA: '102',
    PLAN_ABONADO: '103',
}

/** Registro de iconos por codigo de actividad */
export const IconoAct = {
    '90': globalcampoIconos.postCosecha.solid.name,
    '91': globalcampoIconos.edificaciones.solid.name,
    '92': globalcampoIconos.siembraPlantacion.solid.name,
    '93': globalcampoIconos.cosecha.solid.name,
    '94': globalcampoIconos.fitosanitario.solid.name,
    '95': globalcampoIconos.fertilizacion.solid.name,
    '96': globalcampoIconos.energiaUtilizada.solid.name,
    '97': globalcampoIconos.comercializacionVenta.solid.name,
    '98': globalcampoIconos.analitica.solid.name,
    '99': globalcampoIconos.pastoreo.solid.name,
    '100': globalcampoIconos.datosCubierta.solid.name,
    '101': globalcampoIconos.usoSemillaTratada.solid.name,
    '102': globalcampoIconos.actividadAgraria.solid.name,
    '103': globalcampoIconos.planAbonado.solid.name,
};

/** Registro de iconos outline por codigo de actividad */
export const IconoActOutline = {
    '90': globalcampoIconos.postCosecha.outline.name,
    '91': globalcampoIconos.edificaciones.outline.name,
    '92': globalcampoIconos.siembraPlantacion.outline.name,
    '93': globalcampoIconos.cosecha.outline.name,
    '94': globalcampoIconos.fitosanitario.outline.name,
    '95': globalcampoIconos.fertilizacion.outline.name,
    '96': globalcampoIconos.energiaUtilizada.outline.name,
    '97': globalcampoIconos.comercializacionVenta.outline.name,
    '98': globalcampoIconos.analitica.outline.name,
    '99': globalcampoIconos.pastoreo.outline.name,
    '100': globalcampoIconos.datosCubierta.outline.name,
    '101': globalcampoIconos.usoSemillaTratada.outline.name,
    '102': globalcampoIconos.actividadAgraria.outline.name,
    '103': globalcampoIconos.planAbonado.outline.name,
};

/** Regsitramos los valores obtenidos por el servicio ya que las actividades, aunque se ampliaran,
 *  tendrían que tener su correspondiente implementación de funcionalidad,
 *  por lo tanto, dejándolas en este archivo evitamos las llamadas al servicio */
const textDesTipo = 'Actividades Explotación';
export const activadesCCA: IActividadCCA[] =[
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '90',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.POST_COSECHA',
        'desTipo': textDesTipo,
        'observaciones': 'TratamientosPostCosecha CUE',
        'lineas': null,
        icon: IconoAct['90'],
        iconOutline: IconoActOutline['90'],
        ordenPrioridad: 10,
        principal: true,
        link: urls.cca.actividades_post_cosecha,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '91',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.EDIFICACIONES_INSTALACIONES',
        'desTipo': textDesTipo,
        'observaciones': 'TratamientosEdifInstalaciones CUE',
        'lineas': null,
        icon: IconoAct['91'],
        iconOutline: IconoActOutline['91'],
        ordenPrioridad: 2,
        principal: true,
        link: urls.cca.actividades_tratamiento_edificacion,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '92',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.SIEMBRA_PLANTACION',
        'desTipo': textDesTipo,
        'observaciones': 'SiembraPlantacion CUE',
        'lineas': null,
        icon: IconoAct['92'],
        iconOutline: IconoActOutline['92'],
        ordenPrioridad: 6,
        link: urls.cca.actividades_siembra_plantacion,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '93',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.COSECHA',
        'desTipo': textDesTipo,
        'observaciones': 'Cosecha CUE',
        'lineas': null,
        icon: IconoAct['93'],
        iconOutline: IconoActOutline['93'],
        ordenPrioridad: 5,
        link: urls.cca.actividades_cosecha,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '94',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.FITOSANITARIO',
        'desTipo': textDesTipo,
        'observaciones': 'TratamFito CUE',
        'lineas': null,
        icon: IconoAct['94'],
        iconOutline: IconoActOutline['94'],
        ordenPrioridad: 1,
        principal: true,
        link: urls.cca.actividades_fitosanitario,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '95',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.FERTILIZACION',
        'desTipo': textDesTipo,
        'observaciones': 'Fertilizacion CUE',
        'lineas': null,
        icon: IconoAct['95'],
        iconOutline: IconoActOutline['95'],
        ordenPrioridad: 3,
        link: urls.cca.actividades_fertilizacion,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '96',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.ENERGIA_UTILIZADA',
        'desTipo': textDesTipo,
        'observaciones': 'Energía utilizada',
        'lineas': null,
        icon: IconoAct['96'],
        iconOutline: IconoActOutline['96'],
        ordenPrioridad: 11,
        link: urls.cca.actividades_energia_utilizada,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '97',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.COMERCIALIZACION',
        'desTipo': textDesTipo,
        'observaciones': 'ComercializacionVD CUE',
        'lineas': null,
        icon: IconoAct['97'],
        iconOutline: IconoActOutline['97'],
        ordenPrioridad: 7,
        link: urls.cca.actividades_comercializacion_venta_directa,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '98',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.ANALITICA',
        'desTipo': textDesTipo,
        'observaciones': 'Analitica CUE',
        'lineas': null,
        icon: IconoAct['98'],
        iconOutline: IconoActOutline['98'],
        ordenPrioridad: 4,
        link: urls.cca.actividades_analitica,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '99',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.PASTOREO',
        'desTipo': textDesTipo,
        'observaciones': 'Pastoreo CUE',
        'lineas': null,
        icon: IconoAct['99'],
        iconOutline: IconoActOutline['99'],
        ordenPrioridad: 12,
        link: urls.cca.actividades_pastoreo,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '100',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.DATOS_CUBIERTA',
        'desTipo': textDesTipo,
        'observaciones': 'DatosCubierta CUE',
        'lineas': null,
        icon: IconoAct['100'],
        iconOutline: IconoActOutline['100'],
        ordenPrioridad: 8,
        link: urls.cca.actividades_datos_cubierta,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '101',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.USO_SEMILLA',
        'desTipo': textDesTipo,
        'observaciones': 'UsoSemillaTratada CUE',
        'lineas': null,
        icon: IconoAct['101'],
        iconOutline: IconoActOutline['101'],
        ordenPrioridad: 9,
        principal: true,
        link: urls.cca.actividades_semilla_tratada,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '102',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.AGRARIA',
        'desTipo': textDesTipo,
        'observaciones': 'Actividad Agraria CUE',
        'lineas': null,
        icon: IconoAct['102'],
        iconOutline: IconoActOutline['102'],
        ordenPrioridad: 13,
        link: urls.cca.actividades_actividad_agraria,
    },
    {
        'codTabla': 'ACTIVIDAD_AGRARIA',
        'codLinea': '103',
        'tipo': '10',
        'descripcion': 'CCA.CUADERNO_DIGITAL.ACTIVIDADES.PLAN_ABONADO',
        'desTipo': textDesTipo,
        'observaciones': 'PlanAbonado CUE',
        'lineas': null,
        icon: IconoAct['103'],
        iconOutline: IconoActOutline['103'],
        ordenPrioridad: 14,
        link: urls.cca.actividades_plan_abonado,
    },
];

export const filtroActCCA: IActividadAgrariaFilter = {
    idExplotacion: null,
    tipoActividad: [],
    auxEstadoActividad: null,
    auxEstadoBorrador:null,
    fchInicioHasta: null,
    fchInicioDesde: null,
    fchFinDesde: null,
    fchFinHasta: null,
    codProducto:null,
    auxEstadoAsesoramiento: null,
};
