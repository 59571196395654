import { NavigationClient } from '@azure/msal-browser';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { Inject } from '@angular/core';
import { LOGGER_PROVIDER } from '../globalcampo/services/logger/logger-provider.token';
import { ILoggerProvider } from '../globalcampo/services/logger/logger-provider.interface';

export class CustomNavigationClient extends NavigationClient {

  private readonly capacitorServer = 'capacitor://localhost/';
  private readonly androidServer = 'https://localhost/';

    constructor(private readonly iab: InAppBrowser, @Inject(LOGGER_PROVIDER) private readonly logger: ILoggerProvider) {
      super();
    }

    override async navigateExternal(url: string, options: {noHistory: boolean}) {
      if (Capacitor.isNativePlatform()) {
        const browser = this.iab.create(url, '_blank', {
          location: 'no',
          clearcache: 'yes',
          clearsessioncache: 'yes',
          hidenavigationbuttons: 'yes',
          hideurlbar: 'yes',
          fullscreen: 'yes',
          closebuttoncaption: 'Cerrar',
          footer: 'no',

        });
        browser.on('loadstart').subscribe((event) => {
          const domainSplitter = '#state';
          if (event.url.includes(domainSplitter)) {
            // Close the in app browser and redirect to localhost + the state parameter
            browser.close();
            const domain = event.url.split('#')[0];
            const replaceChain = Capacitor.getPlatform() === 'ios' ? this.capacitorServer : this.androidServer;
            const urlReplaced = event.url.replace(domain, replaceChain );
            this.logger.log('will redirect to:', urlReplaced);
            window.location.href = urlReplaced;
          }
          if (event.url.includes('logout')) {
            const home = Capacitor.getPlatform() === 'ios' ? this.capacitorServer : this.androidServer;
            window.location.href = home;
            browser.close();
          }
        });
      } else if (options.noHistory) {
          window.location.replace(url);
        } else {
          window.location.assign(url);
        }
      return true;
    }
}

