import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { explotacionesEndPoints } from '../../enpoints/cuadernos.endpoints.v1';
import { reaEndPoints } from '../../enpoints/rea.endpoint.v1';
import { HeaderLogConfig } from '../../config/header-log.config';
import { IComprobarSega } from '../../interfaces/comprobar-sega.interface';
import { IExplotacionDTOFega } from '../../interfaces/explotacionDTO-Fega.interface';
import { IEstadoExplotacion } from '../../interfaces/explotaciones/estado-explotacion.interface';

@Injectable({
  providedIn: 'root',
})
export class ReaService {

  constructor(
    private readonly http: HttpClient,
  ) { }


  /**
   * Determina si existe una explotación ya registrada en Globalcampo con el DNI o CIF
   * @param codIdentific
   */
  comprobarExplotacion(): Observable<IEstadoExplotacion[]> {
      return this.http.get<IEstadoExplotacion[]>(reaEndPoints.comprobarExplotacion());
  }

  getExisteNif(nif: string): Observable<IExplotacionDTOFega[]> {    
    return this.http.get<IExplotacionDTOFega[]>(explotacionesEndPoints.getExisteNif(nif), HeaderLogConfig.getMonitoredWithoutEmptyContent());
  }

  getComprobarSga(codIdentific: string, codComunidadAutonoma: string): Observable<IComprobarSega> {
    return this.http.get<IComprobarSega>(reaEndPoints.getComprobarSga(codIdentific, codComunidadAutonoma));
  }

  public getExportarInfoDgc(codIdentific: string): Observable<void> {
    return this.http.get<void>(reaEndPoints.exportarInfoDgc(codIdentific));
  }

  public getExportarMiniaturasDGC(codIdentific: string): Observable<void> {
    return this.http.get<void>(reaEndPoints.exportarMiniaturasDGC(codIdentific));
  }

  public getExportarMaquinaria(codIdentific: string): Observable<void> {
    return this.http.get<void>(reaEndPoints.exportarMaquinaria(codIdentific));
  }

  public cargarRea(codIdentific: string, tokenSga: string): Observable<void> {
    return this.http.post<void>(reaEndPoints.cargarRea(codIdentific), {
      tokenComprobarsga: tokenSga,
    });
  }

  /**
   * Descarga del REA
   * @param codIdentific
   */
  public exportarRea(codIdentific: string): Observable<void> {
    return this.http.get<void>(reaEndPoints.exportarRea(codIdentific));
  }

  public getAsignacionTecnico(codIdentific: string): Observable<void> {
    return this.http.get<void>(reaEndPoints.asignacionTecnico(codIdentific));
  }
}
