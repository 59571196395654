import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AnimationController, IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { map, Subject } from 'rxjs';
import { menuGCType, menuMAIN } from 'src/app/globalcampo/config/menus-app.config';
import { StorageKeys } from 'src/app/globalcampo/config/storage-keys.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { GlobalcampoService } from 'src/app/globalcampo/services/cuadernos-de-campo/globalcampo.service';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { IStorageProvider } from 'src/app/globalcampo/services/storage/storage-provider.interface';
import { STORAGE_PROVIDER } from 'src/app/globalcampo/services/storage/storage-provider.token';
import { MenuMainDropdownComponent } from '../menu-main-dropdown/menu-main-dropdown.component';

@Component({
  selector: 'gc-menu-main',
  templateUrl: './menu-main.component.html',
  styleUrls: ['./menu-main.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MenuMainComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly destroyRef = inject(DestroyRef);
  private readonly _altaExplotacionKey: string = StorageKeys.ALTA_EXPLOTACION.name;

  /**
 * Representa los nav links del menu
 */
  private _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
 * Representa los links del dropdown
 */
  private readonly _dropdownLinks:IMenu[] = this.manageDropdownLinks();
  public get dropdownLinks(): IMenu[] {
    return this._dropdownLinks;
  }

  /**
   * La altura del header principal.
   */
  private _headerHeight: number = 0;

  /**
   * Indica si se muestra o no el dropdown menu
   */
  private _showDropdown: boolean = false;
  public get showDropdown(): boolean {
    return this._showDropdown;
  }


    /**
   * Indica si se muestra o no el footer del dropdown menu
   */
  private _showDropdownFooter: boolean = false;
  public get showDropdownFooter(): boolean {
    return this._showDropdownFooter;
  }


  private readonly $unsubs: Subject<void> = new Subject<void>();

  constructor(
    private readonly _loginService: LoginService,
    private readonly _router: Router,
    @Inject(STORAGE_PROVIDER) private readonly _storage: IStorageProvider,
    private readonly _globalcampoService: GlobalcampoService,
    private readonly _animationCtrl: AnimationController,
    private readonly _modalCtrl: ModalController,
  ) { }

  ngOnInit(): void {
    /**
   * Comprueba si el usuario está logeado
   */
    this._loginService.isLoggedIn$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((user) => {
        this._showDropdownFooter = !user;
      }),
    ).subscribe();

}

  /**
   * `ResizeObserver` para monitorear los cambios en el tamaño del elemento de encabezado y actualiza la
   * propiedad  `--gc-size-header-main` con la nueva altura
   */
  ngAfterViewInit(): void {
    const headerElement = document.getElementById('gc-header-main') as HTMLElement;
    const headerResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const root = document.documentElement;
        this._headerHeight = entry.borderBoxSize[0].blockSize;
        root.style.setProperty('--gc-size-header-main', `${this._headerHeight}px`);
      }
    });
    if (headerElement) {
      headerResizeObserver.observe(headerElement);
    }
  }

  /**
   * Destruye la suscripción a los observables.
   */
  ngOnDestroy(): void {
    this.$unsubs.next();
    this.$unsubs.complete();
  }

    /**
   * Recupera un array de elementos de menú de navegación.
   *
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageNavLinks(ccaRoute: string = 'cca'): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const inicio = menu['inicio'];
      const cca = menu[ccaRoute];
      const lonjas = menu['lonjas'];
      const globalclima = menu['globalclima'];
      return [inicio, cca, lonjas, globalclima];
    }

    /**
   * Recupera un array de elementos de menú de navegación del dropdown.
   *
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageDropdownLinks(): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const gicoop = menu['gicoop'];
      const noticias = menu['noticias'];
      const pac = menu['pac'];
      const sar = menu['sar'];
      return [gicoop, noticias, pac, sar];
    }

    /**
     * Navega a la URL especificada.
     *
     * @param menu - El objeto de menú que contiene la URL a la que navegar.
     * @param event - El evento que desencadena la navegación.
     * @returns Una Promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu: IMenu, event?: Event) {
      event?.preventDefault();
      const link: string = menu.link ?? '';

      await this._router.navigate([link]);
    }

    /**
     * Determina si la URL dada está activa en función de la URL actual del enrutador.
     *
     * @param url - La URL a verificar si está activa.
     * @returns `true` si la URL está activa, `false` en caso contrario.
     */
    public linkActive(url: string): boolean {
      const urlPrefix = url?.split('/')[1];
      return this._router.url.includes(urlPrefix);
    }

    /**
     * Abre el menú desplegable y rota el icono de flecha del usuario.
     */
    public toggleDropdown() {
      this._showDropdown = !this._showDropdown;
      this.btnIconRotation();

      if (this._showDropdown) {
        this.openDropdown();
      } else {
        this._modalCtrl.dismiss();
      }
    }

    /**
     * Abre un modal dropdown con otros servicios.
     */
    async openDropdown(): Promise<void> {
      const dropdown = await this._modalCtrl.create({
        component: MenuMainDropdownComponent,
        canDismiss: true,
        id: 'gc-main-menu-dropdown',
        cssClass: 'ion-hide-md-down',
        componentProps: {
          dropdownLinks: this.dropdownLinks,
          showDropdownFooter: this.showDropdownFooter,
        },
        showBackdrop: false,
        backdropDismiss: true,
        enterAnimation: this.enterAnimation,
        leaveAnimation: this.leaveAnimation,
      });

      dropdown.present();

      this.clickedOutsideDropdown(dropdown);
      this.navigationTriggered(dropdown);

      dropdown.onWillDismiss().then(() => {
        this._showDropdown = false;
        this.btnIconRotation();
      });
    }

    /**
     * Rota el icono del botón basado en el estado del dropdown.
     */
    private btnIconRotation() {
      const btnArrow = document.getElementById('gc-btn-icon') as HTMLElement;
      btnArrow.style.transform = this._showDropdown ? 'rotate(180deg)' : 'rotate(0deg)';
    }

  /**
   * Crea y devuelve una animación para la entrada de un modal.
   * Esta animación consta de dos partes:
   * 1. Una animación de fondo que desvanece el elemento de fondo.
   * 2. Una animación del contenedor que desliza el elemento del contenedor del modal de derecha a izquierda.
   * @param baseEl - El elemento HTML base del modal.
   * @returns La animación creada o null si no se encuentra la raíz de sombra.
   */
    private readonly enterAnimation = (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;
      const duration: number = 300;
      if (!root) {
        return this._animationCtrl.create();
      }

      const backdropAnimation = this._animationCtrl
        .create()
        .addElement(root.querySelector('ion-backdrop') as HTMLElement)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = this._animationCtrl
        .create()
        .addElement(root.querySelector('.modal-wrapper') as HTMLElement)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'translateY(-100%)' },
          { offset: 1, opacity: '1', transform: `translateY(0)` },
        ]);

      return this._animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-in-out')
        .duration(duration)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    /**
     * Crea una animación de salida para el elemento base dado invirtiendo la animación de entrada.
     * @param baseEl - El elemento HTML base al que se aplicará la animación de salida.
     * @returns La animación de entrada invertida si existe, de lo contrario, null.
     */
    private readonly leaveAnimation = (baseEl: HTMLElement) => {
      const animation = this.enterAnimation(baseEl);
      return animation ? animation.direction('reverse') : this._animationCtrl.create();
    };

    /**
     * Cierra el modal dropdown si ocurre un clic fuera de él.
     * @param dropdown - El HTMLIonModalElement que representa el modal del dropdown.
     */
    private clickedOutsideDropdown(dropdown: HTMLIonModalElement) {
      document.addEventListener('click', (event) => {
        if (dropdown && !dropdown.contains(event.target as Node)) {
          dropdown.dismiss();
        }
      });
    }

    /**
     * Cierra el modal de dropdown cuando la navegación termina.
     * @param dropdown - El HTMLIonModalElement que representa el modal del dropdown a cerrar.
     */
    private navigationTriggered(dropdown: HTMLIonModalElement) {
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          dropdown.dismiss();
        }
      });
    }

    /**
     * Actualiza las rutas del menú principal según la ruta proporcionada.
     *
     * @param ccaRoute - La ruta con la que actualizar el menú principal.
     */
    changeMainMenuRoutes(ccaRoute: string) {
      this._navLinks = this.manageNavLinks(ccaRoute);
    }

}
