export const tecnicoRolIndex = 20;
export const agricultorRolIndex = 30;

export const userRoles = {
    agricultor: 'Agricultor',
    tecnico: 'Técnico',
    cooperativa: 'Cooperativa',
};
export const getUserRolByIndex = (rolIndex: number): string|undefined => {


    if(rolIndex < tecnicoRolIndex){
        /** Técnico Globalcaja */
        return userRoles.tecnico;
    }
    if(rolIndex === tecnicoRolIndex){
        /** Técnico Cooperativa */
        return userRoles.cooperativa;
    }
    if(rolIndex === agricultorRolIndex){
        /** Agricultor */
        return userRoles.agricultor;
    }

    return undefined;
}

/**
 * Determina si el rol es de tipo técnico
 * @param rolIndex
 */
export const isRolTecnico = (rolIndex: number): boolean => rolIndex === tecnicoRolIndex;

/**
 * Determina si el rol es de tipo agricultor
 * @param rolIndex
 */
export const isAgricultor = (rolIndex: number): boolean => rolIndex === agricultorRolIndex;
