import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const explotacionesEndPoints = {

    getDatosExplotacion: (codIdentific: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/${codIdentific}`,
    buscarExplotacion: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/filtro/`,

    /**
    *  Obtención de las instalaciones donde codIdentific está apoderado
    * @param codIdentific the identification number of the owner of the exploitation
    * */
    apoderadosCca: (codIdentific: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}globalcampo/${apiVx}/apoderadoscca/${codIdentific}`,

    /**
     * Get Rol index
     * @param codIdentific the identification number of the owner of the exploitation
     * */
    getRol:        (codIdentific: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/cuadernos/getRol/${codIdentific}`,

    /**
     * Get entidades relacionadas of an exploitation
     * @param idExploitation
     */
    getEntidadRelacionadByExplotacion: (idExploitation: string | null) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/${idExploitation}/entidadrelacionada`,

    /**
     * Get entidades relacionadas of an exploitation
     * @param idExploitation
     */
    getCotitularesByExplotacion: (idExploitation: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/cotitulares/${idExploitation}`,
    /**
     * Get the data of an exploitation
     * @param idExploitation the id of the exploitation
     *  */
    putDgcByExplotacion: (idExploitation: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/cuadernos/explotaciondgcbyexplotacion/${idExploitation}`,

    /**
     * Get the DGC of an exploitation
     * @param idExploitation the id of the exploitation
     *  */
    putDgcByidExplotacion: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/${idExplotacion}?&paginado=false`,


    /**
     * Get the thumbnail of an exploitation
     *
     *  */
    postThunbnail: () => `https://globalcampo.es/miniProxy.php?https://gz2-resources-java-dgc.azurewebsites.net/image?ancho=100`,
    /**
     * Get the thumbnail of an exploitation FALSE
     *
     *  */
    getThunbnail: (nsigpac: string) => `https://api.globalcampo.es/getMiniaturaSIGPACimg/2022/${nsigpac}/1`,


    /**
     * Obtención de la maquinaria de una explotación
     * @param idExplotacion
     * @returns string
     */
    putMaquinaria: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/maquinariasall/${idExplotacion}`,

    /**
     * Obtención de los trabajadores
     * @param idExplotacion
     * @returns string
     */
    putTrabajadores: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/trabajadoresall/${idExplotacion}`,

    /**
     * Obtención de productos vegetales por codCultivo
     * @param codCultivo
     * @returns string
     */
    getProductosVegetalesCultivo: (codCultivo: number) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/vegetales/${codCultivo}`,

    /**
     * Obtención de los asesores de una instalación
     * @param codInstalacion
     * @returns
     */
    getAsesores: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}instalaciones/${apiVx}/asesores`,


    getVegtalesExplotacion: (idExplotacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/dgc/vegetales/${idExplotacion}`,

    /**
     * Obtiene el listado de edificaciones asociadas a una explotación
     * @param id
     */
    getEdificacionesExplotacion: (id: string): string => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/edificacionesall/${id}`,


    /**
     * Para actualizar los parametros de una explotacion
     * @param id identificador de la explotacion
     * @returns
     */
    patchExplotacionInstalacion: (id: string): string => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/explotacion/explotacioninstalacion/${id}`,

}
export const cuadernosEndPoinst = {

    /**
     * Realiza la búsqueda de producto fitosanitario
     * @returns
     */
    putBusquedaProductoFitosanitario:() => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios`,

    /**
     * Obtiene el pdf de un producto fitosanitario
     * @param id El ID del producto fitosanitario
     * @returns
     */
    getArchivoPDFProductoFitosanitario: (id: string) =>`${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios/archivo/${id}`,


    putProductosFertilizantes:()=> `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fertilizantes`,
    putBusquedaProdFitoExcepciones: () => `${environment.apiURL}${ENDPOINTS_PREFIX.api}cca/${apiVx}/productos/fitosanitarios/excepciones`,
}
