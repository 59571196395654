<div class="modal-header">
  <h3 class="header-title">
    {{ 'MODAL.SELECTOR_INSTALACION.TITLE' | translate }}
  </h3>
</div>
<div class="modal-content">
  @if (instalaciones && (instalaciones || []).length > 0) {
  <div class="content-list">
    @for (el of instalaciones; track el; let i = $index) {
    <div class="list-item pointer" [ngClass]="{'odd': (i%2 === 0)}" (click)="selectInstalacion(el)"
      (keydown.enter)="selectInstalacion(el)">
      <div class="item-label">
        <span class="label-title">{{ el.nombreInstalacion | titlecase }}</span>
      </div>
      @if ((el.totalExplotaciones || 0) > 0) {
      <ion-icon class="item-icon" name="gc-arrow-flat-right"></ion-icon>
      }
    </div>
    }
  </div>
  }
</div>
<div class="modal-footer">
  <ion-button class="btn-m-accent-clear" (click)="cancel()" (keydown.enter)="cancel()">
    {{'GENERAL.CANCELAR' | translate}}
  </ion-button>
</div>