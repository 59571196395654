export const estadoAutorizacionSIEX = {
    NO_COMENZADO: {
        'codEstadoAutorizacion': 0,
        'desEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.HEADER.NO_COMENZADO',
        'aclaraEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.MENSAJE.N_C',
    },
    ENVIADA: {
        'codEstadoAutorizacion': 1,
        'desEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.HEADER.ENVIADA',
        'aclaraEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.MENSAJE.ENV',
    },
    DENEGADA: {
        'codEstadoAutorizacion': 2,
        'desEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.HEADER.DENEGADA',
        'aclaraEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.MENSAJE.DEN',
    },
    ACEPTADA: {
        'codEstadoAutorizacion': 3,
        'desEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.HEADER.ACEPTADA',
    },
    PENDIENTE: {
        'codEstadoAutorizacion': 4,
        'desEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.HEADER.PENDIENTE',
        'aclaraEstadoAutorizacion': 'CCA.EXPLOTACIONES.ESTADO_SIEX.MENSAJE.PEN',
    },
};
