import { Component, inject } from '@angular/core';
import { IonicModule, PopoverController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'gc-user-notifications-popover',
  templateUrl: './user-notifications-popover.component.html',
  styleUrls: ['./user-notifications-popover.component.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule],
})
export class UserNotificationsPopoverComponent {

  private readonly _unsubs$: Subject<void> = new Subject();

  private readonly _mdc = inject(PopoverController);
  constructor() { }

  async emitValue(value: string) {
    await this._mdc.dismiss({ accion: value }, undefined, 'popoverNotificacions');
  }

  ngOnDestroy() {
    this._unsubs$.next();
    this._unsubs$.complete();
  }

}
