<div id="gc-main-menu-content">
    @for(navLink of navLinks; track navLink) {
      <ion-button class="btn-s-accent-clear gc-content-btn" [ngClass]="{ 'active': linkActive(navLink.link ?? '')}"
        routerLink="{{navLink.link}}"
        (click)="navigationUrl(navLink)"
        (keydown.enter)="navigationUrl(navLink)" >
        {{ navLink.title | translate }}
      </ion-button>
    }
    <ion-button id="gc-content-btn" class="btn-s-accent-clear gc-content-btn" (click)="toggleDropdown()"  (keydown.enter)="toggleDropdown()">
      {{ 'MENU.MAIN.OTROS_SERVICIOS' | translate }}
      <ion-icon id="gc-btn-icon" slot="end" name="gc-arrow-flat-down"></ion-icon>
    </ion-button>
</div>

<div id="gc-main-menu-dropdown" class="ion-hide-md-down" #mainMenuDropdown>
  <div id="gc-dropdown-content" #dropdownContent [ngClass]="{ 'active': showDropdown}">
    <div class="gc-content-btn">
      @for(dropdownLink of dropdownLinks; track dropdownLink) {
        <a class="gc-btn-link">
          <ion-icon class="link-icon icon-outline" name="{{ dropdownLink.icon }}"></ion-icon>
          <ion-icon class="link-icon icon-solid" name="{{ dropdownLink.iconHover }}"></ion-icon>
          <div class="link-label">
            <span class="label-title"> {{ dropdownLink.title | translate }} </span>
            <span class="label-desc"> {{ dropdownLink.subTitle ?? '' | translate }} </span>
          </div>
        </a>
      }
    </div>
    @if(showDropdownFooter) {
    <div class="content-footer">
      <div class="footer-title"> {{ 'MENU.MAIN.DROPDOWN.FOOTER.TITLE' | translate }} </div>
      <ion-button class="btn-s-accent-clear "> {{ 'MENU.MAIN.DROPDOWN.FOOTER.BUTTON' | translate }} </ion-button>
    </div>
    }
  </div>
</div>

<div id="gc-main-menu-backdrop" #mainMenuBackdrop [ngClass]="{ 'active': showDropdown}"></div>
