import { CommonModule } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';
import { menuGCType, menuMAIN } from 'src/app/globalcampo/config/menus-app.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';

@Component({
  selector: 'gc-main-menu-mobile',
  templateUrl: './main-menu-mobile.component.html',
  styleUrls: ['./main-menu-mobile.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule],
})
export class MainMenuMobileComponent implements AfterViewInit {

  /**
  * Indica la ruta a la imagen del logo.
  */
  public logo: string = ASSETS_PATHS.logoGlobalcampo;

  /**
 * Indica los nav links del menu
 */
  private readonly _navLinks:IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
   * Indica si el menú se muestra actualmente.
   */
  private _showMenu: boolean = false;

  /**
   * Indican los elementos target que se van a utilizar para mostrar y ocultar el menú.
   */
  private readonly _idMainMenuBtn: string = 'gc-main-menu-btn';
  private readonly _idMainMenuContent: string = 'gc-main-menu-content';
  private readonly _idMenuContentHeader: string = 'gc-main-menu-content-header';
  private readonly _idMainMenuBackdrop: string = 'gc-main-menu-backdrop';
  private _mainMenuBtn: HTMLElement | null = document.getElementById(this._idMainMenuBtn);
  private _mainMenuContent: HTMLElement | null = document.getElementById(this._idMainMenuContent);
  private _menuContentHeader: HTMLElement | null = document.getElementById(this._idMenuContentHeader);
  private _mainMenuBackdrop: HTMLElement | null = document.getElementById(this._idMainMenuBackdrop);

  constructor( private readonly _router: Router ) {}

  ngAfterViewInit(): void {

    /**
     * Guradamos los elementos target una vez cargada la vista
     */
    this._mainMenuBtn = document.getElementById(this._idMainMenuBtn);
    this._mainMenuContent = document.getElementById(this._idMainMenuContent);
    this._menuContentHeader = document.getElementById(this._idMenuContentHeader);
    this._mainMenuBackdrop = document.getElementById(this._idMainMenuBackdrop);

    /**
     * Este método configura un listener para los eventos 'click' y 'touchmove' en la ventana
     * que verifica si el menú debe ocultarse según el lugar donde ocurrió el clic.
     */
    const handleEvent = (event: Event) => {
      if(this._showMenu) {
      const target = event.target as Node;
      const mainMenuBtnTarget = this._mainMenuBtn?.contains(target);
      const mainMenuContentTarget = this._mainMenuContent?.contains(target);
      const contentHeaderTarget = this._menuContentHeader?.contains(target);
      const navLinks = document.querySelectorAll('a.gc-nav-link');

      if(!mainMenuBtnTarget && !mainMenuContentTarget || contentHeaderTarget) {
        this.inactiveMenu();
        this._showMenu = false;
      }

      navLinks.forEach((navLink) => {
        if(navLink.contains(target)) {
        this.inactiveMenu();
        this._showMenu = false;
        }
      });
      }
    };
    window.addEventListener('click', handleEvent);
    window.addEventListener('touchmove', handleEvent);

    /**
     * Este método configura un listener para los eventos 'keydown' en la ventana.
     * El listener del evento 'keydown' verifica si el menú debe ocultarse según el key pulsado.
     */
    window.addEventListener('keydown', (event) => {
      if(this._showMenu && event.key === 'Escape') {
        this.inactiveMenu();
        this._showMenu = false;
      }
    });
  }

    /**
   * Recupera un array de elementos de menú de navegación.
   *
   * @returns Un array de objetos `IMenu` que representan los elementos de menú.
   */
    private manageNavLinks(): IMenu[] {
      const menu: menuGCType = menuMAIN;
      const inicio = menu['inicio'];
      const cca = menu['cca'];
      const lonjas = menu['lonjas'];
      const globalclima = menu['globalclima'];
      const noticias = menu['noticias'];
      const pac = menu['pac'];
      const sar = menu['sar'];
      const gicoop = menu['gicoop'];
      return [inicio, cca, lonjas, globalclima, noticias, pac, sar, gicoop];
    }

    /**
     * Navega a la URL especificada.
     *
     * @param menu - El objeto de menú que contiene la URL a la que navegar.
     * @returns Una Promesa que se resuelve cuando se completa la navegación.
     */
    async navigationUrl(menu: IMenu) {
      const link: string = menu.link ?? '';
      await this._router.navigate([link]);
    }

    /**
     * Abre el menú desplegable
     */
    public toggleMenu(): void {
      this._showMenu = !this._showMenu;
      if(this._showMenu) {
        this.activeMenu();
      } else if(!this._showMenu) {
        this.inactiveMenu();
      }
    }

    /**
     * Activa el menú principal estableciendo los estilos apropiados.
     */
    private activeMenu(): void {
      if (this._mainMenuContent) {
        this._mainMenuContent.style.left = '0';
      }
      if (this._mainMenuBackdrop) {
        this._mainMenuBackdrop.style.visibility = 'visible';
        this._mainMenuBackdrop.style.opacity = '1';
      }
    }

    /**
     * Desactiva el menú principal estableciendo los estilos apropiados.
     */
    private inactiveMenu(): void {
      if (this._mainMenuContent) {
        this._mainMenuContent.style.left = '-550px';
      }
      if (this._mainMenuBackdrop) {
        this._mainMenuBackdrop.style.visibility = 'hidden';
        this._mainMenuBackdrop.style.opacity = '0';
      }
    }

}
