import { IMenu } from '../interfaces/menu-item.interface';
import { urls } from './urls';

export type menuGCType = {[key: string]: IMenu};

const menuMainCCA = 'MENU.MAIN.CCA';
const lonjasIcon = 'gc-business-bar-chart';
const gcBusinessFolder = 'gc-business-folder';
const gcBusinessFolderOutline = 'gc-business-folder-outline';

export const menuMAIN: menuGCType = {
  inicio: { link: urls.inicio, icon: 'gc-user-home-outline', iconHover: 'gc-user-home', title: 'MENU.MAIN.INICIO'},
  cca: { link: urls.cca.instalaciones, icon: gcBusinessFolderOutline, iconHover: gcBusinessFolder, title: menuMainCCA},
  ccaAltaExplotacion: { link: urls.cca.alta_explotacion, icon: 'book-outline', iconHover: 'book', title: menuMainCCA},
  lonjas: { link: urls.lonjas, icon: 'gc-business-bar-chart-outline', iconHover: lonjasIcon, title: 'MENU.MAIN.LONJAS'},
  visorsigpac: { link: urls.visorsigpac, icon: 'gc-weather-sun-outline', iconHover: 'gc-weather-sun', title: 'MENU.MAIN.VISORSIGPAC'},
  gicoop: { link: urls.gicoop, icon: 'gc-gicoop-outline', iconHover: 'gc-gicoop', title: 'MENU.MAIN.DROPDOWN.GICOOP_PLUS.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.GICOOP_PLUS.DESC'},
  noticias: { link: urls.noticias, icon: 'gc-ad-news-outline', iconHover: 'gc-ad-news', title: 'MENU.MAIN.DROPDOWN.NOTICIAS.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.NOTICIAS.DESC'},
  pac: { link: urls.pac, icon: 'gc-chat-comment-outline', iconHover: 'gc-chat-comment', title: 'MENU.MAIN.DROPDOWN.PAC_AYUDAS.TITLE',
    subTitle: 'MENU.MAIN.DROPDOWN.PAC_AYUDAS.DESC'},
  sar: { link: urls.sar, icon: 'gc-weather-drop-outline', iconHover: 'gc-weather-drop', title: 'MENU.MAIN.DROPDOWN.SAR.TITLE', subTitle: 'MENU.MAIN.DROPDOWN.SAR.DESC'},
};

const ccaMenuSalir = 'CCA.MENU.SALIR';

export const menuCCA: menuGCType = {
  dashboard: { link: urls.cca.dashboard, icon: 'gc-user-home-outline', iconHover: 'gc-user-home', title: 'CCA.MENU.PANEL_CONTROL'},
  actividades: { link: urls.cca.actividades, icon: 'gc-business-clipboard-outline', iconHover: 'gc-business-clipboard', title: 'CCA.MENU.ACTIVIDADES'},
  titular: { link: urls.cca.titular, icon: 'gc-farming-farmer-outline', iconHover: 'gc-farming-farmer', title: 'CCA.MENU.TITULAR'},
  explotacion: { link: urls.cca.general, icon: 'gc-farming-barn-outline', iconHover: 'gc-farming-barn', title: 'CCA.MENU.EXPLOTACION'},
  trabajadores: { link: urls.cca.trabajadores, icon: 'gc-user-multiple-outline', iconHover: 'gc-user-multiple', title: 'CCA.MENU.TRABAJADORES'},
  maquinaria: { link: urls.cca.maquinaria, icon: 'gc-farming-tracktor-outline', iconHover: 'gc-farming-tracktor', title: 'CCA.MENU.MAQUINARIA'},
  parcelario: { link: urls.cca.parcelas, icon: 'gc-map-outline', iconHover: 'gc-map', title: 'CCA.MENU.PARCELARIO'},
  instalaciones: { link: urls.cca.instalaciones, title: 'CCA.MENU.INSTALACION'},
  explotaciones: { link: urls.cca.busqueda, title: 'CCA.MENU.EXPLOTACION'},
  invitaciones: { link: urls.cca.invitaciones, icon: 'gc-files-lock-outline', iconHover: 'gc-files-lock', title: 'CCA.MENU.INVITACIONES' },
  inicio: { link: urls.inicio, title: ccaMenuSalir},
  carnetFitosanitario: { link: urls.cca.carnet_fitosanitario, icon: 'gc-business-id-name-outline', iconHover: 'gc-business-id-name', title: 'CCA.MENU.CARNET_FITOSANITARIO' },
};

// Menú settings
const settingsIcon = 'gc-user-settings';
const gcUserProfile = 'gc-user-profile';
const gcUserProfileOutiline = 'gc-user-profile-outline';

export const menuSettings: menuGCType = {
  inicioSettings: { link: urls.superAdminOptions.inicioSettings, icon: 'home-outline', iconHover: 'home', title: 'MENU.SETTINGS.INICIO'},
  usuarios: { link: urls.superAdminOptions.usuarios, icon: gcUserProfileOutiline, iconHover: gcUserProfile, title: 'MENU.SETTINGS.USUARIOS'},
  logs: { link: urls.superAdminOptions.logs, icon: 'warning-outline', iconHover: 'warning', title: 'MENU.SETTINGS.LOGS'},
  utilidades: { link: urls.superAdminOptions.utilidades, icon: settingsIcon, iconHover: settingsIcon, title: 'MENU.SETTINGS.UTILIDADES'},
  permisos: { link: urls.superAdminOptions.permisos, icon: 'key-outline', iconHover: 'key', title: 'MENU.SETTINGS.PERMISOS'},
  lonjas: { link: urls.superAdminOptions.lonjas, icon: lonjasIcon, iconHover: lonjasIcon, title: 'MENU.SETTINGS.LONJAS'},
  instalaciones: { link: urls.superAdminOptions.instalaciones, icon: 'business-outline', iconHover: 'business', title: 'INSTALACIONES'},
  salir: { link: urls.inicio, title: ccaMenuSalir},
};

export const menuAdministracion: menuGCType = {
  usuarios: { link: urls.administracion.usuarios, icon: gcUserProfileOutiline, iconHover: gcUserProfile, title: 'ADMINISTRACION.USUARIOS' },
  explotaciones: { link: urls.administracion.explotaciones, icon: gcBusinessFolderOutline, iconHover: gcBusinessFolder, title: 'ADMINISTRACION.EXPLOTACIONES.EXPLOTACIONES' },
};
