import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, from, Observable, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { LoadingModalComponent } from 'src/app/components/modales/app-loading-modal/loading-modal.component';
import { ILoadingMessage } from './interfaces/loading-message.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loadingMessage: BehaviorSubject<ILoadingMessage | null> = new BehaviorSubject<ILoadingMessage | null>(null);
  private readonly loadingMessageError: BehaviorSubject<ILoadingMessage | null> = new BehaviorSubject<ILoadingMessage | null>(null);
  private _loadingModalReference: HTMLIonModalElement|null = null;

  constructor(private readonly modalController: ModalController, private readonly _trans: TranslateService) {}

  /**
   * Proporciona acceso al observable de Loading
   */
  public get LoadingMessage$(): Observable<ILoadingMessage | null> {
    return this.loadingMessage.asObservable();
  }
  public get LoadingMessageError$(): Observable<ILoadingMessage | null> {
    return this.loadingMessageError.asObservable();
  }

  /**
   * Define el mensaje a mostrar en el modal de loading
   * @param message
   */
  public sendLoadingMessage(message: ILoadingMessage | null) {
    this.loadingMessage.next(message);
  }

  public sendLoadingMessageError(message: ILoadingMessage | null) {
    this.loadingMessageError.next(message);
  }

  /**
   * Levanta un modal de carga al realizar al procesar un observable
   * @param obs El observable
   * @param message El mensaje u opciones de mensaje que mostrar
   * @returns
   */
  public loadWithMessage<T>(obs: Observable<T>, message: string | ILoadingMessage | null, closeLoading: boolean = true): Observable<T>  {
    //Si no tenemos objeto, lo creamos
    const msgObj: ILoadingMessage | null = typeof message === 'string' ? { message } : message;
    if(msgObj?.message){
      msgObj.message = this._trans.instant(msgObj.message);
    }
    const msgPlusDefaults: ILoadingMessage = { message: '', keepModalOnFinish: false, openDialog: true, ...(msgObj || {})};
    this.sendLoadingMessage(msgPlusDefaults);

    const cancel: Subject<unknown> = new Subject();
    return from(this.openLoadingModal(msgPlusDefaults, cancel)).
        pipe(
          // tap(() => console.log(msgPlusDefaults?.message, this._loadingModalReference)),
          // tap(() => console.log('Lanzando el servicio') ),
          switchMap(() => obs),
          takeUntil(cancel),
          catchError((err) => {
            //* Ha ocurrido un error al realizar la petición al servicio. Se retorna el error donde se ha hecho la llamda al servicio para gestionar el error desde ese componente y mostrar un error mas claro a traves del metodo handleErrorWithMessage.
            //* Se cierra el modal de carga ya que se va a levantar el modal para mostrar el error.
            this.closeLoadingModal();
            return throwError(() => err);
          }),
          finalize(async() => {
            if(!msgPlusDefaults?.keepModalOnFinish && closeLoading){
              await this.closeLoadingModal();
            }
            if(!msgPlusDefaults.keepModalOnFinish && !msgPlusDefaults.openDialog && closeLoading){
              this.sendLoadingMessage(null);
            }
          }),
        );
  }


  /**
   * Realiza la apertura de loading
   */
  async openLoadingModal(msg: ILoadingMessage|null, cancelSubject: Subject<any>) {
    if(!this._loadingModalReference && msg?.openDialog){
      this._loadingModalReference = await this.modalController.create({
        component: LoadingModalComponent,
        id: 'modal',
        cssClass: 'loading',
        backdropDismiss: false,
        componentProps: {
         cancelSubject,
        },
      });
      await this._loadingModalReference.present();
    }
    return this._loadingModalReference;
  }

  /**
   * Cierra el modal de loading
   */
  async closeLoadingModal() {
    if (this._loadingModalReference){
      await this._loadingModalReference.dismiss(null);
      this._loadingModalReference = null;
      this.sendLoadingMessage(null);
    }

  }
}
