export const SIGPAC_UI_SETTINGS = {
    layers: {
      parcelas: {
        fillColor: 'rgba(255, 255, 255, 0)',
        strokeColor: 'blue',
        strokeWidth: 2
      },
      recintos: {
        fillColor: 'rgba(255, 255, 255, 0)',
        strokeColor: 'green',
        strokeWidth: 2
      },
      declaracion: {
        fillColor: 'rgba(255, 255, 255, 0)',
        strokeColor: 'rgb(160, 245, 49)',
        strokeWidth: 2
      },
      arboles: {
        fillColor: 'rgba(255, 255, 255, 0)',
        strokeColor: 'rgb(160, 245, 49)',
        strokeWidth: 2
      },
      redNatura: {
        fillColor: 'rgba(255, 255, 255, 0)',
        strokeColor: 'rgb(160, 245, 49)',
        strokeWidth: 2
      },
    },
    icons: {
        infoSateliteHeader: {
          fontIcon: 'blur_on',
        },
        markerEditionCancel: {
          fontIcon: 'close',
        },
        markerEditionDelete: {
          fontIcon: 'undo',
        },
        markerEditionConfirm: {
          fontIcon: 'done',
        },
        measureDisableEdition: {
          fontIcon: 'close'
        },
        measureUndoMarker: {
          fontIcon: 'undo'
        },
        measureAddMarker: {
          fontIcon: 'done'
        },
        editionSaveGeometry: {
          fontIcon: 'save'
        },
        editionDisable: {
          fontIcon: 'close'
        },
        ndviMap: {
          fontIcon: 'map'
        },
        ndviIndices: {
          fontIcon: 'insights',
          fileIcon: 'assets/wp.svg'
        },
        ndviSearch: {
          fontIcon: 'search'
        },
        ndviClose: {
          fontIcon: 'close'
        },
    }
    
  };  
