<ion-header #header id="header-main" [ngClass]="{ 'hide-header-main': splitPaneContent() !== 'main' && splitPaneContent() !== 'none'}">
    <ion-icon class="main-menu-icon" name="gc-interface-menu" (click)="toggleMenu()"  (keydown.enter)="toggleMenu()"></ion-icon>
    <div class="main-logo" routerLink="/">
      <img class="logo-img" src="{{logo}}" alt="Globalcampo" />
      <span class="logo-title">{{ 'GLOBALCAMPO' | translate }}</span>
    </div>
    <gc-menu-main class="main-menu"></gc-menu-main>
    <div class="main-user">
      @if(authorized){
        <gc-user-notifications></gc-user-notifications>
        <ion-icon *gcPermisos="_adminGlobalcampoPermiss" (click)="goToSettings()" (keydown.enter)="goToSettings()" name="gc-network-optimization" class="settings-icon"></ion-icon>
      }
      <gc-user-profile></gc-user-profile>
      @if (authorized) {
        <ion-button *gcPermisos="_adminInstalacionesPermiss" class="btn-s-accent-solid ion-hide-md-down" (click)="goToAdministracion()" (keydown.enter)="goToAdministracion()">
          {{'ADMINISTRACION.ADMINISTRACION' | translate}}
        </ion-button>
      }
    </div>
</ion-header>
