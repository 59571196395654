<div id="open-modal-notifications" class="notifications" [class.on]="notifications.length > 0" (click)="openModal()" (keydown.enter)="openModal()">
  <ion-icon name="gc-user-notifications" class="notifications-icon"></ion-icon>
  @if (notifications.length > 0){
    <span class="notifications-number">{{notifications.length}}</span>
  }
</div>

<ion-modal #modalNotifications id="modal-notifications" [enterAnimation]="enterAnimation" [leaveAnimation]="leaveAnimation">
  <ng-template>
    <div class="modal-header">
      <ion-icon class="header-close ion-hide-sm-up" name="gc-arrow-exit" (click)="closeModal()" (keydown.enter)="closeModal()"></ion-icon>
      <span class="header-title">{{ 'NOTIFICACIONES.TITLE' | translate }}</span>
      @if (notifications.length > 0) {
        <ion-button class="btn-s-accent-clear header-btn" (click)="markAllAsRead()" (keydown.enter)="markAllAsRead()">{{ 'NOTIFICACIONES.MARK_ALL_AS_READ' | translate }}</ion-button>
        <ion-icon class="header-options" name="gc-user-options" (click)="abrirPopover($event)" (keydown.enter)="abrirPopover($event)"></ion-icon>
      }
    </div>
    <div class="modal-content">
      <div class="content-list">
        @if (notifications.length > 0){
          <ion-item-sliding *ngFor="let notification of notifications" class="list-item">
            <ion-item-options class="item-option" side="start">
              <ion-item-option (click)="markAsRead(notification)" (keydown.enter)="markAsRead(notification)">
                <div class="option">
                  <ion-icon class="option-icon" name="gc-email-readed"></ion-icon>
                  <span class="option-text">{{ 'NOTIFICACIONES.LEIDO' | translate }}</span>
                </div>
              </ion-item-option>
            </ion-item-options>
            <ion-item class="item-notification" lines="none">
              <div class="notification">
                <div class="notification-header">
                  <div class="notification-title">
                    <div class="title-icon">
                      <ion-icon name="gc-user-notifications"></ion-icon>
                    </div>
                    <div class="title-text">
                      <span class="text-title">{{ notification.titulo }}</span>
                      <div class="text-sub">
                        <span class="sub" *ngIf="notification.subtitulo01">{{ notification.subtitulo01 }}</span>
                        <span class="sub" *ngIf="notification.subtitulo02">{{ notification.subtitulo02 }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="notification-close">
                    <ion-icon name="gc-user-cross" (click)="markAsRead(notification)" (keydown.enter)="markAsRead(notification)"></ion-icon>
                  </div>
                </div>
                <div class="notification-content">
                  <span class="content-divider"></span>
                  <span class="content-text">{{ notification.contenido }}</span>
                </div>
                <div class="notification-footer">
                  <span class="footer-date">{{ notification.fchNotificacion | date:'dd MMM yyyy HH:mm' }}</span>
                </div>
              </div>
            </ion-item>
            <ion-item-options class="item-option" side="end">
              <ion-item-option (click)="markAsRead(notification)" (keydown.enter)="markAsRead(notification)">
                <div class="option">
                  <ion-icon class="option-icon" name="gc-email-readed"></ion-icon>
                  <span class="option-text">{{ 'NOTIFICACIONES.LEIDO' | translate }}</span>
                </div>
              </ion-item-option>
            </ion-item-options>
          </ion-item-sliding>
        } @else {
          <div class="list-msg">
            <ion-icon class="msg-icon" name="gc-global-no-notification"></ion-icon>
            <div class="msg">
              <span class="img-title">{{ 'NOTIFICACIONES.IMG_TITLE' | translate }}</span>
              <span class="img-desc">{{ 'NOTIFICACIONES.IMG_SUBTITLE' | translate }}</span>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="modal-footer">
      <ion-button class="btn-s-accent-clear" (click)="navegarHistorialNotificaciones()" (keydown.enter)="navegarHistorialNotificaciones()">{{ 'NOTIFICACIONES.IR_HISTORICO' | translate }}</ion-button>
    </div>
  </ng-template>
</ion-modal>
