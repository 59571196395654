import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { ListItemComponent } from '../../action/gc-list-item/list-item.component';


@Component({
  selector: 'gc-menu-main-dropdown',
  templateUrl: './menu-main-dropdown.component.html',
  styleUrls: ['./menu-main-dropdown.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule, ListItemComponent],
})

export class MenuMainDropdownComponent {

  /**
   * Elementos de menú que se mostrarán en el desplegable.
   */
  @Input() dropdownLinks: IMenu[] = [];

  /**
   * Indica si se debe mostrar el pie de página del menú desplegable.
   */
  @Input() showDropdownFooter: boolean = false;

  constructor() {}

}
