<div class="main-menu">
  <div class="menu-header" routerLink="/">
    <img class="header-img" src="{{logo}}" alt="Globalcampo"/>
    <span class="header-title">{{ 'GLOBALCAMPO' | translate }}</span>
  </div>
  <div class="menu-content">
      <div class="content-nav">
        <a class="nav-link" (click)="goToAdministracion()" (keydown.enter)="goToAdministracion()" >
          <span class="link-icon">
            <ion-icon class="btn-icon" name="gc-business-folder"></ion-icon>
          </span>
          <span class="link-text">{{ 'ADMINISTRACION.ADMINISTRACION' | translate }}</span>
        </a>
        @for(navLink of navLinks; track navLink) {
          <a class="nav-link" routerLink="{{navLink.link}}" routerLinkActive="link-selected" (click)="navigationUrl(navLink, $event)" (keydown.enter)="navigationUrl(navLink, $event)" >
            <span class="link-icon">
              <ion-icon class="icon-outline" name="{{ navLink.icon }}"></ion-icon>
              <ion-icon class="icon-solid" name="{{ navLink.iconHover }}"></ion-icon>
            </span>
            <span class="link-text">{{ navLink.title | translate }}</span>
          </a>
        }
      </div>
  </div>
</div>