import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { IonicModule, MenuController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ASSETS_PATHS } from 'src/app/globalcampo/config/assets-paths.config';
import { urls } from 'src/app/globalcampo/config/urls';
import { PermisosDirective } from 'src/app/globalcampo/directives/permisos.directive';
import { PERMISOS, PERMISOS_ACCIONES } from 'src/app/globalcampo/permissions/permissions.const';
import { LoginService } from 'src/app/globalcampo/services/login.service';
import { UserProfileComponent } from '../../action/gc-user-profile/user-profile.component';
import { MenuMainComponent } from '../menu-main/menu-main.component';
import { UserNotificationsComponent } from '../../notificaciones/gc-user-notifications/user-notifications.component';

@Component({
  selector: 'gc-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterModule, MenuMainComponent, UserProfileComponent, UserNotificationsComponent,
    PermisosDirective],
})
export class HeaderMainComponent  implements OnInit {

  private readonly destroyRef = inject(DestroyRef);

  /**
   * Recibe el contenido del panel
   */
  splitPaneContent = input<string>();

  /**
   * Indica si el usuario está autorizado.
   */
  private _authorized: boolean = false;
  public get authorized(): boolean {
    return this._authorized;
  }

  _adminGlobalcampoPermiss: string[] = [PERMISOS.ADMIN.ADMIN + PERMISOS_ACCIONES.ALL];
  _adminInstalacionesPermiss: string[] = [PERMISOS.ADMIN.INSTALACIONES_EXTERNAS.ADMIN + PERMISOS_ACCIONES.ALL];

  /**
   * Indica la ruta a la imagen del logo.
   */
  public logo: string = ASSETS_PATHS.gcLogoGlobalcampo;

  constructor(private readonly _menuCtrl: MenuController, private readonly _login: LoginService, private readonly _navCtrl: NavController) { }
  ngOnInit(): void {
    this._login.isLoggedIn$
    .pipe(
      takeUntilDestroyed(this.destroyRef),
    )
    .subscribe((loggedIn) => {
      this._authorized = !!loggedIn;
    });
  }

  /**
 * Alterna el menú abriendo el 'gc-menu-main'
 */
  public toggleMenu(): void {
    this._menuCtrl.open('gc-menu-main');
  }

  goToSettings(): void {
    this._navCtrl.navigateRoot(urls.superAdmin);
  }

  goToAdministracion(): void {
    this._navCtrl.navigateRoot(urls.administracion.usuarios);
  }
}
