import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom, Subject } from 'rxjs';
import { menuGCType, menuSettings } from 'src/app/globalcampo/config/menus-app.config';
import { IMenu } from 'src/app/globalcampo/interfaces/menu-item.interface';
import { AppStatusService } from 'src/app/globalcampo/services/app-status.service';
import { IProfileService } from 'src/app/globalcampo/services/profile/profile-service.interface';
import { IProfile } from 'src/app/globalcampo/services/profile/profile.interface';
import { PROFILE_TOKEN } from 'src/app/globalcampo/services/profile/profile.token';

@Component({
  selector: 'gc-menu-settings',
  templateUrl: './menu-settings.component.html',
  styleUrls: ['./menu-settings.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule, TranslateModule, FormsModule],
})
export class MenuSettingsComponent implements OnInit {
  /**
   * Emite un valor booleano que indica si el menú está en estado compacto.
   * @event menuCCACompact
   * @type {boolean}
   */
  @Output() menuCCACompact: EventEmitter<boolean> = new EventEmitter();

  private readonly _unsubs: Subject<unknown> = new Subject();

  /**
 * Representa los nav links del menu
 */
  private readonly _navLinks: IMenu[] = this.manageNavLinks();
  public get navLinks(): IMenu[] {
    return this._navLinks;
  }

  /**
 * Representa el switch link de instalación.
 */
  private _switchUsernLink!: IMenu;
  public get switchUsernLink(): IMenu {
    return this._switchUsernLink;
  }


  private _switchLogsnLink!: IMenu;
  public get switchLogsnLink(): IMenu {
    return this._switchLogsnLink;
  }

  /**
 * Representa el link de salida del footer.
 */
  private readonly _footerExitLink!: IMenu;
  public get footerExitLink(): IMenu {
    return this._footerExitLink;
  }

  /**
   * Indica si el menú debe mostrarse compacto.
   */
  private _compactMenu: boolean = false;
  public get compactMenu(): boolean {
    return this._compactMenu;
  }

  /**
   * Indica si la aplicación se está ejecutando en un entorno de escritorio.
   */
  private _isDesktop: boolean = false;
  public get isDesktop(): boolean {
    return this._isDesktop;
  }

  /**
 * Indica si el tamaño de la pantalla actual es MD o superior.
 */
  private _isMdUp: boolean = false;
  public get isMdUp(): boolean {
    return this._isMdUp;
  }

  /**
 * Indica la etiqueta para el botón toggle.
 */
  private _toogleLabel: string = 'LIGHT';
  public get toogleLabel(): string {
    return this._toogleLabel;
  }

  /**
   * Representa el nombre del icono que se utilizará en el componente del menú.
   */
  private _iconName: string = 'sun';
  public get iconName(): string {
    return this._iconName;
  }

  /**
   * El color del icono, por defecto es 'amarillo'.
   */
  private _iconColor: string = 'yellow';

  /**
  * Indica el estado actual del interruptor de modo.
  */
  public modeChanged: boolean = false;

  private readonly footerIcon: string = '--gc-color-footer-icon';
  private readonly footerIconHover: string = '--gc-color-footer-icon-hover';


  constructor(
    private readonly _router: Router,
    private readonly _menuController: MenuController,
    private readonly _navCtrl: NavController,
    private readonly _elementRef: ElementRef,
    private readonly _platform: Platform,
    private readonly _appStatusService: AppStatusService,
    @Inject(PROFILE_TOKEN) private readonly _profileService: IProfileService,

  ) { }

  ngOnInit() {
    this.manageLinks();
    this._profileService.getProfile()
      .subscribe((profile) => {
        this.updateModeDataBasedOnProfile(profile);
      });

    this.checkIsDesktop();
    this.checkIsMdUp();
    window.addEventListener('resize', () => {
      this.checkIsMdUp();
    });
  }


  /**
 * Recupera un array de elementos de menú de navegación.
 *
 * @returns Un array de objetos `IMenu` que representan los elementos de menú.
 */
  public manageNavLinks(): IMenu[] {
    const menu: menuGCType = menuSettings;
    const usuarios = menu['usuarios'];
    const logs = menu['logs'];
    const utilidades = menu['utilidades'];
    return [usuarios, logs, utilidades];
  }

  /**
     * Gestiona los enlaces para el componente de menú.
     */
  public manageLinks() {
    const menu: menuGCType = menuSettings;
    this._switchUsernLink = menu['usuarios'];
    this._switchLogsnLink = menu['logs'];
  }


  /**
   * Actualiza los datos del modo basado en el perfil proporcionado
   * @param profile - El objeto de perfil que contiene la información del modo. Si es nulo, no se realizan cambios
   */
  public updateModeDataBasedOnProfile(profile: IProfile | null) {
    if (profile) {
      const isDarkMode = profile?.mode === 'dark';
      this.modeChanged = isDarkMode;
      this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
      this._iconName = isDarkMode ? 'moon' : 'sun';
      this._iconColor = isDarkMode ? 'blue' : 'yellow';
      this._elementRef.nativeElement.style.setProperty(this.footerIcon, `var(--gc-color-${this._iconColor}-tone)`);
      this._elementRef.nativeElement.style.setProperty(this.footerIconHover, `var(--gc-color-${this._iconColor}-opacity-10)`);
    }
  }

  /**
 * Verifica si el ancho de la plataforma es mayor a 768 píxeles
 */
  public checkIsMdUp() {
    const mdUp: number = 768;
    this._isMdUp = this._platform.width() > mdUp;
  }

  /**
   * Verifica si la plataforma actual es un escritorio
   */
  public checkIsDesktop() {
    this._isDesktop = this._platform.is('desktop');
  }

  /**
 * Navega a la URL especificada.
 *
 * @param menu - El objeto de menú que contiene la URL a la que navegar.
 * @param rootNavigation - Un booleano que indica si se debe realizar una navegación raíz.
 * @returns Una Promesa que se resuelve cuando se completa la navegación.
 */
  async navigationUrl(menu?: IMenu, rootNavigation: boolean = false) {
    const link: string = menu?.link ?? null ?? '';

    this._menuController.close();

    if (rootNavigation) {
      await this._navCtrl.navigateRoot([link]);
    } else {
      await this._router.navigate([link]);
    }

  }



  /**
 * Alterna el botón del menú en el componente de la aplicación.
 */
  public toggleBtnMenuCCA() {
    this._compactMenu = !this._compactMenu;
    this.menuCCACompact.emit(this._compactMenu);
    const menuCCABtnIcon = document.getElementById('gc-footer-btn-icon') as HTMLElement;
    menuCCABtnIcon.style.transform = this._compactMenu ? 'rotate(180deg)' : 'rotate(0deg)';
  }


  /**
   * Cambia el modo del perfil entre 'oscuro' y 'claro'.
   *
   * @param ev - El objeto de evento.
   */
  async modeChange(ev: CustomEvent | MouseEvent | KeyboardEvent) {
    const isClickEvent: boolean = ev.type === 'click';
    const isDarkMode: boolean = isClickEvent ? !this.modeChanged : ev.detail.checked;
    const updatedProfile: Partial<IProfile> = { mode: isDarkMode ? 'dark' : 'light' };

    this._iconColor = isDarkMode ? 'blue' : 'yellow';
    this._elementRef.nativeElement.style.setProperty(this.footerIcon, `var(--gc-color-${this._iconColor}-tone)`);
    this._elementRef.nativeElement.style.setProperty(this.footerIconHover, `var(--gc-color-${this._iconColor}-opacity-10)`);

    this.modeChanged = isDarkMode;
    this._toogleLabel = isDarkMode ? 'DARK' : 'LIGHT';
    this._iconName = isDarkMode ? 'moon' : 'sun';

    await lastValueFrom(this._profileService.patchProfile(updatedProfile, 'modeChanged'));
  }

}
