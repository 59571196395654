import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { StorageKeys } from '../../config/storage-keys.config';
import { globalcampoEndPoints } from '../../enpoints/globalcampo.endpoints.v1';
import { instalacionesEndPoints } from '../../enpoints/instalaciones.endpoints.v1';
import { usersEndPoints } from '../../enpoints/users.endpoints.v1';
import { IApoderado } from '../../interfaces/apoderado.interface';
import { IExplotacionFeedback, IInstalacion, IInstalacionAmpliado } from '../../interfaces/instalaciones/instalacion.interface';
import { IRole } from '../../interfaces/role.interface';
import { IUser, IUserAplicacion } from '../../interfaces/user.interface';
import { UsuariosAplicacionFilter } from '../../types/serviceFilters/usuarios-aplicacion.filters';
import { AppStatusService } from '../app-status.service';
import { LoadingService } from '../loading/loading.service';
import { LoginService } from '../login.service';
@Injectable({
  providedIn: 'root',
})
export class GlobalcampoService {
  private readonly instalacionSeleccionadaSubject = new BehaviorSubject<IInstalacion | null>(null);
  public instalacionSeleccionada$ = this.instalacionSeleccionadaSubject.asObservable();
  private _meCache: IUserAplicacion | null = null;
  public get userCCA(): IUserAplicacion | null {
    return this._meCache;
  }

  // private readonly _ccaRoute: WritableSignal<string> = signal('cca');
  // public get ccaRoute() {
  //   return this._ccaRoute;
  // }

  public readonly feedBack: WritableSignal<IExplotacionFeedback[]> = signal([]);

  private readonly _feedBackObserve = toObservable(this.feedBack);
  public get feedBackObserve() {
    return this._feedBackObserve;
  }

  // private readonly _ccaRouteObserve = toObservable(this.ccaRoute);
  // public get ccaRouteObserve() {
  //   return this._ccaRouteObserve;
  // }


  constructor(private readonly http: HttpClient, private readonly loading: LoadingService,
    private readonly app: AppStatusService, private readonly _loginService: LoginService) {
      this.subscripciones();
    }

  private subscripciones(){
    /**
     * Subscripción a logout para eliminar la caché del Me
     */
    this._loginService.isLoggedIn$.subscribe((user) => {
      if(!user){
        this._meCache = null;
      }
    });
  }
  /**
   * Get the representatives assigned by the dni
   * @param dni
   * @returns
   */
  getApoderaciones(dni: string | null | undefined): Observable<IApoderado[]> {
    return this.http.get<IApoderado[]>(globalcampoEndPoints.getApoderaciones(dni));
  }

  seleccionarInstalacion(instalacion: IInstalacion): void {
    this.instalacionSeleccionadaSubject.next(instalacion);
  }

  getInstalacionSeleccionada(): IInstalacion | null {
    return this.instalacionSeleccionadaSubject.value;
  }

  /**
   * Get Instalaciones
   * @returns
   */
  public getInstalaciones(_codIdentific: string | null | undefined): Observable<IInstalacion[]|null> {

    return this.http.get<IInstalacion[]>(instalacionesEndPoints.getInstalaciones)
    .pipe(
      tap((instalaciones: IInstalacion[]) => this.app.cantidadInstalaciones = instalaciones.length ),
      tap((instalaciones: IInstalacion[]) => Preferences.set({key: StorageKeys.CHANGE_ENTIDAD.name, value: JSON.stringify({isChangeInstalacion: instalaciones.length > 1})})),
      map((instalaciones: IInstalacion[]) => {
          return instalaciones.sort((a, b) => {
            if(a.nombreInstalacion >= b.nombreInstalacion){
              return 1;
            }
            return -1;
          });
      },
      ),
    );

  }


  /**
   * Obteción de la información del usuario
   * @returns
   */
  public getMe(): Observable<IUserAplicacion> {
    if (this._meCache){
      return of(this._meCache);
    }
    return this.http.get<IUserAplicacion>(usersEndPoints.me).pipe(
      tap((res) => {
        this._meCache = res;
      }),
    );
  }

  /**
   * Sends a POST request to register a user.
   *
   * @param user - The user object to be registered.
   * @returns An observable that emits the registered user.
   */
  public postRegistro(user: {[key: string ]: unknown}): Observable<IUser|null> {
    return this.http.post<IUser>(usersEndPoints.registro, user);
  }

  // Obtención de los usuarios de la aplicación
  putUsuariosAplicacion(options?: UsuariosAplicacionFilter): Observable<IUserAplicacion[]> {
    let body = {};
    if(options){
     body = { ...body, ...options };
    }
    return this.http.put<IUserAplicacion[]>(usersEndPoints.listado, body);
  }


  getRolesAplicacion(): Observable<IRole[]> {
    return this.http.get<IRole[]>(usersEndPoints.rolesAplicacion);
  }

  getRolesUsuarios(): Observable<IRole[]> {
    return this.http.get<IRole[]>(usersEndPoints.rolesUser);
  }

  getInstalacionesAmpliado(): Observable<IInstalacionAmpliado[]> {
    return this.http.get<IInstalacionAmpliado[]>(usersEndPoints.getInstalacionesampliado);
  }

}
