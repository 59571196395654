import { environment } from 'src/environments/environment';
import { ENDPOINTS_PREFIX } from '../config/endpoints.prefix.config';

const apiVx = 'v1';
export const perfilEndPoints = {
  PUT_PERFIL: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilusuario`,
  getPerfil: (desAplicacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilusuario/${desAplicacion}`,
  deletePerfil: (id: string) =>  `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilusuario/${id}`,
};
export const perfilExplotacionEndPoints = {
  PUT_PERFIL: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilexplotacion`,
  getPerfil: (desAplicacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilexplotacion/${desAplicacion}`,
  deletePerfil: (id: string) =>  `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilexplotacion/${id}`,
};
export const perfilInstalacionEndPoints = {
  PUT_PERFIL: `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilinstalacion`,
  getPerfil: (desAplicacion: string) => `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilinstalacion/${desAplicacion}`,
  deletePerfil: (id: string) =>  `${environment.apiURL}${ENDPOINTS_PREFIX.api()}app/${apiVx}/perfilinstalacion/${id}`,
};

export function getEndpoints(nivel: string) {
  switch (nivel) {
    case 'perfil':
      return perfilEndPoints;
    case 'explotacion':
      return perfilExplotacionEndPoints;
    case 'instalacion':
      return perfilInstalacionEndPoints;
    default:
      throw new Error(`Nivel desconocido: ${nivel}`);
  }
}
